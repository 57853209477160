import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { ResearchModel } from '../../../models/research.model';
import { DrawerService } from '../../../services/drawer.service';
import { ResearchesService } from '../../../services/researches.service';
import {Subscription, timer} from 'rxjs';
import {AuthorizationService} from '../../../services/authorization.service';
import {UserModel} from '../../../models/user.model';
import {InterviewService} from '../../../services/interview.service';
import {InterviewModel} from '../../../models/interview.model';
import {ProgressModel} from '../../../models/progress.model';
import {RespondentModel} from '../../../models/respondent.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('status-host') statusHost: ViewContainerRef;

  public researches: ResearchModel[];

  public research: ResearchModel;
  public respondent: RespondentModel;
  public interview: InterviewModel;
  public progress: ProgressModel;

  public user: UserModel;
  public interviewTime: number;
  public currentTime: number;
  public callingNow: string;

  private researchesSubscription: Subscription;
  private researchSubscription: Subscription;
  private timer: Subscription;

  constructor(
    private authorization: AuthorizationService,
    private interviews: InterviewService,
    private drawerService: DrawerService,
    private researchesService: ResearchesService,
    private cd: ChangeDetectorRef
  ) {
    this.authorization.user.subscribe(
      user => this.user = user
    );

    this.interviews.interview.subscribe(
      interview => this.interview = interview
    );

    this.timer = timer(0, 1000).subscribe(
      value => {
        this.interview = this.interviews.interview.value;

        if (this.interview) {
          this.research = this.interview.research;
          this.respondent = this.interview.respondent;
          this.progress = this.interviews.progress.value;
          this.interviewTime = this.getInterviewTime();
          this.callingNow = this.interviews.callingNow;
        }

        this.cd.detectChanges();
      }
    );

    timer(0, 1000).subscribe(
      () => this.currentTime = Date.now()
    );

  }

  ngOnInit() {
    this.researchesSubscription = this.researchesService.list.subscribe(
      researches => this.researches = researches
    );
    this.researchSubscription = this.researchesService.research.subscribe(
      research => this.research = research
    );
  }

  ngOnDestroy(): void {
    this.researchSubscription.unsubscribe();
    this.researchesSubscription.unsubscribe();
  }

  public toggleDrawer() {
    this.drawerService.toggle();
  }

  public refresh() {
    // TODO: this is ugly... better have some sort of global refresh action...
    //  not just only researches but also active view like deferred respondents
    this.researchesService.refresh();
    this.researchesService.research.next(this.researchesService.research.value);
  }

  public getInterviewTime(): number {
    return (Date.now() - this.interview.tstart.getTime()) / 1000;
  }



}

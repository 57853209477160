<app-page-wrapper>
  <div wrapper-title>Statistiky operátora
  </div>

  <div wrapper-actions>
    Statistiky operátora zvoleného výzkumu.
  </div>

  <div wrapper-content fxLayout="column" fxLayoutAlign="space-evenly none" *ngIf="!refreshing">
    <div fxLayoutAlign="row">
      <div fxFlex="shrink">
        <h3>{{research?.rid}} - {{research?.rname}}</h3>
        <table>
          <tr>
            <td>Průměrná délka rozhovoru:</td>
            <td *ngIf="data?.user.average">{{data?.user.average}} sekund</td>
          </tr>
          <tr>
            <td>Kompletních rozhovorů:</td>
            <td>{{data?.user.complete}}</td>
          </tr>
          <tr>
            <td>Použitých čísel:</td>
            <td>{{data?.user.dialed}}</td>
          </tr>
        </table>
      </div>
      <div fxFlex="shrink" fxLayoutGap="10px">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [disabled]="allDays" [(ngModel)]="date" (dateChange)="refresh()" placeholder="Datum">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-checkbox [(ngModel)]="allDays" (change)="refresh()">Všechny dny</mat-checkbox>
      </div>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start">
      <div fxFlex class="chart">
        <h3>Zastoupení výsledků rozhovorů</h3>
        <canvas baseChart
                [data]="chartCallresDataset"
                [labels]="chartCallresLabels"
                [chartType]="chartCallresType"
                [options]="chartCallresOptions"
                [plugins]="chartCallresPlugins"
                [legend]="chartCallresLegend">
        </canvas>
      </div>
    </div>
  </div>
</app-page-wrapper>

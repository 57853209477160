export class ClitemModel {

  public code: number;
  public text: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

}

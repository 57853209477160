import { ClitemModel } from './clitem.model';

export class CodelistModel {

  listname: string;
  items: ClitemModel[] = [];

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface BlacklistDialogData {
  phone: string;
  note: string;
}

@Component({
  selector: 'app-blacklist-dialog',
  template: `
      <div fxFlex fxLayout="column">

          <div mat-dialog-title fxFlex="none">
              Požadavek na zařazení do Blacklistu
          </div>

          <div mat-dialog-content fxFlex="grow">
              <div fxFlex>
                  <mat-form-field fxFlex>
                      <textarea matInput placeholder="Poznámka" matTextareaAutosize matAutosizeMinRows="3" fxFlex
                                [(ngModel)]="data.note"></textarea>
                  </mat-form-field>
              </div>
          </div>

          <div mat-dialog-actions fxFlex="none">
              <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Přidat na Blacklist</button>
              <button mat-button [mat-dialog-close]="null">Zavřít</button>
          </div>

      </div>
  `,
  styles: []
})
export class BlacklistDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BlacklistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BlacklistDialogData
  ) { }

  ngOnInit() {
  }

}

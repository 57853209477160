import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RespondentModel} from '../models/respondent.model';
import {CatiService, RespondentsFilter} from './cati.service';
import {ResearchesService} from './researches.service';
import {AuthorizationService} from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RespondentsService {

  respondent: BehaviorSubject<RespondentModel> = new BehaviorSubject<RespondentModel>(null);
  respondents: BehaviorSubject<RespondentModel[]> = new BehaviorSubject<RespondentModel[]>(null);
  filter: BehaviorSubject<RespondentsFilter> = new BehaviorSubject<RespondentsFilter>(null);

  constructor(
    private researches: ResearchesService,
    private authorization: AuthorizationService,
    private cati: CatiService
  ) {
    this.filter.next({
        date: {
          value: new Date(new Date().setHours(0, 0, 0, 0)),
          active: true
        },
        oid: {
          value: null,
          active: false
        },
        sort: {
          active: false,
          field: null,
          dir: 'ASC'
        }
      }
    );

    this.authorization.user.subscribe(
      user => {
        const filter = this.filter.value;
        filter.oid.value = user ? user.oid : null;
        this.filter.next(filter);
      }
    );
  }

  public async refresh() {
    this.respondents.next(
      await this.cati.getDefferedRespondents(this.researches.rid, this.filter.value)
    );
    this.respondent.next(null);
  }
}

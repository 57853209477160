export class ResearchModel {

  public rid: number;
  public rname: string;
  public rversion: number;
  public initmessage: string;
  public instructions: string;
  public display: string;
  public waveend: Date;
  public createdate: Date;
  public totalquota: number;
  public active: boolean;
  public intralognick: string;
  public hanguptime: number;
  public feedbackemail: string;
  public deferinterval: number;
  public questionary: 'iquest';
  public extraparams: any;

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.waveend) { this.waveend = new Date(this.waveend); }
    if (this.createdate) { this.createdate = new Date(this.createdate); }
    if (this.extraparams) { this.extraparams = JSON.parse(this.extraparams); }

    if (!this.questionary) {
      this.questionary = 'iquest';
    }
  }
}

import {ResearchModel} from './research.model';
import {RespondentModel} from './respondent.model';
import {Callres} from '../shared/cati.consts';

export interface DeferValues {
  tocall: Date;
  tocall_exacttime: number;
  phone: string;
  note: string;
}

export interface SocioValues {
  sex: number;
  age: number;
  distr: number;
  edu: number;
}

export class InterviewModel {

  public research: ResearchModel;
  public respondent: RespondentModel;
  public intid: number;
  public callres: Callres = null;
  public acttime = 0;
  public tstart: Date;
  public tstop: Date;
  public connecttime: Date;
  public dialtime: Date;
  public callovertime: Date;
  public questversion: string;
  public note: string;
  public phone: string;
  public testmode: boolean;
  public manuallyadded: boolean;
  public oid: number;
  public oname: string;
  public method: string;
  public defer: DeferValues = {
    tocall: null,
    tocall_exacttime: null,
    phone: null,
    note: null
  };
  public socio: SocioValues = {
    sex: null,
    age: null,
    distr: null,
    edu: null
  };

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  public get body() {
    return {
      defer: this.defer,
      socio: this.socio,
      times: {
        acttime: this.acttime,
        connecttime: this.connecttime,
        dialtime: this.dialtime,
        callovertime: this.callovertime,
      },
      misc: {
        callres: this.callres,
        testmode: this.testmode,
        manuallyadded: this.manuallyadded,
        questversion: this.questversion,
        rversion: this.research.rversion,
        lastcallnumber: this.phone,
        oname: this.oname,
        note: this.note
      }
    }
  }

  public getAccountCode(oid: number): string {
    const rid = `000000${this.research.rid}`.slice(-6);
    const respid = `00000000${this.respondent.respid}`.slice(-8);
    const intid = `000000${this.intid}`.slice(-6);
    const soid = `0000${oid}`.slice(-4);
    return `${rid}_${respid}_${intid}_${soid}`;
  }
}

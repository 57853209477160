import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { DrawerService } from '../services/drawer.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {PhonebarComponent} from './bars/phonebar/phonebar.component';
import {AuthorizationService} from '../services/authorization.service';
import {Subscription, timer} from 'rxjs';
import {UserModel} from '../models/user.model';
import {SessionsService} from '../services/sessions.service';
import {SessionbarComponent} from './bars/sessionbar/sessionbar.component';
import {SipService} from '../services/sip.service';
import {MessagesService} from '../services/messages.service';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit, OnDestroy {

  @ViewChild('drawer') drawer;

  public authorizationSubscription: Subscription;
  public testmodeSubscription: Subscription;
  public sipStateSubscription: Subscription;
  private timer: Subscription;

  public user: UserModel;
  public sessionTime: number;
  public pauseTime: number;
  public sipStatus: string;

  loading = false;
  testmode = false;

  constructor(
    private authorization: AuthorizationService,
    private sip: SipService,
    private drawerService: DrawerService,
    private sessions: SessionsService,
    private sheet: MatBottomSheet,
    private cd: ChangeDetectorRef,
    private messages: MessagesService,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    this.timer = timer(0, 900000).subscribe(
      value => {
        if (window.innerHeight !== screen.height) {
          this.messages.show('Přepněte webový prohlížeč do režimu celé obrazovky (F11)', 'info');
        }

      });
  }

  ngOnInit() {
    this.drawerService.onToggle.subscribe(
      () => this.drawer.toggle()
    );

    this.authorizationSubscription = this.authorization.user.subscribe(
      user => {
        this.user = user;
      }
    );

    this.sipStateSubscription = this.sip.state.subscribe(
      state => {
        this.sipStatus = state;
        this.cd.detectChanges();
      }
    );

    this.timer = timer(1000, 1000).subscribe(
      async value => {
        this.sessionTime = this.sessions.todaySessionsTotal;
        this.pauseTime = this.sessions.todayPausesTotal;
      }
    );

    this.testmodeSubscription = this.sessions.testmode.subscribe(
      testmode => {
        this.testmode = testmode;
        this.cd.detectChanges();
      }
    );
  }
  ngOnDestroy(): void {
    this.authorizationSubscription.unsubscribe();
    this.sipStateSubscription.unsubscribe();
    this.timer.unsubscribe();
    this.testmodeSubscription.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    if (this.user) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  public phone() {
    this.sheet.open(PhonebarComponent);
  }

  public session() {
    this.sheet.open(SessionbarComponent);
  }

}

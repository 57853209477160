import {Component, HostBinding} from '@angular/core';
import {ResearchModel} from '../../../../../models/research.model';
import {ResearchesService} from '../../../../../services/researches.service';
import {Subscription} from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import {CatiService} from '../../../../../services/cati.service';
import {UserStatisticsModel} from '../../../../../models/user-statistics.model';
import * as moment from 'moment';
import {ChartColor, ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';

@Component({
  selector: 'app-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.css']
})
export class UserStatisticsComponent {

  @HostBinding('class.flex') flex = true;
  date: Date;
  minDate: Date;
  maxDate: Date;
  allDays: Boolean;
  refreshing = false;

  research: ResearchModel;
  data: UserStatisticsModel;

  private researchSubscription: Subscription;

  public chartCallresDataset: SingleDataSet;
  public chartCallresColors: ChartColor;
  public chartCallresLabels: Label[];
  public chartCallresType: ChartType = 'pie';
  public chartCallresLegend = true;
  public chartCallresPlugins = [];
  public chartCallresOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
      labels: {
        fontSize: 10
      }
    }
  };

  constructor(
    private researches: ResearchesService,
    private cati: CatiService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.researchSubscription = this.researches.research.subscribe(
      research => {
        this.research = research;
        this.refresh();
      }
    );

    this.date = new Date();
    this.minDate = this.research.createdate;
    this.maxDate = new Date();
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  public async refresh() {
    this.refreshing = true;

    try {
      const date = this.allDays ? 'allDays' : moment(this.date).format('YYYY-MM-DD');

      if (this.research) {
        this.data = await this.cati.getUserStatistics(this.research.rid, date);
      }

      if (this.data) {
        this.data.user.callres.sort((a, b) => b.cnt - a.cnt);
        this.chartCallresDataset = this.data.user.callres.map(item => item.cnt);
        this.chartCallresLabels = this.data.user.callres.map(item => item.description);
      }
    } catch (e) {
    }
    this.refreshing = false;
  }

}

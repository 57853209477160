import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {environment} from '../../environments/environment';
import {ErrorDialogComponent, ErrorDialogData} from './error-dialog.component';
import {LoggingService} from '../services/logging.service';
import {MessagesService} from '../services/messages.service';
import { MatDialog } from '@angular/material/dialog';
import {logging} from 'selenium-webdriver';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private logging: LoggingService;
  private messages: MessagesService;
  private dialog: MatDialog;

  constructor(
    private injector: Injector
  ) {

  }

  handleError(error) {
    this.logging = this.injector.get(LoggingService);
    this.messages = this.injector.get(MessagesService);
    this.dialog = this.injector.get(MatDialog);

    this.logging.logException('error-handler', error);

    if (environment.production) {
      this.messages.show(`${error.status} - ${error.error.message}`, 'error');
    } else {
      const dlg = this.dialog.open(ErrorDialogComponent, {
        width: '80%',
        data: error
      });
    }
  }
}

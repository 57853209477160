export class SyslistItemModel {

  public value: number;
  public description: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

}

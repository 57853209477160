import {Component, OnDestroy} from '@angular/core';
import {AuthorizationService, LoginState} from '../../../services/authorization.service';
import {UserModel} from '../../../models/user.model';
import {LoginComponent} from '../../content/view/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {SessionsService} from '../../../services/sessions.service';
import {InterviewModel} from '../../../models/interview.model';
import {InterviewService} from '../../../services/interview.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnDestroy {

  public interview: InterviewModel;
  public user: UserModel;
  public state: LoginState;
  public pause: boolean;

  public userSubscription: Subscription;
  public stateSubscription: Subscription;

  constructor(
    private authorization: AuthorizationService,
    private dialog: MatDialog,
    private sessions: SessionsService,
    private interviews: InterviewService
  ) {
    this.authorization.user.subscribe(
      (user) => {
        this.user = user;
      }
    );

    this.authorization.state.subscribe(
      (state) => {
        this.state = state;
      }
    );

    this.sessions.pause.subscribe(
      (pause) => {
        this.pause = pause;
      }
    );

    this.interviews.interview.subscribe(
      interview => this.interview = interview
    );

  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.stateSubscription.unsubscribe();
  }

  public actionLogin() {
    const dialogRef = this.dialog.open(LoginComponent,
      {
        width: '600px',
        data: {}
      });
  }

  public actionLogout() {
    this.authorization.logout(this.authorization.oid, this.authorization.line);
  }

  public actionAcount() {

  }
}

<mat-tab-group>
  <mat-tab label="Kontakty">
    <div fxLayout="column" fxLayoutGap="10px" style="margin:16px">
        <mat-card>
          <mat-card-title>
            <mat-icon>contact_support</mat-icon> Kontakty
          </mat-card-title>
          <mat-card-content>
            <h3>Praha</h3>
            <table>
              <tr>
                <td>
                  <mat-icon>phone</mat-icon>
                </td>
                <td>225 986 836</td>
              </tr>
            </table>
            <h3>Ústí nad Labem</h3>
            <table>
              <tr>
                <td>
                  <mat-icon>phone</mat-icon>
                </td>
                <td>225 986 878</td>
              </tr>
            </table>
            <br>
            <i>Z této aplikace stačí vytočit pouze poslední trojčíslí.</i>
          </mat-card-content>
        </mat-card>
      </div>
  </mat-tab>
  <mat-tab label="Pokyny k ochraně osobních údajů">
    <div fxLayout="column" fxLayoutGap="10px" style="margin:16px">
        <mat-card>
          <mat-card-title>Jak poznáte, z jaké databáze voláte:</mat-card-title>
          <mat-card-content>
            Výzkumy, které jsou označeny --- např. ---Radioprojekt jsou projekty na obecnou populaci.
            <br><br>
            Tam kde v levém okně v dotazníku vidíte:
            <br><br>
            cml=1 jsou respondenti, kteří nám souhlas s dalším kontaktem v souladu s pravidly GDPR dali a na
            základě tohoto
            souhlasu jim voláme znovu<br>
            cml=2 jsou náhodně generovaná čísla, ty na konci dotazníku žádáme o souhlas s dalším kontaktem.
            <br><br>
            Ostatní výzkumy jsou označené:
            <br><br>
            zdroj=1 jsou firemní/lékařské výzkumy, které voláme z volně dostupných zdrojů<br>
            zdroj=2 jsou databáze klienta/zadavatele výzkumu, který nám dodává kontakty se souhlasem s
            kontaktováním v rámci
            výzkumu
            <br><br>
            Pokud si nejste jistí, zeptejte se supervize. Je nutné, abyste tuto informaci měli před začátkem
            volání výzkumu.
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-title>Obecná rady a konkrétní příklady:</mat-card-title>
          <mat-card-content>
            Pokud respondent namítá a stěžuje si a týká se to osobních nebo kontaktních údajů, případně
            začne být agresivní
            či dokonce vulgární, <b>v žádném případě s ním nic neřešte a hlavně neříkejte nějaké nesmysly a
            buďte celou dobu
            pozitivní</b>. Sdělte mu, že toto není k řešení ve vaší kompetenci a odkažte respondenta na
            <a href="https://www.stemmark.cz/osobniudaje" target="_blank">www.stemmark.cz/osobniudaje</a>,
            kde se dozví vše
            o osobních a kontaktních údajích, se kterými naše společnost
            pracuje. Zde najde kontakt na osobu, která má správu a řešení námitek respondentů v naší
            společnosti na starosti
            – kontakt je <a href="mailto:nechci.vyzkumy@stemmark.cz"
                            target="_blank">nechci.vyzkumy@stemmark.cz</a>
            <br><br>
            Použijte tuto formulaci a nic víc s respondentem neřešte!
            „Omlouvám se, že jsem Vás svým kontaktováním obtěžuji, chtěla jsem Vás jen požádat o Vaše názory
            na téma našeho
            výzkumu. Poznačím tu, abychom Vás již nikdy neobtěžovali. Ve věci osobních a kontaktních údajů
            bych Vás rád/a
            odkázal/a na stránky <a href="https://www.stemmark.cz/osobniudaje"
                                    target="_blank">www.stemmark.cz/osobniudaje</a>, kde naleznete veškeré informace a
            kontakt na osobu, která má
            tyto záležitosti na starosti. ROZLUČTE SE A ROZHOVOR <u>SLUŠNĚ</u> UKONČETE.
            <br><br>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-title>Př. situace:</mat-card-title>
          <mat-card-content>
            <i style="color: blue">Respondent:</i> Kde jste na mě vzali telefonní číslo? Jaké údaje o mě
            máte? Řekněte mi to
            a okamžitě je vymažte!
            <br><br>
            <i style="color: chocolate">Operátor:</i> (pokud náhodně generová čísla)
            Vaše telefonní číslo bylo náhodně vygenerovano, v tuto chvíli známe jen vaše telefonní číslo a
            nevíme, komu jsme
            se dovolali.<br>
            Pokud si přejete, abychom Vás již nikdy nekontaktovali, poznačím to tu a na tomto telefonním
            čísle se tak v
            budoucnu již nestane. ROZLUČTE SE A ROZHOVOR <u>SLUŠNĚ</u> UKONČETE.
            <br><br>
            <i style="color: chocolate">Operátor:</i> (pokud voláte databáze klienta) Vaše telefonní číslo
            nám poskytl
            zadavatel výzkumu, kterého jste
            klientem. Omlouvám se a vaší námitku předáme zadavateli výzkumu.<br> ROZLUČTE SE A ROZHOVOR
            <u>SLUŠNĚ</u>
            UKONČETE.
            <br><br>
            <mat-divider></mat-divider>
            <br>
            <i style="color: blue">Respondent:</i> Chci si stěžovat, přepojte mě na vaše vedení ředitele
            nebo mi dejte na
            někoho z vedení číslo.
            <br><br>
            <i style="color: chocolate">Operátor:</i> Velice se omlouvám, ale nemám možnost Vás přepojit.
            Pokud chcete, bude
            Vás kontaktovat někdo z
            managementu společnosti, nebo můžete Vy, na na emailové adrese nechcivyzkumy@stemmark.cz<br>
            ROZLUČTE SE A ROZHOVOR <u>SLUŠNĚ</u> UKONČETE.
            <br><br>
            <mat-divider></mat-divider>
            <br>
            <i style="color: blue">Respondent:</i> Budu si na vás stěžovat na ÚOOÚ / Telekomunikační úřad/
            EU...
            <br><br>
            <i style="color: chocolate">Operátor:</i> Svá práva můžete uplatnit u kterékoli z těchto
            institucí. Na našich
            stránkách naleznete veškeré
            podrobnosti o Vašich právech a kontakt na osobu pověřenou ochranou osobních údajů - <a
                  href="https://www.stemmark.cz/osobniudaje" target="_blank">www.stemmark.cz/osobniudaje</a>
          </mat-card-content>
        </mat-card>
      </div>
  </mat-tab>
  <mat-tab label="Informace o číslech">
    <div fxLayout="column" fxLayoutGap="10px" style="margin:16px">
        <mat-card>
          <mat-card-title>Jak pracovat s respondenty:</mat-card-title>
          <mat-card-content>
            Když se respondent zeptá, kde jsme vzali jeho číslo:
            <br><br>
            <b>„Vaše číslo bylo náhodně vygenerováno. Funguje to tak, že ústředna dostane trojmístné
              předčíslí (ta jsou věřejně
              známá od operátora) a doplní k tomu dalších 6 náhodných čísel. Proto se stává, že voláme i
              na čísla, která
              neexistují.“</b>
            <br><br>
            V žádném rozhovoru nezmiňujte, že číslo dáváte na Blacklist (vznikají pak situace, kdy nejste
            schopni vysvětlit
            respondentovi jak Blacklist funguje a tím jsou následné problémy), používejte vždy větu:
            <br><br>
            <b>„Označím tedy Vaše telefonní číslo, že si nepřejete být již nikdy kontatován/a a ústředna
              toto číslo již nikdy
              nevytočí, protože ho zablokuje.“</b>
          </mat-card-content>
        </mat-card>
      </div>
  </mat-tab>
  <mat-tab label="O používání aplikace">
    <div fxLayout="column" fxLayoutGap="10px" style="margin:16px">
        V přípravě...
<!--        <mat-card>-->
<!--          <mat-card-title>-->
<!--            <mat-icon>school</mat-icon> Jak používat aplikaci?-->
<!--          </mat-card-title>-->
<!--          <mat-card-content>-->
<!--            <video id="help1" width="75%" height="auto" controls>-->
<!--              <source src="resources/help1.ogv" type="video/ogg; codecs=\" theora, vorbis\"" />-->
<!--              <source src="resources/help1.webm" type="video/webm" />-->
<!--              <source src="resources/help1.mp4" type="video/mp4" />-->
<!--            </video>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->

<!--        <mat-card>-->
<!--          <mat-card-title>-->
<!--            <mat-icon>school</mat-icon>Jak pracovat s dotazníkem?-->
<!--          </mat-card-title>-->
<!--          <mat-card-content>-->
<!--            <video id="help2" width="75%" height="auto" controls>-->
<!--              <source src="resources/help2.ogv" type="video/ogg; codecs=\" theora, vorbis\"" />-->
<!--              <source src="resources/help2.webm" type="video/webm" />-->
<!--              <source src="resources/help2.mp4" type="video/mp4" />-->
<!--            </video>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
      </div>
  </mat-tab>
</mat-tab-group>

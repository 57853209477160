import { Injectable } from '@angular/core';
import { ResearchesService } from '../services/researches.service';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CatiService } from '../services/cati.service';
import { AuthorizationService } from '../services/authorization.service';
import { RespondentModel } from '../models/respondent.model';

@Injectable()
export class RespondentResolver implements Resolve<RespondentModel> {

  constructor(
    private authorization: AuthorizationService,
    private researches: ResearchesService,
    private router: Router,
    private cati: CatiService
  ) {

  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<RespondentModel> {
    let respondent = null;

    try {
      const rid = +route.queryParams['rid'];
      const respid = +route.queryParams['respid'];

      respondent = await this.cati.getRespondent(rid, respid);
    } catch (e) {
      this.router.navigate(['/error']);
    }

    return respondent;
  }
}

<app-page-wrapper>

  <div wrapper-title>
    <div *ngIf="!shift.sfrom">Nový požadavek směny</div>
    <div *ngIf="shift.sfrom">Úprava požadavku směny:</div>
    <small *ngIf="shift.sfrom">{{shift.sfrom | datetime}} - {{shift.sto | datetime}}</small>
  </div>

  <div wrapper-actions>
    <button class="flex-grow" mat-stroked-button color="primary" (click)="shiftForm.ngSubmit.emit()"
            [disabled]="!shiftForm.valid">
      <mat-icon>save</mat-icon>
      Uložit položku
    </button>
  </div>

  <div wrapper-content>
    <ng-form [formGroup]="shiftGroup" (ngSubmit)="submitShift()" #shiftForm="ngForm" class="form">

      <mat-card>
        <mat-card-content fxLayout="column" fxLayoutGap="10px" class="full-size-controls">
          <p *ngIf="shift.sfrom">
            Úprava požadavku je možná pouze v rámci daného dne. Je tedy možně změnit pouze hodiny směny. Pokud vložíte jiné
            datum než datum aktuálně upravované směny, vznkne nová směna - resp. požadavek na její přidání nebo zrušení.
            Starý požadavek tímto nezanikne!
          </p>

          <mat-form-field>
            <mat-placeholder>Plánováno od</mat-placeholder>
            <mat-datetimepicker-toggle [for]="dtpSFrom" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #dtpSFrom type="datetime" openOnFocus="true" timeInterval="30"></mat-datetimepicker>
            <input matInput readonly [errorStateMatcher]="matcher" [matDatetimepicker]="dtpSFrom" [min]="sfromMinDate" [matDatepickerFilter]="dateTimefilterShift" (dateChange)="getSfromDate()"required autocomplete="false" formControlName="sfrom"/>
            <mat-error *ngIf="shiftGroup.get('sfrom').hasError('required')">Prosím zadejte počáteční datum.</mat-error>
            <mat-error *ngIf="shiftGroup.get('sfrom').hasError('minDate')">Počáteční datum nemůže být před dnešním datem.</mat-error>
            <mat-error *ngIf="shiftGroup.hasError('startShiftWeek') && !shiftGroup.get('sfrom').hasError('required') && !shiftGroup.get('sfrom').hasError('minDate')">Směna nemůže začínat před 8:30.</mat-error>
            <mat-error *ngIf="shiftGroup.hasError('startShiftWeekend') && !shiftGroup.get('sfrom').hasError('required') && !shiftGroup.get('sfrom').hasError('minDate')">Směna nemůže začínat před 9:00.</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-placeholder>Plánováno do</mat-placeholder>
            <mat-datetimepicker-toggle [for]="dtpSTo" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #dtpSTo type="datetime" openOnFocus="true" timeInterval="30"></mat-datetimepicker>
            <input matInput readonly [errorStateMatcher]="matcher" [matDatetimepicker]="dtpSTo" [min]="stoMinDate" [matDatepickerFilter]="dateTimefilterShift" required autocomplete="false" formControlName="sto"/>
            <mat-error *ngIf="shiftGroup.get('sto').hasError('required')">Prosím zadejte konečné datum.</mat-error>
            <mat-error *ngIf="shiftGroup.get('sto').hasError('minDate')">Konečné datum nemůže být před počátečním datem.</mat-error>
            <mat-error *ngIf="shiftGroup.hasError('stopShiftWeek') && !shiftGroup.get('sto').hasError('required') && !shiftGroup.get('sto').hasError('minDate')">Směna nemůže končit po 20:00.</mat-error>
            <mat-error *ngIf="shiftGroup.hasError('stopShiftWeekend') && !shiftGroup.get('sto').hasError('required') && !shiftGroup.get('sto').hasError('minDate')">Směna nemůže končit po 15:00.</mat-error>
            <mat-error *ngIf="shiftGroup.hasError('minLength') && !shiftGroup.hasError('stopShiftWeek') && !shiftGroup.hasError('stopShiftWeekend') && !shiftGroup.get('sto').hasError('required') && !shiftGroup.get('sto').hasError('minDate')">Směna nemůže být kratší než 3 hodiny.</mat-error>
            <mat-error *ngIf="shiftGroup.hasError('maxLength') && !shiftGroup.hasError('stopShiftWeek') && !shiftGroup.hasError('stopShiftWeekend') && !shiftGroup.get('sto').hasError('required') && !shiftGroup.get('sto').hasError('minDate')">Směna nemůže být delší než 8 hodin.</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="locality">
            <mat-label>Lokalita</mat-label>
            <mat-select required formControlName="locality">
              <mat-option *ngFor="let item of locality.items" [value]="item.value">{{item.description}}</mat-option>
            </mat-select>
            <mat-error *ngIf="shiftGroup.get('locality').hasError('required')">Prosím zadejte lokalitu.</mat-error>
          </mat-form-field>

          <div fxFlexFill fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row">
              <mat-checkbox formControlName="avail">Dostupnost</mat-checkbox>
              <div fxFlex></div>
              <div mat-dialog-actions fxLauyoutAlign="end">
                <mat-hint><i>Pole označená * jsou povinná.</i></mat-hint>
              </div>
            </div>
          </div>

          <mat-form-field>
            <mat-placeholder>Datum poslední změny</mat-placeholder>
            <mat-datetimepicker-toggle [for]="dtpChangeDate" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #dtpChangeDate type="datetime" openOnFocus="true" timeInterval="30"></mat-datetimepicker>
            <input matInput [matDatetimepicker]="dtpChangeDate" required autocomplete="false" formControlName="change_date"/>
          </mat-form-field>

          <mat-checkbox formControlName="approved">Schváleno</mat-checkbox>

          <mat-form-field>
            <input matInput type="text" placeholder="Schvalující" formControlName="approvedby"/>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </ng-form>
  </div>
</app-page-wrapper>

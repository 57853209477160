import {Component, HostBinding, OnInit} from '@angular/core';
import { ConfigModel } from '../../../../models/config.model';
import { StorageService } from '../../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.css']
})
export class ConfigsComponent implements OnInit {

  @HostBinding('class.flex') flex = true;
  displayedColumns: string[] = ['name', 'value', 'description', 'changed'];
  dataSource: ConfigModel[];
  focusedConfig: ConfigModel;
  storageChangedSubscription: Subscription;

  constructor(
    private storage: StorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.storageChangedSubscription = this.storage.changed.subscribe(
      (change) => {
        if (change.table === 'configs') {
          this.refresh();
        }
      }
    );
  }

  ngOnInit() {
    this.refresh();
  }

  async refresh() {
    this.dataSource = await this.storage.configs.toArray();
  }

  deleteConfig(config: ConfigModel) {
    this.storage.configs.delete(config.name);
  }

  editConfig(config: ConfigModel) {
    this.router.navigate(['config'], { queryParams: { id: config.name } });
  }

  selectConfig(config: ConfigModel) {
    this.focusedConfig = config;
  }

}

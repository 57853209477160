import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthorizationService} from './services/authorization.service';
import {BodyComponent} from './body/body.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ConfigEditComponent} from './body/content/view/configs/config-edit/config-edit.component';
import {ConfigResolver} from './resolvers/config.resolver';
import {ConfigsComponent} from './body/content/view/configs/configs.component';
import {ContentComponent} from './body/content/content.component';
import {DatetimePipe} from './pipes/datetime.pipe';
import {DrawerService} from './services/drawer.service';
import {environment} from '../environments/environment';
import {ErrorComponent} from './body/content/view/error/error.component';
import {ErrorResolver} from './resolvers/error.resolver';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HelpComponent} from './body/content/view/help/help.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {IquestComponent} from './shared/iquest.component';
import {LoginComponent} from './body/content/view/login/login.component';
import {MaterialModule} from './modules/material.module';
import {MenuComponent} from './body/bars/sidebar/menu/menu.component';
import {NewsComponent} from './body/content/view/news/news.component';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {NoteDialogComponent} from './body/content/view/survey/note-dialog/note-dialog.component';
import {PageWrapperComponent} from './shared/page-wrapper.component';
import {PhonebarComponent} from './body/bars/phonebar/phonebar.component';
import {ResearchesService} from './services/researches.service';
import {ResearchResolver} from './resolvers/research.resolver';
import {RespondentResolver} from './resolvers/respondent.resolver';
import {RespondentsComponent} from './body/content/view/respondents/respondents.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {SecondsPipe} from './pipes/seconds.pipe';
import {ServiceWorkerModule} from '@angular/service-worker';
import {SessionbarComponent} from './body/bars/sessionbar/sessionbar.component';
import {SidebarComponent} from './body/bars/sidebar/sidebar.component';
import {SipService} from './services/sip.service';
import {SurveyComponent} from './body/content/view/survey/survey.component';
import {SurveyResolver} from './resolvers/survey.resolver';
import {ToolbarComponent} from './body/bars/toolbar/toolbar.component';
import {ViewComponent} from './body/content/view/view.component';
import {WelcomeComponent} from './body/content/view/welcome/welcome.component';
import {DeferDialogComponent} from './body/content/view/survey/defer-dialog/defer-dialog.component';
import {MessagesService} from './services/messages.service';
import {InactivityService} from './services/inactivity.service';
import { PhoneDialogComponent } from './body/content/view/survey/phone-dialog/phone-dialog.component';
import {BlacklistDialogComponent} from './body/content/view/survey/blacklist-dialog/blacklist-dialog.component';
import { ShiftsComponent } from './body/content/view/shifts/shifts.component';
import { ShiftEditComponent } from './body/content/view/shifts/shift-edit/shift-edit.component';
import { ShiftResolver } from './resolvers/shift.resolver';
import { SyslistPipe } from './pipes/syslist.pipe';
import { CatiInterceptor } from './shared/cati.interceptor';
import { LeaderboardComponent } from './body/content/view/leaderboard/leaderboard.component';
import { ResearchStatisticsComponent } from './body/content/view/statistics/research-statistics/research-statistics.component';
import {StatisticsComponent} from './body/content/view/statistics/statistics.component';
import { ChartsModule } from 'ng2-charts';
import { ErrorDialogComponent } from './shared/error-dialog.component';
import { GlobalErrorHandler } from './shared/error-handler.class';
import { ProfileTabsComponent } from './body/content/view/profile-tabs/profile-tabs.component';
import { ProfileComponent } from './body/content/view/profile-tabs/profile/profile.component';
import { DetailComponent } from './body/content/view/profile-tabs/detail/detail.component';
import { PasswordComponent } from './body/content/view/profile-tabs/password/password.component';
import { DetailwindowComponent } from './body/content/view/survey/detailwindow/detailwindow.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {MedalPipe} from './pipes/medal.pipe';
import {MinutesToHoursPipe} from './pipes/minutes-to-hours.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog.component';
import {MatPaginatorIntlCzClass} from './shared/mat-paginator-intl-cz.class';
import {MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {CodelistPipe} from './pipes/codelist.pipe';
import {CommonModule, registerLocaleData} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {MatBadgeModule} from '@angular/material/badge';
import { CallsComponent } from './body/content/view/calls/calls.component';
import { UserStatisticsComponent } from './body/content/view/statistics/user-statistics/user-statistics.component';
import localeCs from '@angular/common/locales/cs';

registerLocaleData(localeCs, 'cs');

@NgModule({
  declarations: [
    AppComponent,
    BodyComponent,
    ConfigEditComponent,
    ConfigsComponent,
    ContentComponent,
    DatetimePipe,
    ErrorComponent,
    HelpComponent,
    IquestComponent,
    LoginComponent,
    MenuComponent,
    NewsComponent,
    NoteDialogComponent,
    PageWrapperComponent,
    PhonebarComponent,
    RespondentsComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    SecondsPipe,
    SessionbarComponent,
    SidebarComponent,
    SurveyComponent,
    ToolbarComponent,
    ViewComponent,
    WelcomeComponent,
    DeferDialogComponent,
    PhoneDialogComponent,
    BlacklistDialogComponent,
    ShiftsComponent,
    ShiftEditComponent,
    SyslistPipe,
    LeaderboardComponent,
    ResearchStatisticsComponent,
    StatisticsComponent,
    ErrorDialogComponent,
    ProfileTabsComponent,
    ProfileComponent,
    DetailComponent,
    PasswordComponent,
    DetailwindowComponent,
    PasswordComponent,
    TimeAgoPipe,
    MedalPipe,
    MinutesToHoursPipe,
    ConfirmationDialogComponent,
    CodelistPipe,
    CallsComponent,
    UserStatisticsComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    ChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatPaginatorModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    MatBadgeModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
        positionClass: 'toast-bottom-center',
        preventDuplicates: true,
        maxOpened: 5
      }
    )
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CatiInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCzClass},
    AuthorizationService,
    ConfigResolver,
    DrawerService,
    ErrorResolver,
    InactivityService,
    ResearchesService,
    MessagesService,
    ResearchResolver,
    RespondentResolver,
    SipService,
    SurveyResolver,
    ShiftResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl, FormGroup, FormGroupDirective, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MessagesService } from '../../../../../services/messages.service';
import { AuthorizationService } from '../../../../../services/authorization.service';
import {errorCodes, passwordMinLength} from '../../../../../shared/cati.consts';

export class NewPasswordErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})

export class PasswordComponent implements OnInit {

  public hidePassword: boolean;
  public hideNewPassword: boolean;
  public hideConfirmPassword: boolean;
  public passwordChangeForm: FormGroup;
  public matcher = new NewPasswordErrorMatcher();
  public passwordMinLength: number;

  constructor(
      private formBuilder: FormBuilder,
      private messages: MessagesService,
      private authService: AuthorizationService ) {
    this.passwordChangeForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(passwordMinLength)]],
      confirmPassword: ['', [Validators.required]],
      password: ['', [Validators.required]]
    }, {
      validator: this.checkNewPassword
    });
    this.passwordMinLength = passwordMinLength;
  }

  ngOnInit() {
    this.hidePassword = true;
    this.hideNewPassword = true;
    this.hideConfirmPassword = true;
  }

  public async onSubmit() {
    const formValue = this.passwordChangeForm.value;
    let message;
    try {
      message = await this.authService.changePassword(formValue.password, formValue.newPassword);
      this.passwordChangeForm.reset();
    } catch (err) {
      message = errorCodes[err.id];
    }
    this.messages.show(message);
  }

  private checkNewPassword(group: FormGroup) {
    const newPassword = group.controls.newPassword.value;
    const confirmPassword = group.controls.confirmPassword.value;

    return newPassword === confirmPassword ? null : {notSame: true};
  }

}

import {NgZone, Pipe, PipeTransform} from '@angular/core';
import {Observable, Observer} from 'rxjs';

interface ProcessOutput {
  text: string; // Convert timestamp to string
  timeToUpdate: number; // Time until update in milliseconds
}

@Pipe({
  name: 'timeago',
  pure: true
})
export class TimeAgoPipe implements PipeTransform {

  constructor(private ngZone: NgZone) { }

  private process = (timestamp: number): ProcessOutput => {
    let text: string;
    const timeToUpdate = 10000;

    if (timestamp === 0) {
      text = '';
    } else {
      const now = new Date();
      const timeAgo: number = now.getTime() - timestamp;

      let seconds = parseInt(String(timeAgo / 1000), 10);

      const days = Math.floor(seconds / (3600 * 24));
      seconds -= days * 3600 * 24;
      const hrs = Math.floor(seconds / 3600);
      seconds -= hrs * 3600;
      const mnts = Math.floor(seconds / 60);

      const daysText = 'd ';
      const hrsText = 'h ';
      const mntsText = 'm ';
      const secondsText = 's ';

      if (days === 0 && hrs === 0 && mnts === 0) {
        text = seconds + secondsText;
      } else if (days === 0 && hrs === 0) {
        text = mnts + mntsText;
      } else if (days === 0 && hrs !== 0 && mnts === 0) {
        text = hrs + hrsText;
      } else if (days !== 0 && hrs === 0 && mnts === 0) {
        text = days + daysText;
      } else if (days === 0 && hrs !== 0 && mnts !== 0) {
        text = hrs + hrsText + mnts + mntsText;
      } else if (days !== 0 && hrs === 0 && mnts !== 0) {
        text = days + daysText + mnts + mntsText;
      } else if (days !== 0 && hrs !== 0 && mnts === 0) {
        text = days + daysText + hrs + hrsText;
      } else {
        text = days + daysText + hrs + hrsText + mnts + mntsText;
      }
    }

    return {
      text,
      timeToUpdate
    };

  }

  public transform = (value: string | Date): Observable<string> => {
    let d: Date;
    if (value instanceof Date) {
      d = value;
    } else {
      d = new Date(value);
    }
    // time value in milliseconds
    const timestamp = d.getTime();

    let timeoutID;

    return Observable.create((observer: Observer<string>) => {
      let latestText = '';

      // Repeatedly set new timeouts for new update checks.
      const registerUpdate = () => {
        const processOutput = this.process(timestamp);
        if (processOutput.text !== latestText) {
          latestText = processOutput.text;
          this.ngZone.run(() => {
            observer.next(latestText);
          });
        }

        timeoutID = setTimeout(registerUpdate, processOutput.timeToUpdate);
      };

      this.ngZone.runOutsideAngular(registerUpdate);

      // Return teardown function
      return () => {
        if (timeoutID) {
          clearTimeout(timeoutID);
        }
      };
    });
  }

}

<app-page-wrapper>
  <div wrapper-title>
    Detail operátora
  </div>

  <div wrapper-content>
    <div fxLayout="row" fxLayoutAlign="start center">
      <h2 *ngIf="hourRate">Tento měsíc pracujete za: {{hourRate}} Kč/h</h2>
    </div>
    <div fxFlax fxLayout="row">
      <div fxFlex fxLayout="column">
          <mat-checkbox
                  *ngFor="let declaration of declarations"
                  [checked]="declaration.has"
                  onclick="return false;">
            {{declaration.description}}
          </mat-checkbox>
      </div>
    </div>
  </div>
</app-page-wrapper>

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { StorageService } from '../../../../../services/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ShiftModel } from '../../../../../models/shift.model';
import {ShiftsService} from '../../../../../services/shifts.service';
import {CodelistsService} from '../../../../../services/codelists.service';
import {SyslistModel} from '../../../../../models/syslist.model';
import {MessagesService} from '../../../../../services/messages.service';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {CATI} from '../../../../../shared/cati.functions';

export class NewShiftErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-shift-edit',
  templateUrl: './shift-edit.component.html',
  styleUrls: ['./shift-edit.component.css']
})
export class ShiftEditComponent implements OnInit {

  public shiftGroup: FormGroup;
  public shift: ShiftModel;
  public locality: SyslistModel;
  public matcher = new NewShiftErrorMatcher();

  public sfromMinDate: Date;
  public stoMinDate: Date;
  public dateTimefilterShift = CATI.dateTimefilterShift;

  constructor(
    private storage: StorageService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private shifts: ShiftsService,
    private codelists: CodelistsService,
    private messages: MessagesService,
    private router: Router,
    private dateAdapter: DateAdapter<Date>,
    private cd: ChangeDetectorRef,
  ) {
    this.shiftGroup = this.formBuilder.group({
      sid: [{ disabled: true, value: ''}],
      sfrom: ['', [Validators.required, RxwebValidators.minDate({value: new Date()})]],
      sto: ['', [Validators.required, RxwebValidators.minDate({fieldName: 'sfrom'})]],
      avail: ['', Validators.required],
      locality: ['', Validators.required],
      change_date: [{ disabled: true, value: ''}],
      approved: [{ disabled: true, value: ''}],
      approvedby: [{ disabled: true, value: ''}]
    }, {validator: [this.minMaxShift('sfrom', 'sto'), this.startStopShift('sfrom', 'sto')]});

    this.dateAdapter.getFirstDayOfWeek = () => 1;

    this.codelists.syslist('c13').then(
      locality => this.locality = locality
    );

    this.activeRoute.data.subscribe(
      (data) => this.loadShift(data.shift)
    );
  }

  ngOnInit() {
    this.sfromMinDate = new Date(new Date().setHours(0, 0, 0, 0));
  }

  loadShift(shift: ShiftModel) {
    this.shift = shift;
    this.shiftGroup.patchValue(shift);
  }

  async submitShift() {
    try {
      Object.assign(this.shift, this.shiftGroup.value);
      await this.shifts.addShiftRequest(this.shift);
      this.router.navigate(['/shifts']);
      this.messages.show('Požadavek směny byl uložen.', 'info');
    } catch (e) {
      this.messages.show(`Nepodařilo se vložit požadavek. ${e.message}`, 'error');
    }
  }

  getSfromDate() {
    this.stoMinDate = this.shiftGroup.value.sfrom;
  }

  startStopShift(sfrom: string, sto: string) {
    return (group: FormGroup): { [key: string]: boolean } => {
      const from = new Date(group.controls[sfrom].value);
      const to = new Date(group.controls[sto].value);
      const fromDay = from.getDay();
      const toDay = to.getDay();
      const fromHour = from.getHours();
      const toHour = to.getHours();
      const fromMinutes = from.getMinutes();
      const toMinutes = to.getMinutes();

      if (fromDay > 0 && fromDay < 6) {
        if ((fromHour < 8) || (fromHour === 8 && fromMinutes < 30)) {
          return {
            'startShiftWeek': true
          };
        }
      }

      if (fromDay === 0 || fromDay === 6) {
        if ((fromHour < 9) || (fromHour === 8 && fromMinutes < 0)) {
          return {
            'startShiftWeekend': true
          };
        }
      }

      if (toDay > 0 && toDay < 6) {
        if ((toHour > 20) || (toHour === 20 && toMinutes > 0)) {
          return {
            'stopShiftWeek': true
          };
        }
      }

      if (toDay === 0 || toDay === 6) {
        if ((toHour > 15) || (toHour === 15 && toMinutes > 0)) {
          return {
            'stopShiftWeekend': true
          };
        }
      }

      return null;
    };
  }

  minMaxShift(sfrom: string, sto: string) {
    return (group: FormGroup): { [key: string]: boolean } => {
      const from = group.controls[sfrom].value;
      const to = group.controls[sto].value;

      if (to - from < 10800000) {
        return {
          'minLength': true
        };
      }

      if (to - from > 28800000) {
        return {
          'maxLength': true
        };
      }
      return null;
    };
  }

}

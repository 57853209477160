import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ShiftModel } from '../models/shift.model';
import { LoggingService } from '../services/logging.service';
import {ShiftsService} from '../services/shifts.service';

@Injectable()
export class ShiftResolver implements Resolve<ShiftModel> {

  constructor(
    private logging: LoggingService,
    private storageService: StorageService,
    private shifts: ShiftsService,
    private router: Router
  ) {

  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ShiftModel> {
    try {
      const sid = route.queryParams['id'];

      let shift;

      if (sid === 'new') {
        shift = new ShiftModel();
      } else {
        shift = this.shifts.getShift(sid);
      }

      if (shift) {
        return shift;
      } else {
        throw new Error('Plánovaná směna nenalezena!');
      }
    } catch (e) {
      this.logging.logError(this.constructor.name, `Chyba při sestavování požadavku směny: ${e.message}`);
      this.router.navigate(['error']);
    }
  }
}

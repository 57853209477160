export class UserModel {

  public oid: number;
  public username: string;
  public firstname: string;
  public surname: string;
  public email: string;
  public token: string;
  public line: string;
  public agency: number;
  public fids: string[];

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  public get tester(): boolean {
    const canDoQuest = this.fids.includes('2');
    const canDoTest = this.fids.includes('3');
    const canShowAllResearches = this.fids.includes('44');

    return ((canDoTest && !canDoQuest) || canShowAllResearches);
  }
}

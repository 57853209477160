import { Injectable } from '@angular/core';
import {ShiftModel} from '../models/shift.model';
import {BehaviorSubject} from 'rxjs';
import {CatiService} from './cati.service';
import {LoggingService} from './logging.service';

/**
 * Service is taking care of Shift Requests manipulation. Mainly just for ShiftsComponent purposes.
 */
@Injectable({
  providedIn: 'root'
})
export class ShiftsService {

  public shifts: BehaviorSubject<ShiftModel[]> = new BehaviorSubject<ShiftModel[]>(null);

  constructor(
    private cati: CatiService,
    private logger: LoggingService
  ) { }

  /**
   * Refreshing shifts list. After successfull GET all subscribers are notified.
   */
  public async refresh() {
    try {
      this.shifts.next(
        await this.cati.getShifts()
      );
    } catch (e) {
      this.logger.logError('shifts.service', e.message);
    }
  }

  /**
   * Add shift request into API.
   * @param shift ShiftModel Shift to be added into the API.
   */
  public async addShiftRequest(shift: ShiftModel) {
    try {
      const result = await this.cati.addShiftRequest(shift);
      this.refresh();
      return result;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Finds a ShiftModel according to given id. Shift will be found just in local array.
   * @param sid number Id to be found in the service.
   */
  public getShift(sid: any): ShiftModel {
    return this.shifts.value.find(
      item => item.sid == sid
    );
  }
}

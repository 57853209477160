import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CatiService } from '../../../../services/cati.service';
import { NewsModel } from '../../../../models/news.model';
import {MessageModel} from '../../../../models/message.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, OnDestroy {

  @HostBinding('class.flex') flex = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public news: NewsModel[] = [];
  public dataSource: any = new MatTableDataSource<MessageModel[]>();
  public displayedColumns: string[] = ['insertdate', 'sender', 'content'];
  public index = 0;
  public now = new Date();

  private timer: Subscription;

  constructor(
    private cati: CatiService
  ) { }

  ngOnInit() {
    this.timer = timer(0, 5000).subscribe(
      async value => {
        this.refresh();
      }
    );

    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
}

  public async refresh() {
    this.index = 0;
    this.news = await this.cati.getNews();
    this.dataSource.data = await this.cati.getArchivedMessages();
  }

  public get current(): NewsModel {
    return this.news[this.index];
  }

  public next() {
    this.index = Math.min(this.index + 1, this.news.length - 1);
  }

  public prev() {
    this.index = Math.max(this.index - 1, 0);
  }

}

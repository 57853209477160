<app-page-wrapper>
  <div wrapper-title>Žebříček
  </div>

  <div wrapper-actions>
    Podíl z odpracovaných minut callcentra tento týden <i>(aktualizováno před {{leaderboard?.ttl}}s).</i>
  </div>

  <div wrapper-content>
    <table mat-table [dataSource]="dataSource" fxFlex>

      <ng-container matColumnDef="medal">
        <th mat-header-cell *matHeaderCellDef> # </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon color="primary" *ngIf="element.locality === 1"> {{element.medal | medal: element.locality}} </mat-icon>
          <mat-icon color="accent" *ngIf="element.locality === 2"> {{element.medal | medal: element.locality}} </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef> Operátor </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'strikeout': !element.state}"> {{element.firstname}} {{element.surname}} </td>
      </ng-container>

      <ng-container matColumnDef="minutes">
        <th mat-header-cell *matHeaderCellDef> Celkem </th>
        <td mat-cell *matCellDef="let element"> {{element.minutes | minutestohours}} </td>
      </ng-container>

      <ng-container matColumnDef="percents">
        <th mat-header-cell *matHeaderCellDef> Podíl </th>
        <td mat-cell *matCellDef="let element"> {{element.percents}} %</td>
      </ng-container>

      <ng-container matColumnDef="homeoper">
        <th mat-header-cell *matHeaderCellDef> Domácí </th>
        <td mat-cell *matCellDef="let element"> <mat-icon color="primary">{{element.homeoper ? 'home' : 'business'}}</mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="locality">
        <th mat-header-cell *matHeaderCellDef> Lokalita </th>
        <td mat-cell *matCellDef="let element"> {{element.locality | syslist: 'c13' | async }} </td>
      </ng-container>

      <ng-container matColumnDef="lastping">
        <th mat-header-cell *matHeaderCellDef> Poslední odezva </th>
        <td mat-cell *matCellDef="let element"> {{element.lastping | datetime}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</app-page-wrapper>

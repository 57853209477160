<mat-toolbar color="primary" fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center">
  <button mat-mini-fab (click)="toggleDrawer()" color="primary" matTooltip="Zobrazit skrýt levou lištu s tlačítky."><mat-icon>menu</mat-icon></button>
  <div fxFlex *ngIf="user" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="0px">
    <div fxFlex fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px" *ngIf="!interview">
      <mat-form-field fxFlex>
        <mat-select fxFlex [(ngModel)]="researchesService.rid">
          <mat-option style="height: 48px" *ngFor="let research of researches" [value]="research.rid">
            {{research.rid}} - {{research.rname}} [{{research.intralognick}}]
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-mini-fab fxFlex="none" (click)="refresh()" color="primary" *ngIf="user" matTooltip="Obnovit seznam výzkumů."><mat-icon>refresh</mat-icon></button>
    </div>
    <div *ngIf="interview" fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="stretch" class="interview">
      <div fxLayout="column" fxLayoutAlign="stretch">
        <div fxLayout="row" fxLayoutGap="10px"><div>Výzkum:</div><div>{{research?.rname}} ({{research?.rid}})</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Respondent:</div><div>{{respondent?.respid}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Interview:</div><div>{{interview?.intid}}</div></div>
      </div>
      <div fxLayout="column" fxLayoutAlign="stretch">
        <div fxLayout="row" fxLayoutGap="10px"><div>Začátek:</div><div>{{interview?.tstart | datetime}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Kontakt:</div><div>{{interview?.connecttime | datetime}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Délka:</div><div>{{interviewTime | seconds}}</div></div>
      </div>
      <div fxLayout="column" fxLayoutAlign="stretch">
        <div fxLayout="row" fxLayoutGap="10px"><div>Telefon 1:</div><div>{{respondent?.phone1}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Telefon 2:</div><div>{{respondent?.phone2}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Volané č.:</div><div>{{callingNow}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div></div><div></div></div>
      </div>
      <div fxLayout="column" fxLayoutAlign="stretch">
        <div fxLayout="row" fxLayoutGap="10px"><div>Průběh:</div><div>{{progress?.progress}}%</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div>Otázka:</div><div>{{progress?.qname}}</div></div>
        <div fxLayout="row" fxLayoutGap="10px"><div></div><div></div></div>
      </div>
    </div>
  </div>
  <div fxFlex *ngIf="!user"></div>
  <div fxLayout="column" fxLayoutAlign="end" class="clock" matTooltip="Aktuální čas.">
    <div fxLayout="row" fxLayoutAlign="center">{{currentTime | date:'HH:mm:ss'}}</div>
    <div fxLayout="row" fxLayoutAlign="center">{{currentTime | date:'dd.MM.yy'}}</div>
  </div>
</mat-toolbar>

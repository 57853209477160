import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {timer, Subscription} from 'rxjs';

export interface NoteDialogData {
  note: string;
}

@Component({
  selector: 'app-note-dialog',
  template: `
      <mat-progress-bar mode="determinate" [value]="progressbarValue"></mat-progress-bar>
      <ng-form [formGroup]="noteGroup" #noteForm="ngForm" class="form">
          <div fxFlex fxLayout="column">

              <div mat-dialog-title fxFlex="none">
                  Poznámka
              </div>

              <div mat-dialog-content fxFlex="grow">
                  <div fxFlex>
                      <mat-form-field fxFlex>
                          <textarea (focus)="stopTimer()" matInput matTextareaAutosize matAutosizeMinRows="6" [(ngModel)]="data.note" fxFlex formControlName="note"></textarea>
                          <mat-error *ngIf="noteGroup.get('note').hasError('required')">Prosím zadejte poznámku.</mat-error>
                          <mat-error *ngIf="noteGroup.get('note').hasError('maxLength')">Poznámka nemůže být delší než 256 znaků.
                          </mat-error>
                      </mat-form-field>
                  </div>
              </div>

              <div mat-dialog-actions fxFlex="none">
                  <button mat-button [mat-dialog-close]="data" [disabled]="!noteForm.valid" cdkFocusInitial>Uložit</button>
                  <button mat-button [mat-dialog-close]="null">Zavřít</button>
              </div>
          </div>
      </ng-form>
  `,
})
export class NoteDialogComponent implements OnInit, OnDestroy {

  public noteGroup: FormGroup;

  progressbarValue = 0;

  public timer: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NoteDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: NoteDialogData
  ) {
    this.noteGroup = this.formBuilder.group({
      note: ['', [Validators.required, RxwebValidators.maxLength({value: 256})]]
    });
  }

  ngOnInit() {
    this.timer = timer(0, 1000).subscribe(
      value => {
        this.progressbarValue = value * 100 / 10;

        if (value === 10) {
          this.dialogRef.close(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }

  stopTimer() {
    this.timer.unsubscribe();
    this.progressbarValue = 100;
  }

}

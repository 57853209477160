import { Component } from '@angular/core';
import { MenuItem, MenuService } from '../../../../services/menu.service';
import {MessagesService} from '../../../../services/messages.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  items: MenuItem[];
  messagesCount = 0;

  constructor(
    private menu: MenuService,
    private messages: MessagesService
  ) {
    this.menu.items.subscribe(
      items => this.items = items
    );

    this.messages.messagesCount.subscribe(
      count => this.messagesCount = count
    );
  }

  public executeItem(item: MenuItem) {
    this.menu.executeItem(item);
  }

}

<ng-form [formGroup]="deferGroup" (ngSubmit)="submitDefer()" #deferForm="ngForm" class="form">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="5px">
    <div fxLayout="row">
      <h1 mat-dialog-title>Odložení hovoru</h1>
      <div fxFlex></div>
      <div fxLauyoutAlign="end">
        <mat-hint><i>Ukončení výzkumu: {{research?.waveend | date}}</i></mat-hint>
      </div>
    </div>

    <div fxFlex="none" fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
        <mat-form-field>
          <mat-placeholder>Datum a čas</mat-placeholder>
          <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
          <mat-datetimepicker #datetimePicker type="datetime" timeInterval="5" ></mat-datetimepicker>
          <input matInput readonly [errorStateMatcher]="matcher" [matDatetimepicker]="datetimePicker" required autocomplete="false" [min]="minDate" [max]="maxDate" [matDatepickerFilter]="dateTimefilterDefer" (dateChange)="timeChange($event)" formControlName="tocall">
          <mat-error *ngIf="deferGroup.get('tocall').hasError('required')">Prosím zadejte datum odložení.</mat-error>
          <mat-error *ngIf="deferGroup.get('tocall').hasError('minDate')">Datum odložení nemůže být před dnešním datem.</mat-error>
          <mat-error *ngIf="deferGroup.get('tocall').hasError('maxDate')">Datum odložení nemůže být po ukončení výzkumu: {{research?.waveend | date}}</mat-error>
          <mat-error *ngIf="deferGroup.hasError('startShiftWeek') && !deferGroup.get('tocall').hasError('required') && !deferGroup.get('tocall').hasError('minDate') && !deferGroup.get('tocall').hasError('maxDate')">Hovor nemůže být odložen na čas před začátkem směny.</mat-error>
          <mat-error *ngIf="deferGroup.hasError('startShiftWeekend') && !deferGroup.get('tocall').hasError('required') && !deferGroup.get('tocall').hasError('minDate') && !deferGroup.get('tocall').hasError('maxDate')">Hovor nemůže být odložen na čas před začátkem směny.</mat-error>
          <mat-error *ngIf="deferGroup.hasError('stopShiftWeek') && !deferGroup.get('tocall').hasError('required') && !deferGroup.get('tocall').hasError('minDate') && !deferGroup.get('tocall').hasError('maxDate')">Hovor nemůže být odložen na čas po konci směny.</mat-error>
          <mat-error *ngIf="deferGroup.hasError('stopShiftWeekend') && !deferGroup.get('tocall').hasError('required') && !deferGroup.get('tocall').hasError('minDate') && !deferGroup.get('tocall').hasError('maxDate')">Hovor nemůže být odložen na čas po konci směny.</mat-error>
        </mat-form-field>

        <mat-radio-group aria-label="Denní doba" fxLayout="column" fxLayoutGap="10px" (change)="daytimeChange($event)" formControlName="tocall_exacttime">
          <mat-radio-button [value]="0">Volat za 1 hodinu</mat-radio-button>
          <mat-radio-button [value]="1">Ráno</mat-radio-button>
          <mat-radio-button [value]="2">Dopoledne</mat-radio-button>
          <mat-radio-button [value]="3">Odpoledne</mat-radio-button>
          <mat-radio-button [value]="4">Podvečer</mat-radio-button>
        </mat-radio-group>

        <mat-form-field>
          <input matInput type="phone" placeholder="Telefon" formControlName="phone"/>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxLayout="column" fxLayoutGap="11px">
        <mat-form-field>
          <mat-select placeholder="Pohlaví" formControlName="sex">
            <mat-option *ngFor="let item of sex.items" [value]="item.code">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" placeholder="Věk" formControlName="age"/>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Vzdělání" formControlName="edu">
            <mat-option *ngFor="let item of edu.items" [value]="item.code">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Okres" formControlName="distr">
            <mat-option *ngFor="let item of distr.items" [value]="item.code">{{item.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="grow" fxLayout="column">
      <mat-form-field>
        <textarea placeholder="Poznámka" [errorStateMatcher]="matcher" matInput matTextareaAutosize required matAutosizeMinRows="3" formControlName="note"></textarea>
        <mat-error *ngIf="deferGroup.get('note').hasError('required')">Prosím zadejte poznámku.</mat-error>
        <mat-error *ngIf="deferGroup.get('note').hasError('maxLength')">Poznámka nemůže být delší než 256 znaků.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxFlexFill fxLayout="column" fxLayoutGap="5px">
    <div fxLayout="row">
      <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="data" [disabled]="!deferForm.valid" (click)="deferForm.ngSubmit.emit()" cdkFocusInitial>Uložit</button>
        <button mat-button [mat-dialog-close]="null">Zavřít</button>
      </div>
      <div fxFlex></div>
      <div mat-dialog-actions fxLauyoutAlign="end">
        <mat-hint><i>Pole označená * jsou povinná.</i></mat-hint>
      </div>
    </div>
  </div>
</ng-form>

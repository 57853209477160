import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import 'dexie-observable';
import { Subject } from 'rxjs';
import { ConfigModel } from '../models/config.model';
import { CallModel} from '../models/call.model';
import * as moment from 'moment';

export type ConfigsKey = string;
export type CallsKey = [number, number, number];

@Injectable({
  providedIn: 'root'
})
export class StorageService extends Dexie {

  public configs: Dexie.Table<ConfigModel, ConfigsKey>;
  public calls: Dexie.Table<CallModel, CallsKey>;
  public changed: Subject<any> = new Subject<any>();

  constructor() {
    super('xquest-cati');

    this.version(1).stores(
      {
        configs: `name`,
        calls: `[rid+respid+intid]`
      }
    );

    this.configs.mapToClass(ConfigModel);
    this.calls.mapToClass(CallModel);

    this.on('changes',
      (changes) => {
        changes.forEach(
          (change) => {
            this.changed.next(change);
          }
        );
      }
    );

    this.cleareOldCalls();
    this.generateClientId();
  }


  public generateId(size?: number): string {
    return Math.random().toString(36).substring(size);
  }

  public async addCall(call: CallModel) {
    return await this.calls.put(call);
  }

  public async cleareOldCalls() {
    const twoDaysAgo = moment().subtract(2, 'days').toDate();
    const collections = this.calls.filter(item => item.date < twoDaysAgo);
    return await collections.delete();
  }

  public async getAllCalls() {
    return await this.calls.toArray();
  }

  public async generateClientId() {
    const clid = await this.configs.where('name').equals('app.clid').toArray();

    if (clid && clid.length < 1) {
      const rnd = {
        name: 'app.clid',
        value: this.generateId(6),
        description: 'Identifikátor zařízení'
      };
      await this.configs.put(rnd);
    }
  }

}


export class UserScoreModel {
    public evaldate: any;
    public points: number;
    public evaluator: string;
    public note: string;

    constructor(obj?: any) {
        Object.assign(this,  obj);
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CatiService } from '../services/cati.service';
import { AuthorizationService } from '../services/authorization.service';
import { SurveyModel } from '../models/survey.model';
import { ResearchResolver } from './research.resolver';
import { RespondentResolver } from './respondent.resolver';
import { LoggingService } from '../services/logging.service';
import { InterviewService } from '../services/interview.service';
import { errorCodes } from '../shared/cati.consts';
import { StorageService } from '../services/storage.service';
import {SessionsService} from '../services/sessions.service';

@Injectable()
export class SurveyResolver implements Resolve<SurveyModel> {

  constructor(
    private authorization: AuthorizationService,
    private router: Router,
    private cati: CatiService,
    private researchResolver: ResearchResolver,
    private respondentResolver: RespondentResolver,
    private interviews: InterviewService,
    private sessions: SessionsService,
    private logging: LoggingService,
    private storage: StorageService
  ) {

  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SurveyModel> {
    let survey = null;

    try {
      survey = new SurveyModel();
      let method = 'click';
      const rid = route.queryParams['rid'];
      let respid = route.queryParams['respid'];
      const manuallyadded = route.queryParams['man']

      if (!respid) {
        respid = await this.cati.tossRespondent(rid, this.authorization.oid, this.sessions.testmode.value);
        route.queryParams = Object.assign({ respid: respid.toString() }, route.queryParams);
        method = 'toss';
      }

      survey.research = await this.researchResolver.resolve(route, state);
      survey.respondent = await this.respondentResolver.resolve(route, state);
      survey.interview = await this.interviews.startInterview(survey.research, survey.respondent);
      survey.interview.manuallyadded = manuallyadded;
      survey.history = await this.cati.getCallsHistory(rid, respid);
      survey.interview.method = method;
    } catch (e) {
      const message = errorCodes.hasOwnProperty(e.id) ? errorCodes[e.id] : e.message;
      this.logging.logError('survey.resolver', message);
      this.router.navigate(['/error']);
    }

    return survey;
  }
}

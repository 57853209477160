import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../services/storage.service';
import { CallModel } from '../../../../models/call.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.css']
})
export class CallsComponent implements OnInit {

  public data = new MatTableDataSource<CallModel>();
  public displayedColumns: string[] = ['date', 'oid', 'rid', 'respid', 'intid', 'callres', 'method', 'tocall'];

  constructor(
    private storage: StorageService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    this.data.data = await this.storage.getAllCalls();
  }

  public filter(value: string) {
    this.data.filter = value.trim().toLowerCase();
  }
}

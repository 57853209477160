import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface PhoneDialogData {
  phone: string;
}

@Component({
  selector: 'app-phone-dialog',
  template: `
      <ng-form [formGroup]="phoneDialogGroup" #phoneDialogForm="ngForm" class="form">
          <div fxFlex fxLayout="column">

              <div mat-dialog-title fxFlex="none">
                  Nové číslo
              </div>

              <div mat-dialog-content fxFlex="grow">
                  <div fxFlex>
                      <mat-form-field fxFlex>
                          <input matInput type="tel" [(ngModel)]="data.phone" formControlName="phone"/>
                          <mat-error *ngIf="phoneDialogGroup.get('phone').hasError('required')">Prosím zadejte telefonní číslo.</mat-error>
                          <mat-error *ngIf="phoneDialogGroup.get('phone').hasError('pattern')">Prosím zadejte platné telefonní číslo.</mat-error>
                      </mat-form-field>
                  </div>
              </div>

              <div mat-dialog-actions fxFlex="none">
                  <button mat-button [mat-dialog-close]="data" [disabled]="!phoneDialogForm.valid" cdkFocusInitial>Uložit</button>
                  <button mat-button [mat-dialog-close]="null">Zavřít</button>
              </div>

          </div>
      </ng-form>
  `,
})
export class PhoneDialogComponent implements OnInit {

  public phoneDialogGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PhoneDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: PhoneDialogData
  ) {
    this.phoneDialogGroup = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.pattern('^(?:[+\\d].*\\d|\\d)$')]]
    });
  }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ResearchModel} from '../models/research.model';
import {ConfigService} from './config.service';
import {AuthorizationService} from './authorization.service';
import {map} from 'rxjs/operators';
import {AnyResponse, BooleanResponse, NumberResponse, ServerResponse} from '../shared/response.class';
import {RespondentModel} from '../models/respondent.model';
import {MessageModel} from '../models/message.model';
import {CodelistModel} from '../models/codelist.model';
import {ProgressModel} from '../models/progress.model';
import {NewsModel} from '../models/news.model';
import {ClitemModel} from '../models/clitem.model';
import {ShiftModel} from '../models/shift.model';
import {SyslistModel} from '../models/syslist.model';
import {SyslistItemModel} from '../models/syslistitem.model';
import {LeaderboardModel} from '../models/leaderboard.model';
import {ResearchStatisticsModel} from '../models/research-statistics.model';
import {CallHistoryModel} from '../models/call-history.model';
import {UserScoreModel} from '../models/user-score.model';
import {UserDeclarationModel} from '../models/user-declaration';
import {UserStatisticsModel} from '../models/user-statistics.model';


class GetResearchesResponse extends ServerResponse {
  data: ResearchModel[];
}

class GetDeferredResponse extends ServerResponse {
  data: RespondentModel[];
}

class GetMessagesResponse extends ServerResponse {
  data: MessageModel[];
}

class GetCodelistResponse extends ServerResponse {
  data: CodelistModel;
}

class GetProgressResponse extends ServerResponse {
  data: ProgressModel;
}

class GetRespondentResponse extends ServerResponse {
  data: RespondentModel;
}

class GetNewsResponse extends ServerResponse {
  data: NewsModel[];
}

class GetShiftsResponse extends ServerResponse {
  data: ShiftModel[];
}

class GetSyslistResponse extends ServerResponse {
  data: SyslistModel;
}

class GetLeaderboardResponse extends ServerResponse {
  data: LeaderboardModel;
}

class GetResearchStatisticsResponse extends ServerResponse {
  data: ResearchStatisticsModel;
}

class GetUserStatisticsResponse extends ServerResponse {
  data: UserStatisticsModel;
}

class GetCallHistoryModelResponse extends ServerResponse {
  data: CallHistoryModel[];
}

class GetScoreResponse extends ServerResponse {
  data: UserScoreModel[];
}

class GetDeclarationResponse extends ServerResponse {
  data: UserDeclarationModel[];
}

class GetRateResponse extends ServerResponse {
  data: number;
}

class GetDetailContent extends ServerResponse {
  data: string;
}

class GetIntro extends ServerResponse {
  data: string;
}

class GetCallsCountResponse extends ServerResponse {
  data: number;
}

class GetDefferedCount extends ServerResponse {
  data: number;
}

export interface RespondentsFilter {
  date: {
    active: boolean,
    value: Date
  };
  oid: {
    active: boolean;
    value: number;
  };
  sort: {
    active: boolean;
    field: string;
    dir: 'ASC' | 'DESC';
  }
}

/**
 * Service taking care of all routes in cati server. Each response is "unpacked" from standard response JSON
 * so pure data is forwarded to application. All the routes are well described in cati server documentation.
 * For more details please take a look there.
 */
@Injectable({
  providedIn: 'root'
})
export class CatiService {

  public catiurl: string;
  public bccpurl: string;
  public lburl: string;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private authorization: AuthorizationService,
  ) {
    this.config.changed.subscribe(
      configs => {
        this.catiurl = `${this.config.get('cati.api.url')}/cati`;
        this.bccpurl = `${this.config.get('cati.api.url')}/bccp`;
        this.lburl = `${this.config.get('cati.api.url')}/leaderboard`;
      }
    );
  }

  get headers() {
    if (this.authorization.state.value) {
      return {
        'headers': {
          'x-access-token': this.authorization.token
        }
      };
    } else {
      return null;
    }
  }

  public async getResearches(): Promise<ResearchModel[]> {
    return this.http.get<GetResearchesResponse>(
      `${this.catiurl}/researches/${this.authorization.oid}/${this.authorization.tester ? '1' : '0'}`,
      this.headers
    ).pipe(
      map(
        response => response.data.map(
          item => new ResearchModel(item)
        )
      )
    ).toPromise();
  }

  public async getDefferedRespondents(rid: number, filter: RespondentsFilter): Promise<RespondentModel[]> {
    let headers = this.headers;

    headers.headers['x-filter'] = JSON.stringify(
      filter
    );

    return this.http.get<GetDeferredResponse>(
      `${this.catiurl}/defferedrespondents/${rid}`,
      headers
    ).pipe(
      map(
        response => response.data.map(
          item => new RespondentModel(item)
        )
      )
    ).toPromise();
  }

  public async startSession(rid, testmode, oldSessionId, clid, state): Promise<any> {
    const body = {
      testmode: testmode,
      oldSessionId: oldSessionId,
      clid: clid,
      state: state
    };
    return this.http.post<AnyResponse>(
      `${this.catiurl}/session/${rid}/${this.authorization.oid}`,
      body,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async updateSession(sessionid, clid, state): Promise<any> {
    return this.http.patch<AnyResponse>(
      `${this.catiurl}/session/${sessionid}/${clid}/${state}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async pauseSession(sessionid): Promise<any> {
    return this.http.post<AnyResponse>(
      this.catiurl + '/pause/' + sessionid,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async updatePause(pauseid): Promise<number> {
    return this.http.patch<AnyResponse>(
      this.catiurl + '/pause/' + pauseid,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async startConsultation(): Promise<any> {
    return this.http.post<AnyResponse>(
      `${this.catiurl}/consultation/${this.authorization.oid}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async updateConsultation(consultationId): Promise<any> {
    return this.http.patch<AnyResponse>(
      `${this.catiurl}/consultation/${consultationId}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getTodaySessionsTotal(): Promise<number> {
    return this.http.get<NumberResponse>(
      `${this.catiurl}/sessions/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getTodayTestmodeTotal(): Promise<number> {
    return this.http.get<NumberResponse>(
      `${this.catiurl}/testmode/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }


  public async getTodayPausesTotal(): Promise<number> {
    return this.http.get<NumberResponse>(
      `${this.catiurl}/pauses/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getTodayConsultationsTotal(): Promise<number> {
    return this.http.get<NumberResponse>(
      `${this.catiurl}/consultations/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getTodayInactivityTotal(): Promise<number> {
    return this.http.get<NumberResponse>(
      `${this.catiurl}/inactivity/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async startInterview(rid: number, respid: number, testmode: boolean): Promise<number> {
    return this.http.post<NumberResponse>(
      this.catiurl + `/interview/${rid}/${respid}/${this.authorization.oid}/${testmode}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async endInterview(rid, respid, intid, oid, params): Promise<number> {
    return this.http.patch<NumberResponse>(
      this.catiurl + `/interview/${rid}/${respid}/${intid}/${oid}`,
      params,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async tossRespondent(rid, oid, testmode): Promise<number> {
    return this.http.get<NumberResponse>(
      this.catiurl + `/toss/${rid}/${oid}/${testmode}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getMessages(): Promise<MessageModel[]> {
    return this.http.get<GetMessagesResponse>(
      `${this.catiurl}/messages/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data.map(
          item => new MessageModel(item)
        )
      )
    ).toPromise();
  }

  public async getArchivedMessages(): Promise<MessageModel[]> {
    return this.http.get<GetMessagesResponse>(
      `${this.catiurl}/archived-messages/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data.map(
          item => new MessageModel(item)
        )
      )
    ).toPromise();
  }

  public async archiveMessage(messageid): Promise<any> {
    return this.http.patch<AnyResponse>(
      this.catiurl + '/message/' + messageid,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getCodelist(rid, listname): Promise<CodelistModel> {
    return this.http.get<GetCodelistResponse>(
      this.catiurl + `/codelist/${rid}/${listname}`,
      this.headers
    ).pipe(
      map(
        (response: any) => {
          const obj = {
            listname: listname,
            items: response.data.map(
              item => new ClitemModel(item)
            )
          };

          return new CodelistModel(obj);
        }
      )
    ).toPromise();
  }

  public async getQuestProgress(rid, respid, intid): Promise<ProgressModel> {
    return this.http.get<GetProgressResponse>(
      this.catiurl + `/progress/${rid}/${respid}/${intid}`,
      this.headers
    ).pipe(
      map(
        response => {
          const progress = response.data;
          if ((progress) && (progress.callres < 100)) progress.callres += 100;
          return progress;
        }
      )
    ).toPromise();
  }

  public async getRespondent(rid, respid): Promise<RespondentModel> {
    return this.http.get<GetRespondentResponse>(
      this.catiurl + `/respondent/${rid}/${respid}`,
      this.headers
    ).pipe(
      map(
        response => new RespondentModel(response.data)
      )
    ).toPromise();
  }

  public async startInactivity(rid): Promise<number> {
    return this.http.post<NumberResponse>(
      `${this.catiurl}/inactivity/${rid}/${this.authorization.oid}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async updateInactivity(inactivityid): Promise<number> {
    return this.http.patch<NumberResponse>(
      `${this.catiurl}/inactivity/${inactivityid}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getNews(): Promise<NewsModel[]> {
    return this.http.get<GetNewsResponse>(
      this.catiurl + `/news`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getBlacklisted(phone): Promise<boolean> {
    return this.http.get<BooleanResponse>(
      this.catiurl + `/blacklist/${phone}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async addToBlacklist(rid, respid, intid, phone, oid, note): Promise<number> {
    const body = {
      rid: rid,
      respid: respid,
      intid: intid,
      phone: phone,
      oid: oid,
      note: note
    };

    return this.http.post<NumberResponse>(
      this.catiurl + `/blacklist/${phone}`,
      body,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async dial(source, destination, accountcode): Promise<number> {
    const body = {
      accountCode: accountcode
    };

    return this.http.post<NumberResponse>(
      this.bccpurl + `/extension/${source}/${destination}`,
      body,
      this.headers
    ).pipe(
        map(
          response => response.data
        )
      ).toPromise();
  }

  public async logCall(rid, respid, intid, oid, phone): Promise<number> {
    const body = {
      rid: rid,
      respid: respid,
      intid: intid,
      oid: oid,
      phone: phone
    };

    return this.http.post<AnyResponse>(
      this.catiurl + `/logcall/${phone}`,
      body,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async logLastcall(rid, respid): Promise<number> {
    return this.http.post<NumberResponse>(
      `${this.catiurl}/lastcall/${rid}/${respid}`,
      null,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async hangup(source, destination): Promise<any> {
    return this.http.delete<AnyResponse>(
      this.bccpurl + `/extension/${source}/${destination}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getShifts(): Promise<ShiftModel[]> {
    return this.http.get<GetShiftsResponse>(
      `${this.catiurl}/shifts/${this.authorization.oid}`,
      this.headers
    ).pipe(
      map(
        response => response.data.map(
          item => new ShiftModel(item)
        )
      )
    ).toPromise();
  }

  public async getSyslist(table: string): Promise<SyslistModel> {
    return this.http.get<GetSyslistResponse>(
      this.catiurl + `/syslist/${table}`,
      this.headers
    ).pipe(
      map(
        (response: any) => {
          const obj = {
            table: table,
            items: response.data.map(
              item => new SyslistItemModel(item)
            )
          };

          return new SyslistModel(obj);
        }
      )
    ).toPromise();
  }

  public async addShiftRequest(shift: ShiftModel): Promise<void> {
    return this.http.post<AnyResponse>(
      `${this.catiurl}/shift/${this.authorization.oid}`,
      shift,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getLeaderboard(): Promise<LeaderboardModel> {
    const agency = this.authorization.user.getValue().agency;
    return this.http.get<GetLeaderboardResponse>(
      `${this.lburl}/workweek/${agency}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getResearchStatistics(rid: number): Promise<ResearchStatisticsModel> {
    return this.http.get<GetResearchStatisticsResponse>(
      `${this.lburl}/research/${rid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getUserStatistics(rid: number, date?: string): Promise<UserStatisticsModel> {
    return this.http.get<GetUserStatisticsResponse>(
      `${this.lburl}/user/${rid}/${this.authorization.oid}/${date}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getCallsHistory(rid: number, respid: string): Promise<CallHistoryModel[]> {
    return this.http.get<GetCallHistoryModelResponse>(
      `${this.catiurl}/calls/${rid}/${respid}`,
      this.headers
    ).pipe(
      map(
          response => response.data.map(
              item => new CallHistoryModel(item)
          )
      )
    ).toPromise();
  }

  public async getUserScore(year: number, month: number): Promise<UserScoreModel[]> {
    return this.http.get<GetScoreResponse>(
        `${this.catiurl}/score/${this.authorization.oid}/${year}/${month}`,
        this.headers
    ).pipe(
        map(
            response => response.data.map(
                item => new UserScoreModel(item)
            )
        )
    ).toPromise();
  }

  public async getUserDeclaration(): Promise<UserDeclarationModel[]> {
    return this.http.get<GetDeclarationResponse>(
        `${this.catiurl}/declaration/${this.authorization.oid}`,
        this.headers
    ).pipe(
        map(
            response => response.data.map(
                item => new UserDeclarationModel(item)
            )
        )
    ).toPromise();
  }

  public async getUserHourRate(): Promise<number> {
    return this.http.get<GetRateResponse>(
        `${this.catiurl}/hour-rate/${this.authorization.oid}`,
        this.headers
    ).pipe(
        map(
            response => response.data
        )
    ).toPromise();
  }

  public async getDetailContent(rid: number, intid: number): Promise<string> {
    return this.http.get<GetDetailContent>(
        `${this.catiurl}/detail-content/${this.authorization.oid}/${rid}/${intid}`,
        this.headers
    ).pipe(
        map(
            response => response.data
        )
    ).toPromise();
  }

  public async getIntro(rid: number, intid: number): Promise<string> {
    return this.http.get<GetIntro>(
      `${this.catiurl}/intro/${this.authorization.oid}/${rid}/${intid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getCallsCountToday(phone): Promise<number> {
    return this.http.get<GetCallsCountResponse>(
      this.catiurl + `/logcall/${phone}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async getDefferedCount(rid: number, respid: number): Promise<number> {
    return this.http.get<GetDefferedCount>(
      this.catiurl + `/defferedcount/${rid}/${respid}`,
      this.headers
    ).pipe(
      map(
        response => response.data
      )
    ).toPromise();
  }

  public async lockCall(rid: number, respid: number): Promise<boolean> {
    return this.http.post<BooleanResponse>(
      `${this.catiurl}/lockcall`,
      {rid, respid},
      this.headers
    ).pipe(map(
      response => response.data
    )).toPromise();
  }

  public async addContact(rid, phone, testmode): Promise<number> {
    return this.http
      .post<NumberResponse>(
        `${this.catiurl}/addcontact/${rid}/${phone}/${testmode}`,
        null,
        this.headers
      )
      .pipe(map(response => response.data))
      .toPromise();
  }

}

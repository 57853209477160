<app-page-wrapper>
  <div wrapper-title>Statistiky výzkumu
  </div>

  <div wrapper-actions>
    Celkové statistiky zvoleného výzkumu <i>(aktualizováno před {{data?.ttl}}s).</i>
  </div>

  <div wrapper-content fxLayout="column" fxLayoutAlign="space-evenly none" *ngIf="!refreshing">
    <div fxFlex="shrink">
      <h3>{{data?.research.rid}} - {{data?.research.rname}}</h3>
      <table>
        <tr>
          <td>Průměrná délka rozhovoru</td>
          <td>{{data?.research.statistics.len_average_complete}} sekund</td>
        </tr>
        <tr>
          <td>Kompletních rozhovorů</td>
          <td>{{data?.research.statistics.cnt_interviews_complete}}</td>
        </tr>
        <tr>
          <td>Použitých čísel</td>
          <td>{{data?.research.statistics.cnt_interviews_dialed}}</td>
        </tr>
      </table>
    </div>
    <div fxFlex fxLayout.xl="row" fxLayout.lt-xl="column" fxLayoutAlign="space-evenly none">
      <div fxFlex="50">
        <h3>Zastoupení výsledků rozhovorů</h3>
        <canvas baseChart
                [data]="chartCallresDataset"
                [labels]="chartCallresLabels"
                [chartType]="chartCallresType"
                [options]="chartCallresOptions"
                [plugins]="chartCallresPlugins"
                [legend]="chartCallresLegend">
        </canvas>
      </div>
      <div fxFlex="50">
        <h3>Hodiny s nejvyšší úspěšností (% kompletních rozhovorů z celkového počtu rozhovorů)</h3>
        <canvas baseChart
                [datasets]="chartBesthoursDataset"
                [labels]="chartBesthoursLabels"
                [chartType]="chartBesthoursType"
                [options]="chartBesthoursOptions"
                [plugins]="chartBesthoursPlugins"
                [legend]="chartBesthoursLegend">
        </canvas>
      </div>
    </div>
  </div>
</app-page-wrapper>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ResearchModel } from '../models/research.model';
import { StorageService } from './storage.service';
import {CatiService} from './cati.service';
import {AuthorizationService} from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchesService {

  public list: BehaviorSubject<ResearchModel[]> = new BehaviorSubject<ResearchModel[]>([]);
  public research: BehaviorSubject<ResearchModel> = new BehaviorSubject<ResearchModel>(null);

  constructor(
    private authorization: AuthorizationService,
    private storage: StorageService,
    private cati: CatiService
  ) {
    this.authorization.user.subscribe(
      user => {
        if (this.authorization.authorized) {
          this.refresh();
        }
      }
    );

    this.research.subscribe(
      research => {
        if (this.rid && this.rid > 0) {
          localStorage.setItem('rid', this.rid.toString());
        }
      }
    );

    this.list.subscribe(
      list => this.rid = +localStorage.getItem('rid')
    );
  }

  public get rid(): number {
    return (this.research.value) ? this.research.value.rid : -1;
  }

  public set rid(rid: number) {
    const research = this.get(rid);
    this.research.next(research);
  }

  public async refresh(): Promise<ResearchModel[]> {
    const researches = await this.cati.getResearches();
    this.list.next(researches);
    return researches;
  }

  public get(rid: number): ResearchModel {
    return this.list.value.find(
        (item) => {
            return (item.rid === rid);
        }
    );
  }

  public copy(): ResearchModel[] {
    return this.list.value.slice();
  }
}

import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ShiftModel} from '../../../../models/shift.model';
import {CatiService} from '../../../../services/cati.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SyslistModel} from '../../../../models/syslist.model';
import {CodelistsService} from '../../../../services/codelists.service';
import {ShiftsService} from '../../../../services/shifts.service';
import {Subscription} from 'rxjs';
import {MessagesService} from '../../../../services/messages.service';

@Component({
  selector: 'app-shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.css']
})
export class ShiftsComponent implements OnInit, OnDestroy {

  @HostBinding('class.flex') flex = true;
  displayedColumns: string[] = ['sfrom', 'sto', 'change_date', 'locality', 'avail', 'approved', 'approvedby'];
  dataSource: ShiftModel[];
  focusedShift: ShiftModel;
  locality: SyslistModel;
  shiftsSubscription: Subscription;

  constructor(
    private cati: CatiService,
    private router: Router,
    private route: ActivatedRoute,
    private shifts: ShiftsService,
    private codelists: CodelistsService,
    private messages: MessagesService
  ) { }

  ngOnInit() {
      this.shifts.refresh();

      this.shiftsSubscription = this.shifts.shifts.subscribe(
        shifts => this.dataSource = shifts
      );
  }

  ngOnDestroy(): void {
    this.shiftsSubscription.unsubscribe();
  }

  async refresh() {
    this.locality = await this.codelists.syslist('c13');
  }

  public async deleteShift(shift: ShiftModel) {
    try {
      shift.avail = false;
      await this.shifts.addShiftRequest(shift);
      await this.refresh();
      this.messages.show('Požadavek směny byl odstraněn.', 'info');
    } catch (e) {
      this.messages.show(`Chyba při vkládání požadavku... (${e.message})`, 'error');
    }
  }

  public editShift(shift: ShiftModel) {
    this.router.navigate(['shift'], { queryParams: { id: shift.sid } });
  }

  public selectShift(shift: ShiftModel) {
    this.focusedShift = shift;
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigModel } from '../../../../../models/config.model';
import { MessagesService } from 'src/app/services/messages.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-config-edit',
  templateUrl: './config-edit.component.html',
  styleUrls: ['./config-edit.component.css']
})
export class ConfigEditComponent implements OnInit {

  public configGroup: FormGroup = null;
  public config: ConfigModel;
  public errorMesssage = 'Tato položka je povinná!';

  constructor(
    private storage: StorageService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private messages: MessagesService,
  ) {
    this.configGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.activeRoute.data.subscribe(
      (data) => this.loadConfig(data.config)
    );
  }

  loadConfig(config: ConfigModel) {
    this.config = config;

    this.configGroup = this.formBuilder.group({
      name: [this.config.name, Validators.required],
      value: [this.config.value],
      description: [this.config.description, Validators.required],
      changed: [this.config.changed]
    });
  }

  async submitConfig() {
    try {
      Object.assign(this.config, this.configGroup.value);
      await this.storage.configs.put(this.config);
      this.messages.show('Konfigurace byla uložena');
      this.router.navigate(['configs']);
    } catch (e) {
      this.messages.show('Nepodařilo se uložit konfiguraci');
    }
  }

}

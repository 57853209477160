import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ResearchStatisticsModel} from '../../../../../models/research-statistics.model';
import {Subscription, timer} from 'rxjs';
import {CatiService} from '../../../../../services/cati.service';
import {ResearchesService} from '../../../../../services/researches.service';
import {ResearchModel} from '../../../../../models/research.model';
import {ChartColor, ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';

@Component({
  selector: 'app-research-statistics',
  templateUrl: './research-statistics.component.html',
  styleUrls: ['./research-statistics.component.css']
})
export class ResearchStatisticsComponent implements OnInit, OnDestroy {

  @HostBinding('class.flex') flex = true;
  public data: ResearchStatisticsModel;
  public research: ResearchModel;
  public refreshing: boolean = false;

  public chartCallresDataset: SingleDataSet;
  public chartCallresColors: ChartColor;
  public chartCallresLabels: Label[];
  public chartCallresType: ChartType = 'pie';
  public chartCallresLegend: boolean = true;
  public chartCallresPlugins = [];
  public chartCallresOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
      labels: {
        fontSize: 10
      }
    }
  };

  public chartBesthoursDataset: ChartDataSets[];
  public chartBesthoursLabels: Label[];
  public chartBesthoursType: ChartType = 'bar';
  public chartBesthoursLegend: boolean = true;
  public chartBesthoursPlugins = [];
  public chartBesthoursOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
      labels: {
        fontSize: 10
      }
    },
    scales: {
      yAxes: [{
        id: 'percents',
        type: 'linear',
        position: 'left',
      }, {
        id: 'count',
        type: 'linear',
        position: 'right'
      }]
    }
  };


  private timer: Subscription;
  private researchSubscription: Subscription;

  constructor(
    private cati: CatiService,
    private researches: ResearchesService
  ) {
    this.researchSubscription = this.researches.research.subscribe(
      research => {
        this.research = research;
        this.refresh();
      }
    );
  }

  ngOnInit() {
    this.timer = timer(0, 60000).subscribe(
      value => {
        this.refresh();
      }
    );
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
    this.researchSubscription.unsubscribe();
  }

  public async refresh() {
    this.refreshing = true;
    try {
      if (this.research) {
        this.data = await this.cati.getResearchStatistics(
          this.research.rid
        );
      }

      if (this.data) {
        this.data.research.callres.sort((a, b) => b.cnt - a.cnt );
        this.chartCallresDataset = this.data.research.callres.map(item => item.cnt);
        this.chartCallresLabels = this.data.research.callres.map(item => item.description);

        this.chartBesthoursDataset = [
          {
            data: this.data.research.statistics.best_hours.map(item => item.percents),
            yAxisID: 'percents',
            label: '% kompletních'
          },
          {
            data: this.data.research.statistics.best_hours.map(item => item.cnt),
            yAxisID: 'count',
            label: 'celkový počet'
          }
        ];
        this.chartBesthoursLabels = this.data.research.statistics.best_hours.map(item => `${item.hr} hodin`);
      }

    } catch (e) {
    }
    this.refreshing = false;
  }
}


<app-page-wrapper>
  <div wrapper-title>Plánovač směn</div>

  <div wrapper-actions>
    <div class="button-row">
      <button class="flex-grow" mat-stroked-button color="primary" [routerLink]="['/shift']" [queryParams]="{id:'new'}">
        <mat-icon>add</mat-icon> Nový požadavek
      </button>
      <button class="flex-grow" mat-stroked-button color="accent" (click)="editShift(focusedShift)" [disabled]="!focusedShift">
        <mat-icon>edit</mat-icon> Upravit požadavek
      </button>
      <button class="flex-grow" mat-stroked-button color="warn" (click)="deleteShift(focusedShift)" [disabled]="!focusedShift">
        <mat-icon>delete</mat-icon> Smazat požadavek
      </button>
    </div>
  </div>

  <div wrapper-content fxLayout="column">
    <table mat-table [dataSource]="dataSource" fxFlex>

      <ng-container matColumnDef="sfrom">
        <th mat-header-cell *matHeaderCellDef> Od </th>
        <td mat-cell *matCellDef="let element"> {{element.sfrom | datetime}} </td>
      </ng-container>

      <ng-container matColumnDef="sto">
        <th mat-header-cell *matHeaderCellDef> Do </th>
        <td mat-cell *matCellDef="let element"> {{element.sto | datetime}} </td>
      </ng-container>

      <ng-container matColumnDef="avail">
        <th mat-header-cell *matHeaderCellDef> Dostupnost </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon color="{{element.approved ? 'primary': element.avail ? 'accent' : 'warn'}}">{{element.avail ? 'event_available' : 'event_busy'}}</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="change_date">
        <th mat-header-cell *matHeaderCellDef> Čas poslední změny </th>
        <td mat-cell *matCellDef="let element"> {{element.change_date | datetime}} </td>
      </ng-container>

      <ng-container matColumnDef="locality">
        <th mat-header-cell *matHeaderCellDef> Lokalita </th>
        <td mat-cell *matCellDef="let element"> {{element.locality | syslist: 'c13' | async }} </td>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef> Schváleno </th>
        <td mat-cell *matCellDef="let element"> <mat-icon *ngIf="element.approved" color="primary">done</mat-icon> </td>
      </ng-container>

      <ng-container matColumnDef="approvedby">
        <th mat-header-cell *matHeaderCellDef> Schvalující </th>
        <td mat-cell *matCellDef="let element"> {{element.approvedby}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectShift(row)" [ngClass]="{'highlight': focusedShift === row}"></tr>
    </table>

    <div fxLayout="row" fxLayoutAlign="center">
      <ng-container *ngIf="!dataSource || dataSource.length === 0">
        <p class="no-records">Nebyly nalezeny žádné naplánované směny.</p>
      </ng-container>
    </div>

  </div>
</app-page-wrapper>

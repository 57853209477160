<mat-accordion>
  <mat-expansion-panel #panelFilter hideToggle [ngClass]="{noColor: !filterActive, filteredColor: filterActive}">
    <mat-expansion-panel-header>
      <mat-panel-title fxLayoutAlign="start center">
        <mat-icon>filter_list</mat-icon>&nbsp;&nbsp;Filtrace a řazení
      </mat-panel-title>
      <mat-panel-description fxLayoutAlign="start center">
        <span *ngIf="!filterActive && !panelFilter.expanded">Klikněte pro nastavení zobrazení jen některých položek...</span>
        <span *ngIf="!filterActive && panelFilter.expanded">Klikněte pro zabalení...</span>
        <span *ngIf="filterActive">Pozor, filtr odložených hovorů je aktivní!</span>
      </mat-panel-description>
      <button mat-button matTooltip="Odstranit všechny filtry" [disabled]="!filterActive" (click)="delFilter(); panelFilter.open()"><mat-icon>delete</mat-icon></button>
    </mat-expansion-panel-header>

    <div fxFlex fxLayout="row">
      <div fxFlex fxLayout="column">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <mat-checkbox
              [(ngModel)]="filter.date.active"
              (ngModelChange)="refresh()"
          >Pouze odložené na konkrétní datum.</mat-checkbox>
          <mat-form-field>
            <input matInput
                   [matDatepicker]="dtpToCall" placeholder="Vyberte datum..."
                   [(ngModel)]="filter.date.value"
                   [disabled]="!filter.date.active"
                   (ngModelChange)="refresh()"
            >
            <mat-datepicker-toggle matSuffix [for]="dtpToCall"></mat-datepicker-toggle>
            <mat-datepicker #dtpToCall></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="row">
          <mat-checkbox
              [(ngModel)]="filter.oid.active"
              (ngModelChange)="refresh()"
          >Pouze mé odložené hovory.</mat-checkbox>
        </div>
      </div>

      <div fxFlex fxLayout="column">
        <mat-form-field>
          <mat-label>Řadit podle:</mat-label>
          <mat-select [(ngModel)]="filter.sort.field" (ngModelChange)="resort()">
            <mat-option *ngFor="let field of panelFields" [value]="field.value">
              {{field.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Směr řazení:</mat-label>
          <mat-select [(ngModel)]="filter.sort.dir" (ngModelChange)="resort()" [disabled]="!filter.sort.active">
            <mat-option [value]="'ASC'">Vzestupně</mat-option>
            <mat-option [value]="'DESC'">Sestupně</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>

<table mat-table [dataSource]="dataSource" fxFlex>

  <ng-container matColumnDef="respid">
    <th mat-header-cell *matHeaderCellDef [style.min-width]="'65px'"> RESPID</th>
    <td mat-cell *matCellDef="let element"> {{element.respid}} </td>
  </ng-container>

  <ng-container matColumnDef="oname">
    <th mat-header-cell *matHeaderCellDef [style.min-width]="'65px'"> Operátor</th>
    <td mat-cell *matCellDef="let element"> {{element.oname}} </td>
  </ng-container>

  <ng-container matColumnDef="tocall">
    <th mat-header-cell *matHeaderCellDef [style.min-width]="'140px'"> Dohodnutý čas volání</th>
    <td mat-cell *matCellDef="let element"> {{element.tocall | datetime}} </td>
  </ng-container>

  <ng-container matColumnDef="tocall_note">
    <th mat-header-cell *matHeaderCellDef> Poznámka</th>
    <td mat-cell *matCellDef="let element"> {{element.tocall_note}} </td>
  </ng-container>

  <ng-container matColumnDef="calledago">
    <th mat-header-cell *matHeaderCellDef [style.min-width]="'100px'"> Voláno před</th>
    <td mat-cell *matCellDef="let element"> {{element.lastcall | timeago | async}} </td>
  </ng-container>

  <ng-container matColumnDef="sex">
    <th mat-header-cell *matHeaderCellDef [style.min-width]="'55px'"> Pohlaví</th>
    <td mat-cell *matCellDef="let element"> {{element.sex | codelist: 'sex' | async}} </td>
  </ng-container>

  <ng-container matColumnDef="age">
    <th mat-header-cell *matHeaderCellDef [style.min-width]="'30px'"> Věk</th>
    <td mat-cell *matCellDef="let element"> {{element.age}} </td>
  </ng-container>

  <ng-container matColumnDef="edu">
    <th mat-header-cell *matHeaderCellDef> Vzdělání</th>
    <td mat-cell *matCellDef="let element"> {{element.edu | codelist: 'edu' | async}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [ngClass]="{'highlight': isOver(row.tocall), 'twice-highlight': focused === row}"></tr>
</table>

<ng-container *ngIf="!dataSource || dataSource.length === 0">
  <p *ngIf="!filterActive" class="no-records">Nebyli nalezeni žádní respondenti.</p>
  <p *ngIf="filterActive" class="no-records">Pro tento filtr nebyli nenalezeni žádní respondenti.</p>
</ng-container>

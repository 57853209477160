import { Injectable } from '@angular/core';
import { ConfigModel } from '../models/config.model';
import { ConfigsKey, StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';

/**
 * Service taking care of all configs. Configs are stored inside indexed db of the browser. Configs are loaded just
 * after the application init. If there is no configs in database, it tries to initialize default database
 * from json in assets folder.
 * There is also default database recipe versioning system watching db.configs.version field value, so there is some
 * support to change default configs values remotely.
 * Next feature is ability to have different versions of configs recipe for development and production.
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configs: Array<ConfigModel> = [];
  public changed: Subject<Array<ConfigModel>> = new Subject<Array<ConfigModel>>();

  /**
   * Constructor is just managing database changes to reread all the config values.
   * @param storage
   * @param http
   */
  constructor(
    private storage: StorageService,
    private http: HttpClient
  ) {
    this.storage.changed.subscribe(
      async (change) => {
        if (change.table === 'configs') {
          this.read();
        }
      }
    );

    this.read();
  }

  /**
   * Method for config reading for database.
   */
  public async read() {
    const configsStored = await this.storage.configs.toArray();
    const configsAssets = await this.readConfigsFromAssets();

    const versionStored = configsStored.find(
      (item: ConfigModel) => item.name === 'db.config.version'
    );

    const versionAssets = configsAssets.find(
      (item: ConfigModel) => item.name === 'db.config.version'
    );

    if ((!versionStored) || (versionAssets.value > versionStored.value)) {
      await this.initialize();
    }

    this.configs = await this.storage.configs.toArray();
    this.changed.next(this.configs);
  }

  /**
   * Method for getting the right name of configs recipe according to environment settings.
   */
  private get filename(): string {
    if (environment.production) {
      return 'assets/configs.prod.json';
    } else {
      return 'assets/configs.dev.json';
    }
  }

  /**
   * Method for getting all the config fields from recipe.
   */
  public async readConfigsFromAssets(): Promise<Array<ConfigModel>> {
    return this.http.get<Array<ConfigModel>>(this.filename).toPromise();
  }

  /**
   * Initialization method storing all the config fields form configuration recipe to indexed db.
   */
  public async initialize() {
    const configs = await this.readConfigsFromAssets();

    configs.forEach(
      (config: ConfigModel) => {
        this.storage.configs.put(config);
      }
    );
  }

  /**
   * Method getting a config field.
   * @param name string Name of the config field.
   */
  public get(name: string): any {
    const config = this.configs.find(
      (item: ConfigModel) => item.name === name
    );

    if (config) {
      return config.value;
    }
  }

  /**
   * Method setting a config field.
   * @param name string Name of the field.
   * @param value any Value to be set.
   * @param description Optional human readable description of the config field.
   */
  public set(name: string, value: any, description?: string): Promise<ConfigsKey> {
    const config = new ConfigModel(name, value, description, new Date());
    return this.storage.configs.put(config);
  }
}

import {DeferValues, SocioValues} from './interview.model';

export class RespondentModel {

  public respid: number;
  public tocall: Date;
  public tocall_note: string;
  public tocall_exacttime: number;
  public oname: string;
  public lastcall: Date;
  public lastcall_number: string;
  public sex: number;
  public age: number;
  public edu: number;
  public distr: number;
  public phone1: string;
  public phone2: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  public get defer(): DeferValues {
    return {
      tocall: this.tocall ? new Date(this.tocall) : null,
      tocall_exacttime: this.tocall_exacttime,
      note: this.tocall_note,
      phone: this.lastcall_number ? this.lastcall_number : this.phone1
    };
  }

  public get socio(): SocioValues {
    return {
      sex: this.sex,
      age: this.age,
      edu: this.edu,
      distr: this.distr
    };
  }

}

import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { UserModel } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ServerResponse } from '../shared/response.class';
import { errorCodes, infoCodes } from '../shared/cati.consts';

export interface ReservedLine {
  number: string;
  prefix: string;
  username: string;
  password: string;
  reservationdate: Date;
  permanent: boolean;
}

export interface LoginState {
  token: string;
  expiry: Date;
  line: ReservedLine;
}

class LoginResponse extends ServerResponse {
  data: LoginState;
}

class MeResponse extends ServerResponse {
  data: UserModel;
}

/**
 * Service making requests for login, logout and "me". Manages logged user and stores returned token in local storage.
 * "state" and "user" BehaviorSubjects are used across the whole application to detect login logout state.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public state: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(null);
  public user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);

  /**
   * Constructor is taking care of token reading and writing in the local storage and loading user from API.
   */
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private config: ConfigService
  ) {
    this.config.changed.subscribe(
      configs => {
        if (!this.state.value && !this.user.value) {
        this.state.next(JSON.parse(localStorage.getItem('state')));
        this.state.subscribe(
          async state => {
            localStorage.setItem('state', JSON.stringify(this.state.value));
            if (state) {
              try {
                this.user.next(
                  await this.me(state.token)
                );
              } catch (e) {
                this.state.next(null);
                this.user.next(null);
              }
            }
          }
        );
      }
      }
    );

    this.user.subscribe(
      user => {
        if (user) {
          this.router.navigate(['news']);
        } else {
          this.router.navigate(['welcome']);
        }
      }
    );
  }

  /**
   * Method constructing authorization API route url from config.
   */
  public getUrl(): string {
    return this.config.get('cati.api.url') + '/auth';
  }

  /**
   * Method simplifying logged in state checking.
   */
  public get authorized(): boolean {
    return !(this.state.value === null);
  }

  /**
   * Method performing user login. After successful login "state" property is set.
   * @param username Username usually taken from UI.
   * @param password Password usually taken from UI.
   */
  public login(username: string, password: string): Observable<LoginState> {
    const url = this.getUrl();

    return this.http.post<LoginResponse>(
      `${url}/login`,
      {
        username: username,
        password: password
      }
    ).pipe(
      map(
        (response: LoginResponse) => {
          this.state.next(response.data);
          return response.data;
        })
    );
  }

  /**
   * Method performing user logout in API. After API logout "user" and "state" property are set to null
   * so the subscribed places in application are notified.
   * @param oid Operator id. It's deprecated will be removed.
   * @param line Line. It's deprecated will be removed.
   */
  public async logout(oid: number, line: string) {
    const url = this.getUrl();
    await this.http.post<ServerResponse>(
      `${url}/logout`,
      {
        oid: oid,
        number: line
      },
      {
        'headers': {
          'x-access-token': this.token,
          'Content-Type': 'application/json'
        }
      }
    ).pipe(
      map( res => {
        this.user.next(null);
        this.state.next(null);
        localStorage.clear();
      })
    ).toPromise();
  }

  /**
   * Method getting logged user details. It needs a token since "me" route is secured.
   * @param token The token received from authorization API.
   */
  public async me(token: string): Promise<UserModel> {
    const url = this.getUrl();

    return this.http.get<MeResponse>(
      `${url}/me`,
      {
        'headers': {
          'x-access-token': token
        }
      }
    ).pipe(
      map(
        response => new UserModel(response.data)
      )
    ).toPromise();
  }

  public async changePassword(password: string, newPassword: string ): Promise<string> {
    const url = this.getUrl();
    const messageCodes =  Object.assign(infoCodes, errorCodes);
    return this.http.post<ServerResponse>(
        `${url}/change-password`,
        {
          password: password,
          newPassword: newPassword,
          oid: this.oid
        }
    ).pipe(
        map(
            response => messageCodes[response.id]
        )
    ).toPromise();
  }

  public get token(): string {
    return this.state.value ? this.state.value.token : null;
  }

  public get oid(): number {
    return this.user.value ? this.user.value.oid : null;
  }

  public get tester(): boolean {
    return this.user.value ? this.user.value.tester : null;
  }

  public get username(): string {
    return this.user.value ? this.user.value.username : null;
  }

  public get line(): string {
    return this.state.value && this.state.value.line ? this.state.value.line.number : null;
  }
}

export interface MessageInterface {
  content: string;
  type: string;
}

export class MessageModel {

  public messageid: number;
  public targetwin: string;
  public expiration: Date;
  public content: string;
  public senderoid: number;
  public insertdate: Date;
  public firstname: string;
  public surname: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.expiration) this.expiration = new Date(this.expiration);
    if (this.insertdate) this.insertdate = new Date(this.insertdate);
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { InterviewService } from '../../../../../services/interview.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-detailwindow',
  templateUrl: './detailwindow.component.html',
  styleUrls: ['./detailwindow.component.css']
})
export class DetailwindowComponent implements OnInit, OnDestroy {

  public content: string;
  private intid: number;
  private rid: number;
  private progressSubscription: Subscription;

  constructor(
      private interviews: InterviewService
  ) { }

  ngOnInit() {
    this.progressSubscription = this.interviews.progress.subscribe(
        progress => this.loadContent()
    );
  }

  ngOnDestroy(): void {
    this.progressSubscription.unsubscribe();
  }

  public async loadContent() {
    this.content = await this.interviews.getDetailWindowContent();
  }

}

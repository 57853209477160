import { Injectable } from '@angular/core';
import { ProgressModel } from '../models/progress.model';
import { BehaviorSubject } from 'rxjs';
import { ResearchModel } from '../models/research.model';
import { RespondentModel } from '../models/respondent.model';
import { InterviewModel } from '../models/interview.model';
import { CatiService } from './cati.service';
import { AuthorizationService } from './authorization.service';
import { SessionsService } from './sessions.service';
import { MatDialog } from '@angular/material/dialog';
import { DeferDialogComponent, DeferDialogData } from '../body/content/view/survey/defer-dialog/defer-dialog.component';
import { SipService } from './sip.service';
import { NoteDialogComponent, NoteDialogData } from '../body/content/view/survey/note-dialog/note-dialog.component';
import {PhoneDialogComponent, PhoneDialogData} from '../body/content/view/survey/phone-dialog/phone-dialog.component';
import {BlacklistDialogComponent, BlacklistDialogData} from '../body/content/view/survey/blacklist-dialog/blacklist-dialog.component';
import * as _ from 'lodash';
import {ConfigService} from './config.service';
import { StorageService } from './storage.service';
import {CallModel} from '../models/call.model';
import {CATI} from '../shared/cati.functions';


/**
 * Service managing whole interview interaction with API and all important interview values
 */
@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  public callingNow: string;

  public intro: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public contacted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public alternativeDialed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public interview: BehaviorSubject<InterviewModel> = new BehaviorSubject<InterviewModel>(null);
  public progress: BehaviorSubject<ProgressModel> = new BehaviorSubject(null);

  constructor(
    private cati: CatiService,
    private sip: SipService,
    private authorization: AuthorizationService,
    private sessions: SessionsService,
    private dialog: MatDialog,
    private config: ConfigService,
    private storage: StorageService
  ) {
    this.sip.state.subscribe(
      state => {
        if (
          (state === 'ringing') && (this.interview)
        ) {
          this.sip.answer();
        }
      }
    );
  }

  public async startInterview(research: ResearchModel, respondent: RespondentModel): Promise<InterviewModel> {
    const intid = await this.cati.startInterview(research.rid, respondent.respid, this.sessions.testmode.value);

    research.initmessage = await this.loadIntro(research.initmessage, research.rid, intid);

    const interview = new InterviewModel();
    interview.research = research;
    interview.respondent = respondent;
    interview.intid = intid;
    interview.tstart = new Date();
    interview.oid = this.authorization.oid;
    interview.oname = this.authorization.username;
    interview.testmode = this.sessions.testmode.value;
    interview.questversion = this.config.get('app.version');
    interview.defer = respondent.defer;
    interview.socio = respondent.socio;

    this.intro.next(true);
    this.contacted.next(false);
    this.interview.next(interview);

    if (!this.interview.value.testmode) {
      this.dial(respondent.phone1);
      await this.cati.logCall(
        research.rid,
        respondent.respid,
        interview.intid,
        interview.oid,
        this.interview.value.phone);

      await this.storage.addCall(new CallModel(
        interview.dialtime,
        research.rid,
        respondent.respid,
        interview.intid,
        3,
        interview.oid,
        interview.method,
        interview.defer.tocall
      ));

    }

    return interview;
  }

  public progressInterview(progress: ProgressModel) {
    if ( !_.isMatch(this.progress.value, progress)) {
      this.progress.next(progress);
    }
  }

  public contact() {
    this.interview.value.connecttime = new Date();
    this.intro.next(false);
    this.contacted.next(true);
  }

  public stopInterview(interview: InterviewModel) {
    this.hangup();

    this.interview.next(null);
    this.callingNow = undefined;

    if ( !interview.testmode ) {
      this.storage.addCall(new CallModel(
        interview.dialtime,
        interview.research.rid,
        interview.respondent.respid,
        interview.intid,
        interview.body.misc.callres,
        interview.oid,
        interview.method,
        interview.defer.tocall
      ));
    }

    return this.cati.endInterview(
      interview.research.rid,
      interview.respondent.respid,
      interview.intid,
      interview.oid,
      interview.body
    );
  }

  public async dial(phone: string): Promise<any> {
    this.callingNow = phone;

    const target = `${this.authorization.state.value.line.prefix}${phone}`;
    const source = this.authorization.state.value.line.number;
    const accountCode = this.interview.value.getAccountCode(this.authorization.oid);

    this.interview.value.phone = phone;
    if (!this.interview.value.dialtime) {
      this.interview.value.dialtime = new Date();
    }

    await this.cati.logLastcall(
      this.interview.value.research.rid,
      this.interview.value.respondent.respid);

    return this.cati.dial(source, target, accountCode);
  }

  public async hangup(): Promise<any> {
    const source = this.authorization.state.value.line.number;

    this.interview.value.callovertime = new Date();

    return new Promise<any>(
      resolve => {
        this.cati.hangup(source, null);
        this.sip.hangup();
        resolve();
      }
    );
  }

  public async defer(): Promise<boolean> {
    return new Promise<boolean>(
      async resolve => {
        const interview = this.interview.value;

        const data: DeferDialogData = {
          defer: interview.defer,
          socio: interview.socio
        };

        data.defer.tocall = await this.getDefaultDefferTime(this.interview.value.research.rid, this.interview.value.respondent.respid, this.interview.value.research.deferinterval);

        const ref = this.dialog.open(DeferDialogComponent, {
          width: '50%',
          data: data
        });

        ref.afterClosed().subscribe(
          data => {
            if (data) {
              interview.defer = data.defer;
              interview.socio = data.socio;

              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      }
    );
  }

  public note(): Promise<boolean> {
    return new Promise<boolean>(
      resolve => {
        const interview = this.interview.value;

        const data: NoteDialogData = {
          note: interview.note
        };

        const ref = this.dialog.open(NoteDialogComponent, {
          width: '400px',
          height: '300px',
          data: data
        });

        ref.afterClosed().subscribe(
          data => {
            if (data) {
              interview.note = data.note;
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      }
    );
  }

  public dialAgain(): void {
    if (!this.interview.value.testmode) {
      this.hangup();
      this.dial(this.interview.value.phone);
    }
  }

  public dialAlternative(): void {
    if (!this.interview.value.testmode) {
      this.hangup();
      this.dial(this.interview.value.respondent.phone2);
      this.alternativeDialed.next(true);
    }
  }

  public dialNew(): Promise<boolean> {
    return new Promise<boolean>(
      resolve => {

        const data: PhoneDialogData = {
          phone: null
        };

        const ref = this.dialog.open(PhoneDialogComponent, {
          width: '400px',
          data: data
        });

        ref.afterClosed().subscribe(
          async data => {
            if (data) {
              this.interview.value.defer.phone = data.phone;
              this.hangup();
              if (!this.interview.value.testmode) {
                this.dial(data.phone);
                await this.cati.logCall(
                  this.interview.value.research.rid,
                  this.interview.value.respondent.respid,
                  this.interview.value.intid,
                  this.interview.value.oid,
                  this.interview.value.phone);
              }
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      }
    );
  }

  blacklist() {
    const interview = this.interview.value;
    const user = this.authorization.user.value;
    const data: BlacklistDialogData = {
      phone: this.interview.value.phone,
      note: null
    };

    const ref = this.dialog.open(BlacklistDialogComponent, {
      width: '400px',
      data: data
    });

    ref.afterClosed().subscribe(
      data => {
        if (data) {
          if (!this.interview.value.testmode) {
            this.cati.addToBlacklist(
              interview.research.rid,
              interview.respondent.respid,
              interview.intid,
              data.phone,
              user.oid,
              data.note
            );
          }
        }
      }
    );
  }

  private hasTags(content) {
    const regex = /(\<#|\{{2})[apur](\.|\_)/gi;
    const tags = content.match(regex);
    return tags && tags.length > 0;
  }

  public async getDetailWindowContent() {
    const interview = this.interview.value;
    if (this.hasTags(interview.research.display)) {
      return this.cati.getDetailContent(interview.research.rid, interview.intid);
    } else {
      return null;
    }
  }

  async loadIntro(intro, rid, intid) {
    if (this.hasTags(intro)) {
      return await this.cati.getIntro(rid, intid);
    } else {
      return intro;
    }
  }

  async getDefaultDefferTime(rid, respid, deferinterval) {
    let defferedCount = await this.cati.getDefferedCount(rid, respid);

    if (defferedCount === 0) {
      defferedCount = 1;
    }

    let tocall = new Date();

    /** increment default research deffer interval */
    tocall.setMinutes(tocall.getMinutes() + deferinterval);

    /**  allow weekend calls sometimes */
    const allowWeekend = ((defferedCount % 3) === 0);

    /** if tocall is later than 19:30, issue the call to the next working day at 18h */
    if (((60 * tocall.getHours()) + tocall.getMinutes()) > 1170) {
      /** move to next day to N hour */
      tocall = CATI.getNextDay(tocall, 18, allowWeekend);
    } else if (allowWeekend) {
      tocall = CATI.getNextDayTime(tocall, true);
    }

    return tocall;

  }
}

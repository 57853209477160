<mat-toolbar color="primary" fxFlexAlign="column" fxLayoutAlign="none center">
  <button mat-button [style.line-height]="'15px'" [matMenuTriggerFor]="profileMenu" fxFlex matTooltip="Přihlášení / odhlášení uživatele.">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="(user)">
      <mat-icon>person</mat-icon>
      <div fxLayout="column" fxLayoutAlign="start start">
        <div>{{user.firstname}} {{user.surname}}</div>
        <div><small>({{user.username}}) [{{state.line?.number}}]</small></div>
      </div>
    </div>
    <mat-icon *ngIf="!(user)">person_outline</mat-icon> <span class="menu-text" *ngIf="!(user)"> Nepřihlášen</span>
  </button>

  <mat-menu #profileMenu="matMenu">
    <button mat-menu-item (click)="actionLogin()" *ngIf="!(user)"><mat-icon>lock_open</mat-icon> Přihlášení </button>
    <button mat-menu-item (click)="actionLogout()" *ngIf="(user)"><mat-icon>lock</mat-icon> Odhlášení </button>
    <mat-divider *ngIf="(user)"></mat-divider>
    <button mat-menu-item (click)="actionAcount()" *ngIf="(user)" [routerLink]="['/profileTabs']"><mat-icon>perm_identity</mat-icon> Účet </button>
  </mat-menu>
</mat-toolbar>

<app-menu class="scroll"></app-menu>

<mat-sidenav-container [hasBackdrop]="false" fxFlex fxLayout="row">
  <mat-sidenav #drawer fxFlex="200px" [mode]="'side'" opened>
    <app-sidebar fxFlex></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content fxFlex fxLayout="column">
    <app-content fxFlex></app-content>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-spinner
  *ngIf="loading" color="primary" mode="indeterminate">
</mat-spinner>

<div class="bottom-icons" fxLayout="column" fxLayoutGap="20px">
  <div *ngIf="user">
    <button mat-fab class="extended-mat-fab" color="primary" (click)="session()" matTooltip="Podrobné informace o přihlášení, celkovém čase směny a přestávek."><small>{{sessionTime | seconds}}</small><br><small style="color: #6dba63">{{pauseTime | seconds}}</small></button>
  </div>

  <div *ngIf="user">
    <button mat-fab color="warn" (click)="phone()" matTooltip="Zobrazit telefonní panel, ve kterém lze vytáčet telefonní čísla přimo, zavěšovat hovory. Může sloužit rovněž k vytočení linky supervize." [ngClass]="(!testmode && sipStatus === 'ringing') ? 'shake' : null"><mat-icon>phone</mat-icon></button>
  </div>
</div>


import { Pipe, PipeTransform } from '@angular/core';
import {CodelistsService} from '../services/codelists.service';

@Pipe({
  name: 'syslist'
})
export class SyslistPipe implements PipeTransform {

  constructor(
    private codelists: CodelistsService
  ) {}

  async transform(value: number, args?: any) {
    const sl = await this.codelists.syslist(args);
    const sli = sl.items.find(
      item => item.value === value
    );

    return sli ? sli.description : '';
  }

}

import { Component, OnInit, HostBinding } from '@angular/core';
import { CatiService } from '../../../../../services/cati.service';
import * as moment from 'moment';
import { UserScoreModel } from '../../../../../models/user-score.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  @HostBinding('class.flex') flex = true;
  public years: number[];
  public year: number;
  public months: { value: number; label: string; }[];
  public month: { value: number; label: string; };
  public dataSource: UserScoreModel[];
  public displayedColumns: string[] = ['evaldate', 'points', 'evaluator', 'note'];

  constructor(
      private cati: CatiService
  ) { }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.month = { value: moment().month(), label: moment().format('MMMM') };
    this.initYear();
    this.initMonths();
    this.loadScore();
  }

  public async loadScore() {
    this.dataSource = await this.cati.getUserScore(this.year, this.month.value);
  }

  public async loadScoreByYear(event) {
    this.dataSource = await this.cati.getUserScore(event, this.month.value);
  }

  public async loadScoreByMonth(event) {
    this.dataSource = await this.cati.getUserScore(this.year, event);
  }

  private initYear(): void {
    const currentYear = new Date().getFullYear();
    const countYearToPass = 4;
    this.years = Array.from({ length: countYearToPass}, (_, i) => currentYear - i);
  }

  private initMonths(): void {
    let monthIndex = 0;
    this.months = moment.months().map(month => { monthIndex++; return {value: monthIndex, label: month}; });
  }
}

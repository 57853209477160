import { Component, OnInit } from '@angular/core';
import { CatiService } from '../../../../../services/cati.service';
import { UserDeclarationModel } from '../../../../../models/user-declaration';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  public declarations: UserDeclarationModel[];
  public hourRate: number;

  constructor(
      private cati: CatiService
  ) { }

  ngOnInit() {
   this.loadDeclarations();
    this.loadHourRate();
  }

  public async loadDeclarations() {
    this.declarations = await this.cati.getUserDeclaration();
  }

  public async loadHourRate() {
    this.hourRate = await this.cati.getUserHourRate();
  }

}

import {ResearchModel} from './research.model';
import {InterviewModel} from './interview.model';
import {RespondentModel} from './respondent.model';
import {CallHistoryModel} from './call-history.model';

export class SurveyModel {
  public research: ResearchModel;
  public respondent: RespondentModel;
  public interview: InterviewModel;
  public history: CallHistoryModel[];
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
      <div mat-dialog-content fxLayoutAlign="center">
          <p align="center" [innerHTML]="message"></p>
      </div>
      <div mat-dialog-actions fxLayoutAlign="center">
          <button mat-button [mat-dialog-close]="true">Ano</button>
          <button mat-button (click)="onNoClick()">Ne</button>
      </div>`,
})

export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

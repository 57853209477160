import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AuthorizationService} from '../../../../services/authorization.service';
import {errorCodes} from '../../../../shared/cati.consts';

export interface LoginDialogData {
  username: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  message: string;
  logging = false;

  constructor(
    public authorization: AuthorizationService,
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoginDialogData
  ) {

  }

  close(): void {
    this.dialogRef.close();
  }

  login(): void {
    this.logging = true;
    this.authorization.login(
      this.data.username,
      this.data.password
    ).subscribe(
      response => {
        this.close();
      },
      response => {
        const message = errorCodes[response.id];
        this.message = message ? message : response.message;
        this.logging = false;
      }
    );
  }

}

<div fxFlex fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="space-evenly">
  <mat-list fxFlex>

    <h3 mat-subheader>Časy</h3>
    <mat-list-item>
      <mat-icon mat-list-icon>access_time</mat-icon>
      <h4 mat-line>Směny</h4>
      <p mat-line>{{sessionTime | seconds}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>pause</mat-icon>
      <h4 mat-line>Přestávek <mat-icon *ngIf="pause" class="pull-right">play_circle_outline</mat-icon></h4>
      <p mat-line>{{pausesTime | seconds}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>supervisor_account</mat-icon>
      <h4 mat-line>Konzultací <mat-icon *ngIf="consultation" class="pull-right">play_circle_outline</mat-icon></h4>
      <p mat-line>{{consultationsTime | seconds}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>toys</mat-icon>
      <h4 mat-line>Zkušebního režimu  <mat-icon *ngIf="testmode" class="pull-right">play_circle_outline</mat-icon></h4>
      <p mat-line>{{testmodeTime | seconds}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>hotel</mat-icon>
      <h4 mat-line>Nečinnosti <mat-icon *ngIf="inactive" class="pull-right">play_circle_outline</mat-icon></h4>
      <p mat-line>{{inactivityTime | seconds}}</p>
    </mat-list-item>

    <mat-divider></mat-divider>

    <h3 mat-subheader>Uživatel</h3>
    <mat-list-item>
      <mat-icon mat-list-icon>face</mat-icon>
      <h4 mat-line>Jméno a příjmení</h4>
      <p mat-line>{{user.firstname}} {{user.surname}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>perm_identity</mat-icon>
      <h4 mat-line>Uživatelské jméno</h4>
      <p mat-line>{{user.username}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>fingerprint</mat-icon>
      <h4 mat-line>OID</h4>
      <p mat-line>{{user.oid}}</p>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon>alternate_email</mat-icon>
      <h4 mat-line>Email</h4>
      <p mat-line>{{user.email}}</p>
    </mat-list-item>
  </mat-list>
</div>

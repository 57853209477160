import {Component, HostBinding, OnInit} from '@angular/core';
import {LoginComponent} from '../login/login.component';
import {AuthorizationService} from '../../../../services/authorization.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  @HostBinding('class.flex') flex = true;
  constructor(
    public authorization: AuthorizationService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  public actionLogin() {
    const dialogRef = this.dialog.open(LoginComponent,
      {
        width: '600px',
        data: {}
      });
  }

  public actionLogout() {
    this.authorization.logout(this.authorization.oid, this.authorization.line);
  }

}

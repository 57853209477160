import { Injectable } from '@angular/core';
import {MenuService} from './menu.service';
import {BehaviorSubject, Subscription, timer} from 'rxjs';
import {ConfigService} from './config.service';
import {CatiService} from './cati.service';
import {ResearchesService} from './researches.service';
import {LoggingService} from './logging.service';
import {MessagesService} from './messages.service';
import {InterviewService} from './interview.service';
import {SessionsService} from './sessions.service';
import {AuthorizationService} from './authorization.service';
import {InterviewModel} from '../models/interview.model';

/**
 * Service checking whether user is active or not. If there is no activity for a specified period, it fires inactive event.
 * Inactivity is reported to API and user is notified.
 */
@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  interview: InterviewModel;

  public inactivityStart: Date = new Date;
  public inactivityTime: number;
  public inactive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private timer: Subscription;
  private inactivityId: number;

  constructor(
    private menu: MenuService,
    private config: ConfigService,
    private cati: CatiService,
    private authorization: AuthorizationService,
    private researches: ResearchesService,
    private interviews: InterviewService,
    private logging: LoggingService,
    private sessions: SessionsService,
    private messages: MessagesService,
  ) {
    this.interviews.interview.subscribe(
      interview => this.interview = interview
    );

    /** Reset inactivity trigger on any application action */
    this.menu.action.subscribe(
      action => this.update()
    );

    /** Notify API and application about recent inactivity */
    this.timer = timer(0, 5000).subscribe(
      value => {
        /** Do not report inactivity on pauses and consultations */
        /** Do not report if there is no user logged in */
        /** Do not report inactivity during interview! */
        if (this.sessions.pause.value || this.sessions.consultation.value || !this.authorization.user.value || this.interview) {
          this.inactive.next(false)
          return;
        }

        const inactive = this.check();

        if (this.inactive.value !== inactive) {
          this.inactive.next(inactive);
        }

        this.notify();
      }
    );

    /** Check user is logged in and then retrieve total inactivity time */
    this.authorization.user.subscribe(
      async user => {
        if (user) {
          this.update();
          this.inactivityTime = await this.cati.getTodayInactivityTotal();
        }
      }
    );

    /** Show message to user if inactivity detected */
    this.inactive.subscribe(
      async value => {
        if (value) {
          this.messages.show(`
            Upozornění! Byl jste neaktivní po delší dobu.
            Pokud již nepracujete odhlašte se nebo zapněte přestávku!
            `,
            'info'
          );
        }
      }
    );

  //   /** Do not report inactivity during interview progress! */
  //   this.interviews.progress.subscribe(
  //     progress => this.update()
  //   );
}

  /**
   * Updates inactivity trigger. Makes the user active again.
   */
  public update(): void {
    this.inactivityStart = new Date();
  }

  /**
   * Check wheter the user is active or not. If not, inactivity event is fired and the inactivity is reported to API.
   */
  public check(): boolean {
    return (this.seconds() > this.config.get('app.times.inactivity'));
  }

  /**
   * Returns number of seconds user is being inactive.
   */
  public seconds(): number {
    return (Date.now() - this.inactivityStart.getTime()) / 1000;
  }

  /**
   * Report inactivity to API or reset inactivity id if necessary.
   */
  public async notify() {
    try {
      if (!this.inactive.value) {
        this.inactivityId = null;
        return;
      }

      /** Notify API abou current inactivity... */
      if (!this.inactivityId) {
        this.inactivityId = await this.cati.startInactivity(this.researches.rid);
        this.logging.logInformation('inactivity.service', `started inactivity with id=${this.inactivityId}`);
      } else {
        await this.cati.updateInactivity(this.inactivityId);
      }

      /** Update current total inactivity */
      this.inactivityTime = await this.cati.getTodayInactivityTotal();

    } catch (e) {
      this.logging.logError('inactivity.service', e.message);
    }
  }


  public get todayInactivityTotal(): number {
    return this.inactivityTime;
  }
}

import { Injectable } from '@angular/core';
import { ResearchesService } from '../services/researches.service';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ResearchModel } from '../models/research.model';

@Injectable()
export class ResearchResolver implements Resolve<ResearchModel> {

    constructor(
        private researches: ResearchesService,
        private router: Router
    ) {

    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<ResearchModel> | Promise<ResearchModel> | ResearchModel {
        const rid = +route.queryParams['rid'];

        const research = this.researches.get(rid);

        if (research) {
            return research;
        } else {
            this.router.navigate(['/error']);
        }
    }
}

import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  @HostBinding('class.flex') flex = true;

  constructor() { }

  ngOnInit() {
  }

}

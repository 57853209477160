<mat-tab-group>
  <mat-tab label="Detail operátora">
      <app-detail></app-detail>
  </mat-tab>

  <mat-tab label="Hodnocení operátora">
      <app-profile></app-profile>
  </mat-tab>

  <mat-tab label="Změna hesla">
      <app-password></app-password>
  </mat-tab>
</mat-tab-group>

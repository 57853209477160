<div fxLayout="column" fxLayoutGap="10px" style="margin:16px">
  <mat-card>
    <div fxLayout="row">
      <mat-card-title>Novinky </mat-card-title>

      <div *ngIf="news.length > 1" fxFlex fxLayoutAlign="end center">
        <button mat-stroked-button color="basic" (click)="prev()">
          <mat-icon>arrow_back</mat-icon>
          Předchozí
        </button>
        <button style="margin-left: 2px" mat-stroked-button color="basic" (click)="next()">
          <mat-icon>arrow_forward</mat-icon>
          Další
        </button>
      </div>
    </div>

    <mat-card-content *ngIf="news.length > 0">
      <h3>{{ current?.firstname }} {{ current?.surname }} ({{ current?.createdate | date }})</h3>
      <p>{{ current?.msgcontent }}</p>
    </mat-card-content>
    <mat-card-content *ngIf="news.length === 0">
      <p class="no-records">Nebyly nalezeny žádné aktuální novinky.</p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Archiv zpráv </mat-card-title>

    <mat-card-content *ngIf="dataSource.data.length > 0">
      <table mat-table [dataSource]="dataSource" fxFlex>
        <ng-container matColumnDef="insertdate">
          <th mat-header-cell *matHeaderCellDef [style.min-width]="'200px'">Datum</th>
          <td mat-cell *matCellDef="let item">{{ item.insertdate | datetime }}</td>
        </ng-container>

        <ng-container matColumnDef="sender">
          <th mat-header-cell *matHeaderCellDef [style.min-width]="'200px'">Odesílatel</th>
          <td mat-cell *matCellDef="let item">{{ item.firstname }} {{ item.surname }}</td>
        </ng-container>

        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef>Zpráva</th>
          <td mat-cell *matCellDef="let item">{{ item.content }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            highlight: row.expiration > now || (now - row.insertdate < 10 * 60 * 1000 && row.expiration === null)
          }"
        ></tr>
      </table>
    </mat-card-content>
    <mat-card-content *ngIf="dataSource.data.length === 0">
      <p class="no-records">Nebyly nalezeny žádné zprávy od supervize.</p>
    </mat-card-content>
    <div [hidden]="dataSource.data.length < 6">
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
</div>

import {Dayblocks, Daytimes, NamedDictionary} from './cati.consts';
import {MatDatetimepickerFilterType} from '@mat-datetimepicker/core';

export module CATI {

  export function dayblockToHours(dayblock: Dayblocks): number {
    let h = 0;

    switch (dayblock) {
      case Dayblocks.Morning: h = Daytimes.Morning; break;
      case Dayblocks.Forenoon: h = Daytimes.Forenoon; break;
      case Dayblocks.Afternoon: h = Daytimes.Afternoon; break;
      case Dayblocks.Evening: h = Daytimes.Evening; break;
    }

    return h;
  }

  export function generateId(size?: number): string {
    return Math.random().toString(36).substring(size);
  }

  export function dictToArray(dict: NamedDictionary<string>): {value: string, description: string}[] {
    return Object.entries(dict).map(([value, description]) => ({value, description}));
  }

  export let dateTimefilterDefer = (d: Date, type: MatDatetimepickerFilterType): boolean => {
    const day = d.getDay();
    const hour = d.getHours();
    const minute =  d.getMinutes();

    if (day > 0 && day < 6) {
      switch (type) {
        case MatDatetimepickerFilterType.DATE:
          return day >= 0;
        case MatDatetimepickerFilterType.HOUR:
          return hour > 7 && hour < 23;
        case MatDatetimepickerFilterType.MINUTE:
          if (hour === 8) {
            return minute >= 30;
          } else if (hour === 22) {
            return minute === 0;
          } else {
            return minute >= 0;
          }
      }

    } else if (day === 0 || day === 6) {
      switch (type) {
        case MatDatetimepickerFilterType.DATE:
          return day >= 0;
        case MatDatetimepickerFilterType.HOUR:
          return hour > 8 && hour < 16;
        case MatDatetimepickerFilterType.MINUTE:
          if (hour === 15) {
            return minute === 0;
          } else {
            return minute >= 0;
          }
      }
    }
  };

  export let dateTimefilterShift = (d: Date, type: MatDatetimepickerFilterType): boolean => {
    const day = d.getDay();
    const hour = d.getHours();
    const minute =  d.getMinutes();

    if (day > 0 && day < 6) {
      switch (type) {
        case MatDatetimepickerFilterType.DATE:
          return day >= 0;
        case MatDatetimepickerFilterType.HOUR:
          return hour > 7 && hour < 21;
        case MatDatetimepickerFilterType.MINUTE:
          if (hour === 8) {
            return minute >= 30;
          } else if (hour === 20) {
            return minute === 0;
          } else {
            return minute >= 0;
          }
      }

    } else if (day === 0 || day === 6) {
      switch (type) {
        case MatDatetimepickerFilterType.DATE:
          return day >= 0;
        case MatDatetimepickerFilterType.HOUR:
          return hour > 8 && hour < 16;
        case MatDatetimepickerFilterType.MINUTE:
          if (hour === 15) {
            return minute === 0;
          } else {
            return minute >= 0;
          }
      }
    }
  };

  export function getNextDay(datetime, hour, allowweekends) {
    /** get day of the week to calculate correct day shifting */
    const dow = datetime.getDay();

    /** respect weekends */
    let shift = 1;
    if (!allowweekends) {
      if (dow === 5) {
        shift = 3;
      } else if (dow === 6) {
        shift = 2;
      }
    }

    /** finally increment correct number of days according to calculated shift */
    datetime.setDate(datetime.getDate() + shift);

    /** if caller provided hour, set correct hour of the day */
    if (hour > 0) {
      datetime.setHours(hour);
      datetime.setMinutes(0);
      datetime.setSeconds(0);
    }

    return datetime;
  }

  export function getNextDayTime(datetime, allowweekend) {
    /** calculate exact hour of the day */
    const hour = datetime.getHours() + (datetime.getMinutes() / 60);

    /**
     if daytime is morning, shift to afternoon
     if daytime is afternoon, shift to next day morning
     */
    let shiftHours = null;

    if (hour < 13.5) {
      shiftHours = 6;
    } else {
      shiftHours = 20;
    }

    /** inc to next calculated daytime */
    datetime.setHours(datetime.getHours() + shiftHours);

    /** respect weekends if necessary */
    const dow = datetime.getDay();

    let shiftDays = 0;
    if (!allowweekend) {
      if (dow === 6) {
        shiftDays = 2;
      } else if (dow === 0) {
        shiftDays = 1;
      }
    }

    /** finally increment correct number of days according to calculated shift */
    datetime.setDate(datetime.getDate() + shiftDays);

    return datetime;
  }

}

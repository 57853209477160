import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Callres, Color } from '../shared/cati.consts';
import { UserModel } from '../models/user.model';
import { InterviewService } from './interview.service';
import { ResearchesService } from './researches.service';
import { LoggingService } from './logging.service';
import { RespondentsService } from './respondents.service';
import { SessionsService } from './sessions.service';
import { MessagesService } from './messages.service';
import { ResearchModel } from '../models/research.model';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CatiService } from './cati.service';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { PhoneDialogComponent, PhoneDialogData } from '../body/content/view/survey/phone-dialog/phone-dialog.component';
import { ConfigService } from './config.service';


export interface MenuItemIntf {
  caption: string;
  hint: string;
  path?: string;
  name: string;
  icon: string;
  roles: string[];
  data?: any;
  color?: string;
  action?: (item: MenuItem) => void;
  canShow?: (item: MenuItem) => boolean;
}

export class MenuItem {

  caption: string;
  hint: string;
  path: string;
  name: string;
  icon: string;
  roles: string[];
  data: any;
  color: string;
  action: (item: MenuItem) => void;
  canShow: (item: MenuItem) => boolean;

  constructor(intf: MenuItemIntf) {
    Object.assign(this, intf);
  }

  public execute(menu: MenuService) {
    if (this.action) {
      this.action.call(menu, this);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  registered: MenuItem[] = [];
  items: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  action: Subject<MenuItem> = new Subject<MenuItem>();

  public research: ResearchModel;
  public user: UserModel;

  loading = false;

  constructor(
    private router: Router,
    private sessions: SessionsService,
    private researches: ResearchesService,
    private respondents: RespondentsService,
    private authorization: AuthorizationService,
    private interviews: InterviewService,
    private messages: MessagesService,
    private logging: LoggingService,
    private dialog: MatDialog,
    private cati: CatiService,
    private config: ConfigService
  ) {
    this.authorization.user.subscribe(
      (user) => {
        this.user = user;
        this.refilter();
      }
    );

    this.researches.research.subscribe(
      (research) => {
        this.research = research;
        this.refilter();
      }
    );

    this.interviews.progress.subscribe(
      progress => this.refilter()
    );


    this.sessions.pause.subscribe(
      value => this.refilter()
    );

    this.sessions.testmode.subscribe(
      value => this.refilter()
    );

    this.sessions.consultation.subscribe(
      value => this.refilter()
    );

    this.config.changed.subscribe(
      value => this.refilter()
    );

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd: {
          this.refilter();
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    this.initialize();
  }

  public initialize() {
    const applicationRoutes = (item) => !this.router.isActive('survey', false);
    const blankResearchRoutes = (item) => applicationRoutes(item) && this.researches.rid !== -1 && !this.sessions.pause.value && !this.sessions.consultation.value;
    const canAddContactRoutes = (item) => blankResearchRoutes(item) &&  +this.research.extraparams?.allowaddcontact === 1;
    const workRoutes = (item) => applicationRoutes(item) && !this.sessions.pause.value && !this.sessions.consultation.value;
    const pauseRoutes = (item) => applicationRoutes(item) && this.sessions.pause.value;

    this.register({ caption: 'Vítejte', hint: 'Uvítací stránka.', name: 'welcome', path: 'welcome', icon: 'home', roles: ['!@'], canShow: applicationRoutes });
    this.register({ caption: 'Losovat', hint: 'Losovat nový hovor ve vybraném výzkumu. Upřednostněny jsou odložené hovory na přesný čas, na časové období a pak následují noví respondenti.', name: 'toss', icon: 'phone', color: Color.ORANGE, roles: ['@'], canShow: blankResearchRoutes, action: this.actionToss });
    this.register({ caption: 'Přidat kontakt', hint: 'Přidat nový kontakt a zahájit hovor', name: 'addcontact', icon: 'contact_mail', roles: ['@'], canShow: canAddContactRoutes, action: this.actionAddContact });
    this.register({ caption: 'Pokračovat', hint: 'Pokračovat ve vybraném odloženém rozhovoru.', name: 'continue', icon: 'restore', roles: ['@'], canShow: (item) => workRoutes(item) && !(this.respondents.respondent.value === null), action: this.actionCheckIfContinue });
    this.register({ caption: 'Respondenti', hint: 'Seznam odložených rozhovorů.', name: 'respondents', path: 'respondents', icon: 'people', roles: ['@'], canShow: blankResearchRoutes });
    this.register({ caption: 'Přestávka', hint: 'Začít přestávku... Režim přestávky je zvýrazněn zeleným okrajem kolem aplikace.', name: 'pause_start', path: 'news', icon: 'pause', roles: ['@'], canShow: workRoutes, action: this.actionPause })
    this.register({ caption: 'Pracovat', hint: 'Ukončit přestávku... Pracovní režim je zvýrazněn modrým okrajem kolem aplikace.', name: 'pause_stop', path: 'news', icon: 'play_arrow', roles: ['@'], canShow: (item) => applicationRoutes(item) && this.sessions.pause.value && !this.sessions.consultation.value, action: this.actionUnpause });
    this.register({ caption: 'Konzultace', hint: 'Přepnout režim "Konzultace se supervizí!" Aktivní režim konzultace je vyznačen červeným okrajem kolem aplikace.', name: 'supervision_toggle', path: 'news', icon: 'supervisor_account', roles: ['@'], canShow: (item) => applicationRoutes(item) && !this.sessions.pause.value, action: this.actionSupervisionToggle });
    this.register({ caption: 'Zkušební režim', hint: 'Zapnutí / vypnutí zkušebního režimu. Ve zkušebním režimu nebudou ukládána žádná data dotazníků a nebudou vytáčena žádná telefonní čísla. Zkušební režim je zvýrazněn žlutým okrajem kolem aplikace.', name: 'testmode', icon: 'toys', roles: ['@'], canShow: (item) => applicationRoutes(item) && !this.sessions.consultation.value && !this.sessions.pause.value, action: this.actionTestmode });
    this.register({ caption: 'Novinky a zprávy', hint: 'Novinky a zprávy pro operátory od supervize.', name: 'news', path: 'news', icon: 'mail_outline', roles: ['@'], canShow: applicationRoutes });
    this.register({ caption: 'Směny', hint: 'Nahlašování a kontrola schválení směn.', name: 'shifts', path: 'shifts', icon: 'date_range', roles: ['@'], canShow: pauseRoutes});
    this.register({ caption: 'Nápověda', hint: 'Nápověda k používání aplikace.', name: 'help', path: 'help', icon: 'school', roles: ['@'], canShow: applicationRoutes });
    this.register({ caption: 'Žebříček', hint: 'Žebříček výkonnosti operátorů.', name: 'leaderboard', path: 'leaderboard', icon: 'format_list_numbered', roles: ['@'], canShow: pauseRoutes});
    this.register({ caption: 'Statistiky', hint: 'Souhrnné statistiky zvoleného projektu.', name: 'statistics', path: 'statistics', icon: 'bar_chart', roles: ['@'],  canShow: (item) => pauseRoutes(item) && this.researches.rid !== -1});
    this.register({ caption: 'Konfigurace', hint: 'Konfigurace aplikace. Slouží k odborné manipulaci s aplikací.', name: 'settings', path: 'configs', icon: 'settings', roles: ['!'], canShow: applicationRoutes });

    const surveyRoutesAll = (item) => {
      return this.router.isActive('survey', false);
    };

    const surveyRoutesIntro = (item) => {
      return (surveyRoutesAll(item) && this.interviews.intro.value);
    };

    const surveyRoutesQuest = (item) => {
      return (surveyRoutesAll(item) && this.interviews.contacted.value);
    };

    const surveyRoutesComplete = (item) => {
      return (surveyRoutesQuest(item) && this.interviews.progress.value.callres === Callres.Complete);
    };

    const surveyRouteStoppedBeginning = (item) => {
      return (surveyRoutesQuest(item) && this.interviews.progress.value.qname == null );
    };

    const surveyRouteStoppedDuring = (item) => {
      return (surveyRoutesQuest(item) && (this.interviews.progress.value.callres === Callres.StoppedDuring || this.interviews.progress.value.callres === Callres.Interrupted));
    };

    const surveyRouteRefusedBeginning = (item) => {
      return (surveyRoutesIntro(item) || (surveyRoutesQuest(item) && this.interviews.progress.value.qname == null));
    };

    const surveyRouteRefusedDuring = (item) => {
      return (surveyRoutesQuest(item) && this.interviews.progress.value.qname !== null && this.interviews.progress.value.callres !== Callres.Complete && this.interviews.progress.value.callres !== Callres.StoppedDuring && this.interviews.progress.value.callres !== Callres.Interrupted);
    };

    this.register({ caption: 'Kontakt', hint: 'Podařilo se navázat kontakt s respondentem a bude zahájen dotazník.', name: 'interview_contact', icon: 'arrow_forward', color: Color.GREEN, roles: ['!'], canShow: surveyRoutesIntro, action: this.actionContact });
    this.register({ caption: 'Kompletní', hint: 'Ukončit dotazník jako kompletní. Data dotazníku budou považována za finální a budou použita pro výzkum.', name: 'interview_callres', icon: 'check', color: Color.GREEN, roles: ['!'], data: { callres: Callres.Complete }, canShow: surveyRoutesComplete, action: this.actionStopInterview });
    this.register({ caption: 'Nevyhovuje kvótě', hint: 'Respondent nevyhovuje kvótě z důvodu dílčích neshod z kvótami.', name: 'interview_callres', icon: 'person_add_disabled', roles: ['!'], data: { callres: Callres.StoppedBeginning }, canShow: surveyRouteStoppedBeginning, action: this.actionStopInterview });
    this.register({ caption: 'Ukončit screenem', hint: 'Respondent nevyhovuje screenu z důvodu zásadních neshod z cílovou skupinou.', name: 'interview_callres', icon: 'person_add_disabled', roles: ['!'], data: { callres: Callres.StoppedDuring }, canShow: surveyRouteStoppedDuring, action: this.actionStopInterview });
    this.register({ caption: 'Odmítá v intru', hint: 'Respondent odmítá dále pokračovat v rozhovoru.', name: 'interview_callres', icon: 'close', roles: ['!'], data: { callres: Callres.RefusedBeginning }, canShow: surveyRouteRefusedBeginning, action: this.actionStopInterview });
    this.register({ caption: 'Odmítá pokračovat', hint: 'Respondent odmítá pokračovat v rozhovoru.', name: 'interview_callres', icon: 'close', roles: ['!'], data: { callres: Callres.RefusedDuring }, canShow: surveyRouteRefusedDuring, action: this.actionStopInterview });
    this.register({ caption: 'Blacklist', hint: 'Respondent požádal, abychom ho již nikdy nekontaktovali a bude zařazen na tzv. Blacklist.', name: 'interview_blacklist', icon: 'flag', roles: ['!'], canShow: surveyRoutesQuest, action: this.actionBlackList });
    this.register({ caption: 'Špatné číslo', hint: 'Použité telefonní číslo není správné nebo je nefunkční.', name: 'interview_callres', icon: 'phonelink_erase', roles: ['!'], data: { callres: Callres.BadNumber }, canShow: surveyRoutesIntro, action: this.actionStopInterview });
    this.register({ caption: 'Obsazeno', hint: 'Telefonní číslo je obsazeno, zkusíme zavolat později.', name: 'interview_callres', icon: 'phone_in_talk', roles: ['!'], data: { callres: Callres.Busy }, canShow: surveyRoutesIntro, action: this.actionStopInterview });
    this.register({ caption: 'Nelze vytočit', hint: 'Telefonní číslo neze vytočit z jakéhokoli důvodu.', name: 'interview_callres', icon: 'warning', roles: ['!'], data: { callres: Callres.DialFailed }, canShow: surveyRoutesIntro, action: this.actionStopInterview });
    this.register({ caption: 'Fax / záznamník', hint: 'Vzdálená strana je záznamník nebo fax. Nepoužívat v žadném jiném případě!', name: 'interview_callres', icon: 'voicemail', roles: ['!'], data: { callres: Callres.FaxVoicebox }, canShow: surveyRoutesIntro, action: this.actionStopInterview });
    this.register({ caption: 'Nezvedá', hint: 'Vzdálená strana nezvedá, zkusíme zavolat později.', name: 'interview_callres', icon: 'phone_missed', roles: ['!'], data: { callres: Callres.NotAnswering }, canShow: surveyRoutesIntro, action: this.actionStopInterview });
    this.register({ caption: 'Odložit', hint: 'Odložit rozhovor na jiný termín. Bude umožněno zadat konkrétní termín, jiné telefonní číslo a základní sociodemografika.', name: 'interview_callres', icon: 'av_timer', color: Color.BLUE, roles: ['!'], data: { callres: Callres.Deferred }, canShow: surveyRoutesAll, action: this.actionStopInterview });
    this.register({ caption: 'Znovu vytočit', hint: 'Znovu vytočit poslední volané číslo.', name: 'interview_callagain', icon: 'repeat', roles: ['!'], canShow: surveyRoutesAll, action: this.actionCallAgain });
    this.register({ caption: 'Nové číslo', hint: 'Získali jsme nové telefonní číslo. Bude uloženo do databáze a následně vytočeno.', name: 'interview_newnumber', icon: 'contact_phone', roles: ['!'], canShow: surveyRoutesAll, action: this.actionNewNumber });
    this.register({ caption: 'Alternativní', hint: 'Zavolat na alternativní telefonní číslo. Současné je buď nefunkční nebo z jiného důvodu nepoužitelné.', name: 'interview_alternativenumber', icon: 'phone_forwarded', roles: ['!'], canShow: (item) => surveyRoutesAll(item) && /^(?:[+\d].*\d|\d)$/.test(this.interviews.interview.value.respondent.phone2) === true, action: this.actionAlternative });
    this.register({ caption: 'Poznámka', hint: 'Přidat poznámku k rozhovoru. Dotazník nebude ukončen.', name: 'interview_note', icon: 'note_add', roles: ['!'], canShow: surveyRoutesAll, action: this.actionNote });

    this.refilter();
  }

  public register(item: MenuItemIntf) {
    this.registered.push(
      new MenuItem(item)
    );
  }

  public refilter() {
    const items = this.registered.filter(
      item => this.canShow(item)
    );

    this.items.next(items);
  }

  public canShow(node: MenuItem): boolean {
    const canAsGuest = node.roles.includes('?');
    const canAsGuestOnly = ( (this.user == null) && node.roles.includes('!@') );
    const canAsUser = ( (this.user != null) && node.roles.includes('@') );
    const canAsAdmin = ( (this.user != null) && node.roles.includes('!') );
    const canShow = node.canShow ? node.canShow(node) : true;
    return (canShow && (canAsGuest || canAsUser || canAsAdmin || canAsGuestOnly));
  }

  public executeItem(item: MenuItem) {
    if (!this.loading) {
      try {
        this.logging.logInformation('menu.service', `executing ${item.name} ${item.data ? JSON.stringify(item.data) : ''}`);

        if (item.path) {
          this.router.navigate([item.path]);
        }

        this.action.next(item);

        item.execute(this);

        this.refilter();
      } catch (e) {
        this.messages.show(`Nastala chyba při provádění akce "${item.name}"! Výpis chyby: ${e.message}`, 'error');
      }
    }
  }

  actionToss(item: MenuItem) {
    this.router.navigate(['survey'],
      {
        queryParams: {
          rid: this.researches.rid
        }
      }
    );
  }

  async actionCheckIfContinue() {
    const callsCount = await this.cati.getCallsCountToday(this.respondents.respondent.value.phone1);

    if (callsCount >= 5) {
      const text = `Na toto číslo bylo dnes voláno již ${callsCount}x.<br/><br/> ${this.respondents.respondent.value.phone1} <br/><br/>Opravdu chcete číslo vytočit?`;
      const ref = this.dialog.open(ConfirmationDialogComponent, {
        id: 'confirm',
        data: text,
        autoFocus: false
      });

      ref.afterClosed().subscribe(async result => {
        if (result) {
          this.actionContinue(null);
        }
      });
    } else {
      this.actionContinue(null);
    }
  }

  async actionContinue(menu: MenuItem) {
    const isLock = await this.cati.lockCall(this.researches.rid, this.respondents.respondent.value.respid);
    if (isLock) {
      this.router.navigate(['survey'],
        {
          queryParams: {
            rid: this.researches.rid,
            respid: this.respondents.respondent.value.respid
          }
        }
      );
    } else {
      this.respondents.refresh();
      this.messages.show('S tímto respondentem již hovoří jiný operátor.', 'info');
    }
  }

  public actionAddContact() {
    return new Promise<boolean>(resolve => {
      const data: PhoneDialogData = {
        phone: null
      };

      const ref = this.dialog.open(PhoneDialogComponent, {
        width: '400px',
        data: data
      });

      ref.afterClosed().subscribe(async data => {
        if (data) {
          const post = await this.cati.addContact(
            this.research.rid,
            data.phone,
            this.sessions.testmode.value ? 1 : 0
          );

          this.router.navigate(['survey'], {
            queryParams: {
              rid: this.researches.rid,
              respid: post,
              man: true
            }
          });
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  actionPause(item: MenuItem) {
    this.sessions.startPause();
    this.refilter();
  }

  actionUnpause(item: MenuItem) {
    this.sessions.stopPause();
    this.refilter();
  }

  actionTestmode(item: MenuItem) {
    this.sessions.toggleTestmode();
  }

  async actionStopInterview(item: MenuItem) {
    const text = (item.data.callres === Callres.Deferred) ? 'Opravdu chcete odložit rozhovor?' : 'Opravdu chcete ukončit rozhovor?';
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      id: 'confirm',
      data: text,
      autoFocus: false
    });

    ref.afterClosed().subscribe(async result => {
      if (result) {
        try {
          const interview = this.interviews.interview.value;

          if (item.data.callres === Callres.Deferred) {
            const confirmed = await this.interviews.defer();

            if (!confirmed) {
              return null;
            }
          }

          interview.tstop = new Date();
          interview.acttime = Math.abs(interview.tstart.getTime() - interview.tstop.getTime()) / 60000;
          interview.callres = item.data.callres;

          await this.interviews.stopInterview(this.interviews.interview.value);

          this.router.navigate(['respondents']);
        } catch (e) {
          this.logging.logError('menu.service', e.message);
          this.router.navigate(['respondents']);
        }
      }
    });
  }

  actionBlackList(item: MenuItem) {
    this.interviews.blacklist();
  }

  actionContact(item: MenuItem) {
    this.interviews.contact();
    this.refilter();
  }

  actionNewNumber(item: MenuItem) {
    this.interviews.dialNew();
  }

  actionAlternative(item: MenuItem) {
    this.interviews.dialAlternative();
  }

  actionNote(item: MenuItem) {
    this.interviews.note();
  }

  actionSupervisionToggle(item: MenuItem) {
    this.sessions.toggleConsultation();
  }

  actionCallAgain(item: MenuItem) {
    this.interviews.dialAgain();
  }
}

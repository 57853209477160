<h1 mat-dialog-title>Přihlášení<mat-spinner *ngIf="logging" [diameter]="30" class="pull-right"></mat-spinner></h1>

<ng-form>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="space-evenly">

    <mat-form-field class="form-element">
      <input matInput placeholder="Uživatelské jméno" name="username" [(ngModel)]="data.username"/>
    </mat-form-field>

    <mat-form-field class="form-element">
      <input matInput placeholder="Heslo" type="password" name="password" [(ngModel)]="data.password"/>
    </mat-form-field>

    <mat-error *ngIf="message">
      {{message}}
    </mat-error>
  </div>

  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="login()" cdkFocusInitial type=submit>Přihlásit</button>
    <button mat-button (click)="close()">Zavřít</button>
  </div>
</ng-form>

<app-page-wrapper>
  <div wrapper-title>Vítejte v CATI Online!
  </div>

  <div wrapper-actions>
  </div>

  <div wrapper-content>
    <div fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="50px">
      <div>
        <p>
          Nacházíte se v aplikaci pro telefonické dotazování. Pokračujte stisknutím tlačítka přihlášení. Pokud jste se
          zde ocitli náhodou nebo jste dostali doporučení a chtěli byste dotazovat, neváhejte a navštivte stránku,
          kde najdete podrobnější informace jak se zapojit.
        </p>
        <p>
          <a href="http://www.acrc.sk/kontakt/">http://www.acrc.sk/kontakt/</a>
        </p>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start">
        <button mat-raised-button color="primary" (click)="actionLogin()" *ngIf="!authorization.authorized"><mat-icon>lock_open</mat-icon> Přihlásit</button>
        <button mat-raised-button color="primary" (click)="actionLogout()" *ngIf="authorization.authorized"><mat-icon>lock</mat-icon> Odhlásit</button>
      </div>
    </div>
  </div>
</app-page-wrapper>

<div fxFlex fxLayout="column">

  <mat-accordion>
    <mat-expansion-panel #detailWindow [disabled]="!isDetailWindow">
      <mat-expansion-panel-header>
        <mat-panel-title *ngIf="isDetailWindow" fxLayoutAlign="start center">
          <mat-icon>filter_list</mat-icon>  Podrobné informace
        </mat-panel-title>
        <mat-panel-title *ngIf="!isDetailWindow" fxLayoutAlign="start center">
          <mat-icon>filter_list</mat-icon>  Žádné podrobné informace
        </mat-panel-title>
        <mat-panel-description fxLayoutAlign="start center">
          <span *ngIf="!detailWindow.expanded && isDetailWindow">Klikněte pro rozbalení...</span>
          <span *ngIf="detailWindow.expanded && isDetailWindow">Klikněte pro zabalení...</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-detailwindow></app-detailwindow>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-progress-bar mode="determinate" [value]="progressbarValue"></mat-progress-bar>

  <mat-card fxFlex fxLayout="column" [fxShow]="view === 1" fxLayoutGap="10px">
    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutGap="10px">
      <mat-card fxFlex="50">
        <mat-card-title>Historie volání</mat-card-title>

        <mat-card-content fxLayout="column" class="history">
          <div fxLayout="row" *ngFor="let call of history">
            <div fxFlex="40">{{call.tstart | datetime}}</div>
            <div fxFlex="35">{{call.callres | syslist : 'c2' | async}}</div>
            <div fxFlex="25">{{call.username}}</div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="50">
        <mat-card-title>Poslední poznámka odložení</mat-card-title>
        <mat-card-content>
          {{respondent?.tocall_note}}
        </mat-card-content>
      </mat-card>
    </div>

    <mat-card fxFlex>
      <mat-card-title>Intro</mat-card-title>
      <mat-card-content>
        <div fxFlex [innerHTML]="research ? research.initmessage : null | safeHtml"></div>
      </mat-card-content>
    </mat-card>
  </mat-card>

  <mat-card fxFlex fxLayout="column" [fxShow]="view === 2">
    <mat-progress-bar mode="determinate" [value]="progress? progress.progress : null"></mat-progress-bar>
    <div *ngIf="research" [ngSwitch]="research.questionary" fxFlex>
      <!--<app-surveyjs #survey *ngSwitchCase="'surveyjs'" fxFlex></app-surveyjs>-->
      <app-iquest #survey *ngSwitchCase="'iquest'" fxFlex></app-iquest>
    </div>
  </mat-card>

  <mat-card fxFlex [fxShow]="view === 3">
    <mat-card fxFlex>
      <mat-card-title>Instrukce k výzkumu</mat-card-title>
      <mat-card-content>
        <div [innerHTML]="research ? research.instructions : null | safeHtml"></div>
      </mat-card-content>
    </mat-card>
  </mat-card>

  <div class="tab">
    <button class="tablinks" (click)="view = 1">Intro</button>
    <button class="tablinks" [disabled]="!interviews.contacted.value" (click)="view = 2">Dotazník</button>
    <button class="tablinks" (click)="view = 3">Pokyny</button>
  </div>

</div>

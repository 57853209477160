<mat-card>

  <mat-card-header>
    <mat-card-title class="full-width flex-parent-row">
      <h1>Úprava položky nastavení: {{config.name}}</h1>
      <button class="flex-grow" mat-stroked-button color="primary" (click)="configForm.ngSubmit.emit()">
        <mat-icon>save</mat-icon> Uložit položku
      </button>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <ng-form [formGroup]="configGroup" (ngSubmit)="submitConfig()" #configForm="ngForm" class="form">

      <mat-card>
        <mat-card-content fxLayout="column" fxLayoutGap="10px" class="full-size-controls">
          <mat-form-field class="form-element">
            <input matInput placeholder="Název" formControlName="name">
          </mat-form-field>

          <mat-form-field class="form-element">
            <input matInput placeholder="Hodnota" formControlName="value">
          </mat-form-field>

          <mat-form-field class="form-element">
            <textarea matInput placeholder="Popis" formControlName="description" rows="4">
            </textarea>
          </mat-form-field>

          <mat-form-field>
            <input matInput [matDatepicker]="changeDate" placeholder="Datum změny" formControlName="changed">
            <mat-datepicker-toggle matSuffix [for]="changeDate"></mat-datepicker-toggle>
            <mat-datepicker #changeDate></mat-datepicker>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

    </ng-form>

  </mat-card-content>
</mat-card>

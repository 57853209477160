import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WelcomeComponent} from './body/content/view/welcome/welcome.component';
import {RespondentsComponent} from './body/content/view/respondents/respondents.component';
import {SurveyComponent} from './body/content/view/survey/survey.component';
import {SurveyResolver} from './resolvers/survey.resolver';
import {ErrorComponent} from './body/content/view/error/error.component';
import {ErrorResolver} from './resolvers/error.resolver';
import {ConfigsComponent} from './body/content/view/configs/configs.component';
import {ConfigResolver} from './resolvers/config.resolver';
import {NewsComponent} from './body/content/view/news/news.component';
import {ConfigEditComponent} from './body/content/view/configs/config-edit/config-edit.component';
import {HelpComponent} from './body/content/view/help/help.component';
import {ShiftsComponent} from './body/content/view/shifts/shifts.component';
import {ShiftEditComponent} from './body/content/view/shifts/shift-edit/shift-edit.component';
import {ShiftResolver} from './resolvers/shift.resolver';
import {LeaderboardComponent} from './body/content/view/leaderboard/leaderboard.component';
import {StatisticsComponent} from './body/content/view/statistics/statistics.component';
import {ProfileTabsComponent} from './body/content/view/profile-tabs/profile-tabs.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'welcome', component: WelcomeComponent},
  {path: 'survey', component: SurveyComponent, resolve: {survey: SurveyResolver}},
  {path: 'respondents', component: RespondentsComponent},
  {path: 'configs', component: ConfigsComponent},
  {path: 'config', component: ConfigEditComponent, resolve: {config: ConfigResolver}},
  {path: 'news', component: NewsComponent},
  {path: 'help', component: HelpComponent},
  {path: 'leaderboard', component: LeaderboardComponent},
  {path: 'statistics', component: StatisticsComponent},
  {path: 'shifts', component: ShiftsComponent},
  {path: 'shift', component: ShiftEditComponent, resolve: {shift: ShiftResolver}},
  {path: 'profile-tabs', component: ProfileTabsComponent},
  {path: 'not-found', component: ErrorComponent, data: {message: 'Stránka nenalezena!'}},
  {path: 'error', component: ErrorComponent, resolve: {message: ErrorResolver}},
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

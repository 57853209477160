<app-page-wrapper>
  <div wrapper-title>
    Hodnocení operátora
  </div>

  <div wrapper-actions>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Rok</mat-label>
        <mat-select [(ngModel)]="year" (valueChange)="loadScoreByYear($event)" name="year">
          <mat-option *ngFor="let year of years" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Měsíc</mat-label>
        <mat-select [(ngModel)]="month.value" (valueChange)="loadScoreByMonth($event)" name="month">
          <mat-option *ngFor="let item of months" [value]="item.value">{{item.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div wrapper-content>
    <table mat-table [dataSource]="dataSource" fxFlex>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <ng-container matColumnDef="evaldate">
        <th mat-header-cell *matHeaderCellDef [style.min-width]="'100px'">Datum</th>
        <td mat-cell *matCellDef="let item">{{item.evaldate | date}}</td>
      </ng-container>

      <ng-container matColumnDef="evaluator">
        <th mat-header-cell *matHeaderCellDef [style.min-width]="'150px'">Hodnotil(a)</th>
        <td mat-cell *matCellDef="let item">{{item.evaluator}}</td>
      </ng-container>

      <ng-container matColumnDef="points">
        <th mat-header-cell *matHeaderCellDef [style.min-width]="'50px'">Body</th>
        <td mat-cell *matCellDef="let item">{{item.points}}</td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Poznámka</th>
        <td mat-cell *matCellDef="let item">{{item.note}}</td>
      </ng-container>

    </table>
  </div>
</app-page-wrapper>

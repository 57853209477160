export class ConfigModel {

  constructor(
    public name?: string,
    public value?: any,
    public description?: string,
    public changed?: Date,
  ) {

  }
}

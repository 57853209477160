import {Injectable} from '@angular/core';
import { CatiService } from './cati.service';
import { ResearchesService } from './researches.service';
import {BehaviorSubject, Subscription, timer} from 'rxjs';
import { LoggingService } from './logging.service';
import {environment} from '../../environments/environment';
import {AuthorizationService} from './authorization.service';
import {UserModel} from '../models/user.model';
import { MessagesService } from './messages.service';
import {ConfigService} from './config.service';

/**
 * Service taking care of all sessions and pauses times. Calculating active sessions, pauses and updating current session to API.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionsService {

  /** Testmode is active Subject. Application can register here for changes. */
  testmode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** Pause is active Subject. Application can register here for changes. */
  pause: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** Consulation is active Subject. Application can register here for changes. */
  consultation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** Date time of current session. */
  sessionStart: Date = new Date();
  /** Total session time in seconds. */
  sessionsTime = 0;
  /** Current session id used for updating current session time in API. */
  sessionId: number;
  /** Date time of current pause. */
  pauseStart: Date;
  /** Total pauses time in seconds. */
  pausesTime = 0;
  /** Current pause id used for updating current pause time in API */
  pauseId: number;
  /** Date time of current consultation. */
  consultationStart: Date;
  /** Total consultations time in seconds. */
  consultationsTime = 0;
  /** Date time of current testmode. */
  testmodeStart: Date;
  /** Total testmode time in seconds. */
  testmodeTime = 0;
  /** Current consultations id used for updating current consultation time in API */
  consultationId: number;
  /** Currently logged user. */
  currentUser: UserModel;

  /** Session update timer */
  timer: Subscription;

  rid: number;

  constructor(
    private authorization: AuthorizationService,
    private researches: ResearchesService,
    private logger: LoggingService,
    private cati: CatiService,
    private messageService: MessagesService,
    private config: ConfigService,
  ) {
    if (!environment.production) {
      this.testmode.next(true);
    }

    this.researches.research.subscribe(
      async research => {
        if (this.currentUser) {
          if (research) {
            if (this.rid !== research.rid) {
              this.startSession();
              this.rid = research.rid;
            }
          } else {
            if (!this.rid) {
              this.startSession();
              this.rid = -1;
            }
          }
        } else {
          if (!this.authorization.authorized) {
            this.rid = null;
          }
        }
      }
    );

    this.timer = timer(0, 10000).subscribe(
      value => {
        if (this.sessionId) {
          this.updateSession();
        } else {
          this.startSession();
        }

        if (this.pauseId) {
          this.updatePause();
        }

        if (this.consultationId) {
          this.updateConsultation();
        }
      }
    );

    this.authorization.user.subscribe(
      user => {
        this.currentUser = user;
        this.sessionId = null;
        this.pauseId = null;
        this.consultationId = null;
      }
    );
    this.rid = null;
  }

  /**
   * Start new session. Usually called right after login. Call leads to generating new session id used for updating session later.
   */
  public async startSession() {
    try {
      if (!this.currentUser) {
        return;
      }
      if (this.testmode.value) {
        this.testmodeStart = new Date();
      } else if (!this.testmode.value)  {
        this.testmodeStart = null;
      }

      this.sessionId = await this.cati.startSession(this.researches.rid, this.testmode.value, this.sessionId, this.config.get('app.clid'), this.getState());
      this.sessionStart = new Date();
      this.sessionsTime = await this.cati.getTodaySessionsTotal();
      this.pausesTime = await this.cati.getTodayPausesTotal();
      this.consultationsTime = await this.cati.getTodayConsultationsTotal();
      this.testmodeTime = await this.cati.getTodayTestmodeTotal();
    } catch (e) {
      this.logger.logError('sessions.service', `startSession: ${e.message}`);
      this.authorization.logout(this.currentUser.oid, this.currentUser.line);
    }
  }

  /**
   * Stops existing session. Usually called during logout. Call leads to reseting session id.
   */
  public async stopSession() {
    try {
      if (!this.currentUser) {
        return;
      }

      await this.updateSession();
      this.sessionId = null;
      this.sessionStart = null;
    } catch (e) {
      this.logger.logError('sessions.service', `stopSession: ${e.message}`);
    }
  }

  /**
   * Update exiting session. Usually called by timer. Updates session time in API. Using existing session id.
   */
  public async updateSession() {
    if (this.sessionId) {
      try {
        const data = await this.cati.updateSession(this.sessionId, this.config.get('app.clid'), this.getState());
        if (data.result || (data.result === false && this.sessionId !== data.sessionId)) {
          this.sessionStart = new Date();
          this.sessionsTime = await this.cati.getTodaySessionsTotal();
        } else {
          this.messageService.show('session is inactive', 'error');
          this.authorization.logout(this.currentUser.oid, this.currentUser.line);
        }
      } catch (e) {
        this.logger.logError('sessions.service', `updateSession: ${e.message}`);
        this.messageService.show(e.message, 'error');
        this.authorization.logout(this.currentUser.oid, this.currentUser.line);
      }
    }
  }

  /**
   * Starts new pause. Usually called by user interaction. Call leads to generating new pause id used for updating pause later.
   */
  public async startPause() {
    try {
      if (!this.currentUser) {
        return;
      }

      this.pauseId = await this.cati.pauseSession(this.sessionId);
      this.pauseStart = new Date();
      this.pausesTime = await this.cati.getTodayPausesTotal();
      this.pause.next(true);

      if (this.testmode.value) {
        this.toggleTestmode();
      }
    } catch (e) {
      this.logger.logError('sessions.service', `startPause: ${e.message}`);
    }
  }

  /**
   * Stops exiting pause. Usually called by user interaction. Call leads to reseting pause id and generating an event for application.
   */
  public async stopPause() {
    try {
      if (!this.currentUser) {
        return;
      }

      await this.updatePause;
      this.pauseId = null;
      this.pauseStart = null;
      this.pause.next(false);
    } catch (e) {
      this.logger.logError('sessions.service', `stopPause: ${e.message}`);
    }
  }

  /**
   * Update exiting pause. Usually called by timer. Updates pause time in API. Using existing pause id.
   */
  public async updatePause() {
    if (this.pauseId) {
      try {
        await this.cati.updatePause(this.pauseId);
        this.pauseStart = new Date();
        this.pausesTime = await this.cati.getTodayPausesTotal();
      } catch (e) {
        this.logger.logError('sessions.service', `updatePause: ${e.message}`);
      }
    }
  }

  /**
   * Starts new consultation. Usually called by user interaction. Call leads to generating new pause id used for updating consultation later.
   */
  public async startConsultation() {
    try {
      if (!this.currentUser) {
        return;
      }


      this.consultationId = await this.cati.startConsultation();
      this.consultationStart = new Date();
      this.consultationsTime = await this.cati.getTodayConsultationsTotal();
      this.consultation.next(true);

      if (this.testmode.value) {
        this.toggleTestmode();
      }
    } catch (e) {
      this.logger.logError('sessions.service', `startConsultation: ${e.message}`);
    }
  }

  /**
   * Stops exiting consultation. Usually called by user interaction. Call leads to reseting consultation id and generating an event for application.
   */
  public async stopConsultation() {
    try {
      if (!this.currentUser) {
        return;
      }

      await this.updateConsultation();
      this.consultationId = null;
      this.consultationStart = null;
      this.consultation.next(false);
    } catch (e) {
      this.logger.logError('sessions.service', `stopConsultation: ${e.message}`);
    }
  }

  /**
   * Update exiting consultation. Usually called by timer. Updates consultation time in API. Using existing consultation id.
   */
  public async updateConsultation() {
    if (this.consultationId) {
      try {
        await this.cati.updateConsultation(this.consultationId);
        this.consultationStart = new Date();
        this.consultationsTime = await this.cati.getTodayConsultationsTotal();
      } catch (e) {
        this.logger.logError('sessions.service', `updateConsultation: ${e.message}`);
      }
    }
  }

  /**
   * Toggles consultation state. Usually called by user. Starts a new consulation or stops existing consultation, depends on current state.
   */
  public async toggleConsultation() {
    try {
      if (this.consultation.value) {
        this.stopConsultation();
      } else {
        this.startConsultation();
      }
    } catch (e) {
      this.logger.logError('sessions.service', `toggleConsultation: ${e.message}`);
    }
  }

  /**
   * Toggles testmode value. Usually called by user interaction. Call leads to closing old session and generating a new one.
   */
  public async toggleTestmode() {
    try {
      if (!this.currentUser) {
        return;
      }

      this.testmode.next(!this.testmode.value);
      await this.updateSession();
      await this.startSession();
      this.testmodeTime = await this.cati.getTodayTestmodeTotal();
    } catch (e) {
      this.logger.logError('sessions.service', `toggleTestmode: ${e.message}`);
    }
  }

  public get todaySessionsTotal(): number {
    let current = 0;

    if (this.sessionStart) {
      current = ((Date.now() - this.sessionStart.getTime()) / 1000);
    }

    return this.sessionsTime + current;
  }

  public get todayPausesTotal(): number {
    let current = 0;

    if (this.pauseStart) {
      current = ((Date.now() - this.pauseStart.getTime()) / 1000);
    }

    return this.pausesTime + current;
  }

  public get todayConsultationsTotal(): number {
    let current = 0;

    if (this.consultationStart) {
      current = ((Date.now() - this.consultationStart.getTime()) / 1000);
    }

    return this.consultationsTime + current;
  }

  public get todayTestmodeTotal(): number {
    let current = 0;

    if (this.testmodeStart) {
      current = ((Date.now() - this.testmodeStart.getTime()) / 1000);
    }

    return this.testmodeTime + current;
  }

  public getState() {
    let state: number;

    if (this.consultation.value) {
      state = 4;
    } else if (this.pause.value) {
      state = 3;
    } else if (this.testmode.value) {
      state = 2;
    } else if (!this.testmode.value) {
      state = 1;
    }
    return state;
  }
}



<app-page-wrapper>
  <div wrapper-title>Jejda, něco se pokazilo!</div>

  <div wrapper-actions></div>

  <div wrapper-content>
    <mat-card>
      <p>Detail chyby: </p>
      <pre>{{message | json}}</pre>
    </mat-card>
  </div>
</app-page-wrapper>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    if (value) {
      const dt = new Date(value);
      return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
    } else {
      return null;
    }
  }

}

import { Injectable } from '@angular/core';

class LogItem {

  constructor(
    public date: Date,
    public severity: number,
    public module: string,
    public message: string
  ) { }

}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  public last: LogItem = null;
  public items: LogItem[] = [];

  constructor() { }

  public message(date: Date, severity: number, module: string, message: string, color: string = 'black') {
    const item = new LogItem(date, severity, module, message);
    this.items.push(item);
    console.log(`%c ${date.toISOString()} LoggingService: ${module} - ${message}`, `color: ${color};`);
    this.last = item;
  }

  public logInformation(module: string, message: string) {
    this.message(new Date(), 0, module, message, 'blue');
  }

  public logWarning(module: string, message: string) {
    this.message(new Date(), 1, module, message, 'orange');
  }

  public logError(module: string, message: string) {
    this.message(new Date(), 2, module, message, 'red');
  }

  public logException(module: string, message: string) {
    this.message(new Date(), 3, module, message, 'fuchsia');
  }
}

import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-wrapper',
  template: `
    <div fxLayout="column" fxLayoutGap="10px" style="margin:16px">
      <div fxLayout="row">
        <h1><ng-content select="[wrapper-title]"></ng-content></h1>
        <div fxFlex fxLayoutAlign="end center">
          <ng-content select="[wrapper-actions]"></ng-content>
        </div>
      </div>

      <div fxFlex fxLayout="column">
        <ng-content select="[wrapper-content]"></ng-content>
      </div>
    </div>
  `,
  styles: []
})
export class PageWrapperComponent implements OnInit {

  @HostBinding('class.flex') host = true;

  constructor() { }

  ngOnInit() {
  }

}

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { ResearchModel } from '../models/research.model';
import { ConfigService } from '../services/config.service';
import {SurveyInterface} from './survey.interface';
import {RespondentModel} from '../models/respondent.model';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {InterviewModel} from '../models/interview.model';
import {ProgressModel} from '../models/progress.model';
import {CatiService} from '../services/cati.service';

@Component({
  selector: 'app-iquest',
  template: `
    <iframe *ngIf="url" [src]="url | safeUrl" frameborder="0" fxFlex></iframe>
    <div *ngIf="!url" fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div><mat-spinner></mat-spinner></div>
      <div>Načítání dotazníku...</div>
    </div>
  `
})
export class IquestComponent implements OnInit, OnDestroy, SurveyInterface {

  private research: ResearchModel;
  private respondent: RespondentModel;
  private interview: InterviewModel;
  private progress: Subject<ProgressModel>;
  private timer: Subscription;
  private loaded = false;
  public url: string;

  constructor(
    private config: ConfigService,
    private cati: CatiService,
    private cd: ChangeDetectorRef
  ) {  }

  ngOnInit() {
    this.progress = new Subject<ProgressModel>();
    this.timer = timer(1000, 1000).subscribe(
      async value => {
        this.progress.next(
          await this.getQuestProgress()
        );
      }
    );
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
    this.progress.unsubscribe();
  }

  load(
    research: ResearchModel,
    respondent: RespondentModel,
    interview: InterviewModel
  ): Observable<ProgressModel> {
    this.research = research;
    this.respondent = respondent;
    this.interview = interview;
    this.loaded = true;

    const baseUrl = this.config.get('cati.iquest.url');

    this.url = `${baseUrl}?rid=${research.rid}&cati=${interview.intid}&embedded=0`;

    /** Prevent error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. */
    this.cd.detectChanges();

    return this.progress;
  }

  public getQuestProgress(): Promise<ProgressModel> {
    if (this.loaded) {
      return this.cati.getQuestProgress(this.research.rid, this.respondent.respid, this.interview.intid);
    } else {
      return null;
    }
  }
}

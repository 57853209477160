import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ConfigModel } from '../models/config.model';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class ConfigResolver implements Resolve<ConfigModel> {

  constructor(
    private logging: LoggingService,
    private storageService: StorageService,
    private router: Router
  ) {

  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ConfigModel> {
    const name = route.queryParams['id'];

    let config;

    if (name === 'new') {
      config = new ConfigModel();
    } else {
      config = await this.storageService.configs.get(name);
    }

    if (config) {
      return config;
    } else {
      this.logging.logError(this.constructor.name, `Konfigurace name=${name} nebyla nalezena!`);
      this.router.navigate(['error']);
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class ErrorResolver implements Resolve<string> {

    constructor(
        private logging: LoggingService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<string> | Promise<string> | string {
        if (this.logging.last) {
            return this.logging.last.message;
        } else {
            return null;
        }
    }
}

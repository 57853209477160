import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {SessionsService} from '../../../services/sessions.service';
import {AuthorizationService} from '../../../services/authorization.service';
import {UserModel} from '../../../models/user.model';
import {InactivityService} from '../../../services/inactivity.service';

@Component({
  selector: 'app-sessionbar',
  templateUrl: './sessionbar.component.html',
  styleUrls: ['./sessionbar.component.css']
})
export class SessionbarComponent implements OnInit, OnDestroy {

  private timer: Subscription;
  private consultationSubscription: Subscription;
  private pauseSubscription: Subscription;
  private inactivitySubscription: Subscription;
  private testmodeSubscription: Subscription;

  public sessionTime: number;
  public pausesTime: number;
  public inactive: boolean;
  public pause: boolean;
  public consultation: boolean;
  public testmode: boolean;
  public inactivityTime: number;
  public consultationsTime: number;
  public testmodeTime: number;
  public user: UserModel;

  constructor(
    private authorization: AuthorizationService,
    private sessions: SessionsService,
    private inactivity: InactivityService,
    private cd: ChangeDetectorRef
  ) {
    this.authorization.user.subscribe(
      user => this.user = user
    );
  }

  ngOnInit(): void {
    this.timer = timer(0, 1000).subscribe(
      async value => {
        this.sessionTime = this.sessions.todaySessionsTotal;
        this.pausesTime = this.sessions.todayPausesTotal;
        this.inactivityTime = this.inactivity.todayInactivityTotal;
        this.consultationsTime = this.sessions.todayConsultationsTotal;
        this.testmodeTime = this.sessions.todayTestmodeTotal;
        this.cd.detectChanges();
      }
    );

    this.inactivitySubscription = this.inactivity.inactive.subscribe(
      inactive => this.inactive = inactive
    );

    this.pauseSubscription = this.sessions.pause.subscribe(
      pause => this.pause = pause
    );

    this.consultationSubscription = this.sessions.consultation.subscribe(
      consultation => this.consultation = consultation
    );

    this.testmodeSubscription = this.sessions.testmode.subscribe(
      testmode => this.testmode = testmode
    );
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
    this.pauseSubscription.unsubscribe();
    this.consultationSubscription.unsubscribe();
    this.inactivitySubscription.unsubscribe();
    this.testmodeSubscription.unsubscribe();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seconds'
})
export class SecondsPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    const time = new Date(null);
    if (value) {
      time.setSeconds(value); // specify value for SECONDS here
      const str = time.toISOString().substr(11, 8);
      return str;
    } else {
      return null;
    }
  }

}

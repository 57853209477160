import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {LeaderboardItemModel, LeaderboardModel} from '../../../../models/leaderboard.model';
import {CatiService} from '../../../../services/cati.service';
import {Subscription, timer} from 'rxjs';
import {SyslistModel} from '../../../../models/syslist.model';
import {CodelistsService} from '../../../../services/codelists.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})
export class LeaderboardComponent implements OnInit, OnDestroy {

  @HostBinding('class.flex') flex = true;
  public displayedColumns: string[] = [
    'medal',
    'operator',
    'minutes',
    'percents',
    'homeoper',
    'locality',
    'lastping'
  ];
  public dataSource: LeaderboardItemModel[];
  public leaderboard: LeaderboardModel;
  public locality: SyslistModel;
  private timer: Subscription;

  constructor(
    private cati: CatiService,
    private codelists: CodelistsService
  ) { }

  ngOnInit() {
    this.timer = timer(0, 5000).subscribe(
      value => {
        this.refresh();
      }
    );
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }

  public async refresh() {
    this.locality = await this.codelists.syslist('c13');
    this.leaderboard = await this.cati.getLeaderboard();
    this.dataSource = this.leaderboard.list;
  }
}

import { Injectable } from '@angular/core';
import { CatiService} from './cati.service';
import { NamedDictionary } from '../shared/cati.consts';
import { CodelistModel} from '../models/codelist.model';
import {SyslistModel} from '../models/syslist.model';

/**
 * Service is taking care of all global codelists which can be used by application. All codelists are cached,
 * so the API isn't bothered unnecessarily. The service can handle also system codelist (like c1, c2 tables).
 */
@Injectable({
  providedIn: 'root'
})
export class CodelistsService {

  /** Global codelists. (rid=0 codelists) */
  codelists: NamedDictionary<CodelistModel> = new class implements NamedDictionary<CodelistModel> {
    [id: string]: CodelistModel;
  };

  /** System codelists. (c1, c2, c3 tables) */
  syslists: NamedDictionary<SyslistModel> = new class implements NamedDictionary<SyslistModel> {
    [id: string]: SyslistModel;
  };

  constructor(
    private cati: CatiService
  ) {
  }

  /**
   * Get global codelist by name.
   * @param listname Name of the codelist.
   */
  public async codelist(listname: string): Promise<CodelistModel> {
    try {
      let cl = this.codelists[listname];
      if (!cl) cl = await this.cati.getCodelist(0, listname);
      this.codelists[listname] = cl;
      return cl;
    } catch (e) {
      return null;
    }
  }

  /**
   * Get system codelist by name.
   * @param table Name of the system codelist table.
   */
  public async syslist(table: string): Promise<SyslistModel> {
    try {
      let cl = this.syslists[table];
      if (!cl) cl = await this.cati.getSyslist(table);
      this.syslists[table] = cl;
      return cl;
    } catch (e) {
      return null;
    }
  }
}

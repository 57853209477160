import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutestohours',
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    let text: string;
    let seconds = value * 60;

    const hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    const mnts = Math.floor(seconds / 60);

    const hrsText = 'h ';
    const mntsText = 'm ';
    const secondsText = 's ';

    if (hrs === 0 && mnts === 0) {
      text = seconds + secondsText;
    } else if (hrs === 0) {
      text = mnts + mntsText;
    } else if (hrs !== 0 && mnts === 0) {
      text = hrs + hrsText;
    } else if (hrs !== 0 && mnts !== 0) {
      text = hrs + hrsText + mnts + mntsText;
    }

    return text;

  }

}

export class CallModel {
  constructor(
    public date?: Date,
    public rid?: number,
    public respid?: number,
    public intid?: number,
    public callres?: number,
    public oid?: number,
    public method?: string,
    public tocall?: Date
  ) {}
}

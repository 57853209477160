import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { RespondentModel } from '../../../../models/respondent.model';
import { RespondentsFilter } from '../../../../services/cati.service';
import { ResearchesService } from '../../../../services/researches.service';
import { RespondentsService } from '../../../../services/respondents.service';
import { MenuService } from '../../../../services/menu.service';
import { Subscription, timer } from 'rxjs';
import { panelFields } from '../../../../shared/cati.consts';
import { CATI } from '../../../../shared/cati.functions';
import { CodelistModel } from '../../../../models/codelist.model';
import { CodelistsService } from '../../../../services/codelists.service';

@Component({
  selector: 'app-respondents',
  templateUrl: './respondents.component.html',
  styleUrls: ['./respondents.component.css']
})
export class RespondentsComponent implements OnInit, OnDestroy {
  @HostBinding('class.flex') flex = true;
  displayedColumns: string[] = ['respid', 'oname', 'tocall', 'tocall_note', 'calledago', 'sex', 'age', 'edu'];
  dataSource: RespondentModel[];
  focused: RespondentModel;
  filter: RespondentsFilter;
  panelFields: any[] = CATI.dictToArray(panelFields);

  public sex: CodelistModel;
  public edu: CodelistModel;

  private timer: Subscription;

  researchSubscription: Subscription;
  respondentSubscription: Subscription;
  respondentsSubscription: Subscription;
  filterSubscription: Subscription;

  constructor(
    private researches: ResearchesService,
    private respondents: RespondentsService,
    private researchesService: ResearchesService,
    private menu: MenuService,
    private codelists: CodelistsService
  ) { }

  ngOnInit() {
    this.researchSubscription = this.researches.research.subscribe(research => {
      this.refresh();
    });

    this.respondentSubscription = this.respondents.respondent.subscribe(respondent => {
      this.focused = respondent;
      this.menu.refilter();
    });

    this.respondentsSubscription = this.respondents.respondents.subscribe(
      respondents => (this.dataSource = respondents)
    );

    this.filterSubscription = this.respondents.filter.subscribe(filter => (this.filter = filter));

    this.timer = timer(0, 10000).subscribe(value => {
      if (!this.focused) {
        this.refresh();
      }
    });

    this.researchesService.refresh();
  }

  ngOnDestroy(): void {
    this.researchSubscription.unsubscribe();
    this.respondentSubscription.unsubscribe();
    this.respondentsSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
    this.timer.unsubscribe();
    this.respondents.respondent.next(null);
  }

  public async refresh() {
    this.sex = await this.codelists.codelist('sex');
    this.edu = await this.codelists.codelist('edu');
    this.respondents.refresh();
  }

  public select(respondent: RespondentModel) {
    if (this.focused === respondent) {
      if (!this.menu.loading) {
        this.menu.actionCheckIfContinue();
      }
    } else {
      this.respondents.respondent.next(respondent);
    }
  }

  public get filterActive(): boolean {
    return this.filter.date.active || this.filter.oid.active || this.filter.sort.active;
  }

  public async resort() {
    this.filter.sort.active = true;
    await this.refresh();
  }

  public async delFilter() {
    this.filter.date.active = false;
    this.filter.oid.active = false;
    this.filter.sort.active = false;
    this.filter.sort.field = null;
    this.filter.sort.dir = 'ASC';
    await this.refresh();
  }

  public isOver(tocall) {
    if (new Date(tocall) > new Date()) {
      return false;
    } else {
      return true;
    }
  }
}

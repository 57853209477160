<mat-tab-group>
  <mat-tab label="Nastavení">
    <app-page-wrapper>
      <div wrapper-title>Nastavení</div>

      <div wrapper-actions>
        <div class="button-row">
          <button class="flex-grow" mat-stroked-button color="primary" [routerLink]="['/config']" [queryParams]="{id:'new'}">
            <mat-icon>add</mat-icon> Nové nastavení
          </button>
          <button class="flex-grow" mat-stroked-button color="accent" (click)="editConfig(focusedConfig)" [disabled]="!focusedConfig">
            <mat-icon>edit</mat-icon> Upravit nastavení
          </button>
          <button class="flex-grow" mat-stroked-button color="warn" (click)="deleteConfig(focusedConfig)" [disabled]="!focusedConfig">
            <mat-icon>delete</mat-icon> Smazat nastavení
          </button>
        </div>
      </div>

      <div wrapper-content>
        <table mat-table [dataSource]="dataSource" fxFlex>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef> Hodnota </th>
            <td mat-cell *matCellDef="let element"> {{element.value}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Popis </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>

          <ng-container matColumnDef="changed">
            <th mat-header-cell *matHeaderCellDef> Změněno </th>
            <td mat-cell *matCellDef="let element"> {{element.changed}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectConfig(row)" [ngClass]="{'highlight': focusedConfig === row}"></tr>
        </table>
      </div>
    </app-page-wrapper>

  </mat-tab>

  <mat-tab label="Historie volání" fxFlex fxLayout="row">
    <app-calls></app-calls>
  </mat-tab>
</mat-tab-group>

import { Pipe, PipeTransform } from '@angular/core';
import {CodelistsService} from '../services/codelists.service';

@Pipe({
  name: 'codelist'
})
export class CodelistPipe implements PipeTransform {

  constructor(
    private codelists: CodelistsService
  ) {}

  async transform(value: number, args?: any) {
    const cl = await this.codelists.codelist(args);
    const cli = cl.items.find(
      item => item.code === value
    );

    return cli ? cli.text : '';
  }

}

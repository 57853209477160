import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DeferValues, SocioValues} from '../../../../../models/interview.model';
import {CodelistModel} from '../../../../../models/codelist.model';
import {CodelistsService} from '../../../../../services/codelists.service';
import {CATI} from '../../../../../shared/cati.functions';
import {ResearchModel} from '../../../../../models/research.model';
import {ResearchesService} from '../../../../../services/researches.service';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {RxwebValidators} from '@rxweb/reactive-form-validators';

export interface DeferDialogData {
  defer: DeferValues;
  socio: SocioValues;
}

export class NewShiftErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-defer-dialog',
  templateUrl: './defer-dialog.component.html',
  styleUrls: ['./defer-dialog.component.css']
})

export class DeferDialogComponent implements OnInit {
  @HostBinding('class.flex') flex = true;

  public deferGroup: FormGroup;
  public research: ResearchModel;
  public matcher = new NewShiftErrorMatcher();

  public minDate: Date;
  public maxDate: Date;
  public dateTimefilterDefer = CATI.dateTimefilterDefer;

  sex: CodelistModel = new CodelistModel();
  edu: CodelistModel = new CodelistModel();
  distr: CodelistModel = new CodelistModel();

  constructor(
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public codelists: CodelistsService,
    private researches: ResearchesService,
    @Inject(MAT_DIALOG_DATA) public data: DeferDialogData
  ) {
    this.researches.research.subscribe(
      research => this.research = research
    );

    this.deferGroup = this.formBuilder.group({
      tocall:
        ['', [Validators.required, RxwebValidators.minDate({value: new Date()}), RxwebValidators.maxDate({value: this.research.waveend})]],
      tocall_exacttime: ['', []],
      phone: ['', []],
      note: ['', [Validators.required, RxwebValidators.maxLength({value: 256})]],
      sex: ['', []],
      age: ['', []],
      distr: ['', []],
      edu: ['', []]
    }, {validator: [this.startStopShift('tocall')]});

    this.deferGroup.patchValue({
      tocall: this.data.defer.tocall,
      phone: this.data.defer.phone,
      note: this.data.defer.note
    });

    this.deferGroup.patchValue(
      Object.assign(this.data.socio)
    );

    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  ngOnInit() {
    this.initialize();
  }

  private async initialize() {
    this.minDate = new Date(new Date().setHours(0, 0, 0, 0));
    this.maxDate = this.research.waveend;
    this.sex = await this.codelists.codelist('sex');
    this.edu = await this.codelists.codelist('edu');
    this.distr = await this.codelists.codelist('distr');
    this.distr.items = this.distr.items.sort(function(a, b) {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  public timeChange(event) {
    this.data.defer.tocall_exacttime = null;
    this.deferGroup.patchValue({
      tocall_exacttime: null});
  }

  public daytimeChange(event) {
    let date = this.deferGroup.value.tocall;

    if (date) {
      date = new Date(date); // we need always new value to initiate ngModel change
    } else {
      date = new Date();
    }

    if (+event.value === 0) {
      const current = new Date();
      date.setHours(current.getHours() + 1);
      date.setMinutes(current.getMinutes());
    } else {
      date.setHours(
        CATI.dayblockToHours(+event.value)
      );
      date.setMinutes(0);
      date.setSeconds(0);
    }

    this.data.defer.tocall = date;
    this.deferGroup.patchValue({
    tocall: date});
  }

  public submitDefer() {
      Object.assign(this.data.defer, this.deferGroup.value);
      Object.assign(this.data.socio, this.deferGroup.value);
  }

  startStopShift(tocall: string) {
    return (group: FormGroup): { [key: string]: boolean } => {
      const call = new Date(group.controls[tocall].value);
      const callDay = call.getDay();
      const callHour = call.getHours();
      const callMinutes = call.getMinutes();

      if (callDay > 0 && callDay < 6) {
        if ((callHour < 8) || (callHour === 8 && callMinutes < 30)) {
          return {
            'startShiftWeek': true
          };
        }
      }

      if (callDay === 0 || callDay === 6) {
        if ((callHour < 9) || (callHour === 8 && callMinutes < 0)) {
          return {
            'startShiftWeekend': true
          };
        }
      }

      if (callDay > 0 && callDay < 6) {
        if ((callHour > 22) || (callHour === 22 && callMinutes > 0)) {
          return {
            'stopShiftWeek': true
          };
        }
      }

      if (callDay === 0 || callDay === 6) {
        if ((callHour > 15) || (callHour === 15 && callMinutes > 0)) {
          return {
            'stopShiftWeekend': true
          };
        }
      }

      return null;
    };
  }

}

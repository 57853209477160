<mat-nav-list>
  <a mat-list-item style="height: 48px" *ngFor="let node of items" (click)="executeItem(node)" [matTooltip]="node.hint">
    <span [style.color]="node.color">
      <mat-icon matListIcon [matBadgeHidden]="messagesCount === 0 || node.name != 'news'"
                matBadge="{{messagesCount}}" matBadgePosition="above before" matBadgeSize="small"
                matBadgeColor="accent">{{node.icon}}</mat-icon>
    </span>
    <span [style.color]="node.color">
      {{node.caption}}
    </span>
  </a>
</mat-nav-list>

<!--<div fxFlex fxLayout="column" fxLayoutAlign="space-evenly none">-->
<!--  <button mat-flat-button *ngFor="let node of items" (click)="executeItem(node)" fxLayout="row" fxLayoutAlign="space-evenly none">-->
<!--    <mat-icon>{{node.icon}}</mat-icon><span>{{node.name}}</span>-->
<!--  </button>-->
<!--</div>-->

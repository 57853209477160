import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medal'
})
export class MedalPipe implements PipeTransform {

  transform(value: number, locality: number): any {
    if (value === 1 && locality === 1) {
      return 'looks_one';
    } else if (value === 1 && locality === 2) {
      return 'looks_one';
    } else if (value === 2 && locality === 1) {
      return 'looks_two';
    } else if (value === 2 && locality === 2) {
      return 'looks_two';
    } else if (value === 3 && locality === 1) {
      return 'looks_3';
    } else if (value === 3 && locality === 2) {
      return 'looks_3';
    } else {
      return null;
    }
  }
}

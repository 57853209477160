import {CATI} from '../shared/cati.functions';

export class ShiftModel {

  public sid = CATI.generateId(8);
  public sfrom: Date;
  public sto: Date;
  public change_date: Date;
  public locality: number;
  public avail: boolean = true;
  public approved: boolean;
  public approvedby: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.sfrom) this.sfrom = new Date(this.sfrom);
    if (this.sto) this.sto = new Date(this.sto);
    if (this.change_date) this.change_date = new Date(this.change_date);
  }

}

import { Injectable } from '@angular/core';
import { Simple} from 'sip.js/lib/Web';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import { AuthorizationService, ReservedLine } from './authorization.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SipService {

  private sip: Simple;
  private url: string;
  private domain: string;
  private readonly audio;
  private muted: boolean;
  private keepalive: Subscription;
  public line: ReservedLine;
  public state: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public registered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authorization: AuthorizationService,
    private config: ConfigService
  ) {
    this.audio = document.createElement('audio');
    this.audio.id = 'sipjs';

    this.config.changed.subscribe(
      configs => {
        this.url = this.config.get('sip.connection.wss');
        this.domain = this.config.get('sip.connection.domain');

        this.authorization.state.subscribe(
          state => {
            this.line = state ? state.line : null;

            if (this.line) {
              this.register();
            } else {
              this.unregister();
            }
          }
        );
      }
    );
  }

  private getConfig(): object {
    return {
      media: {
        remote: {
          audio: this.audio
        }
      },
      ua: {
        uri: `${this.line.number}@${this.domain}`,
        wsServers: [this.url],
        authorizationUser: this.line.number,
        password: this.line.password,
        transportOptions: {
          keepAliveInterval: 10, // not functional in Simple.js ... seek Simple.js on line 91 "new UA_1.UA({"
          traceSip: true,
          iceTransportPolicy: 'public',
        },
        stunServers: ['stun:turn2.stemmark.cz'],
        turnServers: {
          urls:"turn:turn2.stemmark.cz",
          username:"acrc",
          password:"coturntoprd"
        },
        userAgentString: 'xquest-cati-online',
        log: {
          level: 'debug'
        }
      }
    };
  }

  public register() {
    this.sip = new Simple(
      this.getConfig()
    );

    this.sip.on('registered', () => {
      this.state.next('registered');
      this.registered.next(true);
    });

    this.sip.on('unregistered', () => {
      this.state.next('unregistered');
      this.registered.next(false);
    });

    this.sip.on('new', () => { this.state.next('new'); });
    this.sip.on('ringing', () => { this.state.next('ringing'); });
    this.sip.on('connecting', () => { this.state.next('connecting'); });
    this.sip.on('connected', () => { this.state.next('connected'); });
    this.sip.on('ended', () => { this.state.next('ended'); });
    this.sip.on('hold', () => { this.state.next('hold'); });
    this.sip.on('unhold', () => { this.state.next('unhold'); });
    this.sip.on('mute', () => { this.state.next('mute'); });
    this.sip.on('unmute', () => { this.state.next('unmute'); });
    this.sip.on('dtmf', (tone) => { this.state.next(`dtmf ${JSON.stringify(tone)}`); });

    this.keepalive = timer(10000, 10000).subscribe(
      value => this.sip.ua.transport.send('')
    );
  }

  public unregister() {
    if (this.sip) {
      this.sip.ua.stop();
      this.sip.ua.unregister();
      this.state.next('unregistered');
      this.sip = null;
      this.keepalive.unsubscribe();
    }
  }

  public call(number: string): boolean {
    if (this.sip) {
      this.sip.call(number);
      return true;
    }
  }

  public hangup() {
    if (this.sip) {
      this.sip.hangup();
    }
  }

  public mute() {
    if (this.sip) {
      this.muted = !this.muted;

      if (this.muted) {
        this.sip.mute();
      } else {
        this.sip.unmute();
      }
    }
  }

  public ismuted(): boolean {
    return this.sip ? this.muted : false;
  }

  public answer() {
    if (this.sip) {
      this.sip.answer();
    }
  }

  public dtmf(tone: string) {
    this.sip.sendDTMF(tone);
  }
}

import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import { ResearchModel } from '../../../../models/research.model';
import { InterviewModel } from '../../../../models/interview.model';
import { RespondentModel } from '../../../../models/respondent.model';
import { ProgressModel} from '../../../../models/progress.model';
import { SurveyInterface } from '../../../../shared/survey.interface';
import {ActivatedRoute, Router} from '@angular/router';
import { SurveyModel } from '../../../../models/survey.model';
import { InterviewService } from '../../../../services/interview.service';
import {CallHistoryModel} from '../../../../models/call-history.model';
import {SipService } from '../../../../services/sip.service';
import {MessagesService} from '../../../../services/messages.service';
import {timer, Subscription} from 'rxjs';
import {Callres} from '../../../../shared/cati.consts';
import {LoggingService} from '../../../../services/logging.service';
import { MatDialog } from '@angular/material/dialog';
import {SessionsService} from '../../../../services/sessions.service';
import {ConfirmationDialogComponent} from '../../../../shared/confirmation-dialog.component';
import {InactivityService} from '../../../../services/inactivity.service';

export enum SurveyView {
  Intro = 1,
  Quest = 2,
  Instructions = 3
}

const SipState = {
  ringing: {label: 'Vyzváním...', icon: 'phone_forwarded'},
  ended: {label: 'Zavěšeno...', icon: 'phone_missed'}
};

/**
 * Component handling interview load to any compatible survey component like iQuest or SurveyJs.
 * Whatever it is, it must be marked as "survey" ViewChild and mus implement SurveyInterface.
 */
@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class.flex') flex = true;
  @ViewChildren('survey') survey: QueryList<SurveyInterface>;

  public research: ResearchModel;
  public interview: InterviewModel;
  public respondent: RespondentModel;
  public progress: ProgressModel;
  public history: CallHistoryModel[];
  public historyColumns: string[] = ['intid', 'tstart', 'callres', 'username'];
  public view: SurveyView = SurveyView.Intro;

  status: string;
  progressbarValue = 0;
  countDown = 0;
  alternativeDialed = false;

  public timer: Subscription;
  public state: Subscription;

  constructor(
    public interviews: InterviewService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private sip: SipService,
    private messages: MessagesService,
    private router: Router,
    private logging: LoggingService,
    public dialog: MatDialog,
    public sessions: SessionsService,
    private inactivity: InactivityService
  ) {
  }

  ngOnInit() {
    this.interviews.progress.subscribe(
      progress => this.progress = progress
    );

    if (!this.sessions.testmode.value) {
      this.interviews.alternativeDialed.subscribe(
        dialed => this.alternativeDialed = dialed
      );

      this.state = this.sip.state.subscribe(
        state => {
          this.status = state;
          this.statusFunction();
        }
      );

      this.notAnsweringTimer();
    } else {
      this.progressbarValue = 100;
    }
  }

  ngAfterViewInit(): void {
    this.route.data.subscribe(
      data => this.load(data.survey)
    );
  }

  ngOnDestroy() {
    if (!this.sessions.testmode.value) {
      this.timer.unsubscribe();
      this.state.unsubscribe();
      this.interviews.alternativeDialed.next(false);
    }
    this.inactivity.update();
  }

  /**
   * Load survey method. It's being called from SurveyResolver when data is ready.
   * When all information is stored in the object, then SurveyInterface is called to get the data also to the iQuest
   * or SurveyJS or anyone implementing this interface and is marked as "survey" ViewChild.
   * @param survey SurveyModel - object containing all essential information for the interview.
   * @return void
   */
  public load(survey: SurveyModel) {
    this.research = survey.research;
    this.respondent = survey.respondent;
    this.interview = survey.interview;
    this.history = survey.history;

    /**
     * Get all survey objects in the view and gather it's survey interfaces...
     * Usually there should be only one survey object...
     */
    this.survey.changes.subscribe(
      (list: QueryList<SurveyInterface>) =>
        list.forEach(
          (item: SurveyInterface) => {
            item.load(this.research, this.respondent, this.interview).subscribe(
              progress => this.interviews.progressInterview(progress),
              error => this.interviews.progress.next(null),
              () => this.interviews.progress.next(null)
            );
          }
        )
    );

    /** Prevent error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. */
    this.cd.detectChanges();
  }

  statusFunction() {
    if (this.status === 'ringing' || this.status === 'ended') {
      setTimeout(() => {
        const label = SipState.hasOwnProperty(this.status) ? SipState[this.status] : this.status;
        if (this.alternativeDialed === false) {
          this.messages.show(label.label, 'phone');
        } else if (this.alternativeDialed === true && this.status === 'ringing') {
          this.messages.show('Vytáčím alternativní číslo...', 'phone');
        } else if (this.alternativeDialed === true && this.status === 'ended') {
          this.messages.show(label.label, 'phone');
        }
      });
    }

    if (this.status === 'ringing') {
      this.refreshTimer();
    }
  }

  notAnsweringTimer() {
    this.timer = timer(0, 1000).subscribe(
      value => {
        this.progressbarValue = value * 100 / this.research.hanguptime;
        this.countDown = this.research.hanguptime - value;

        if (this.interviews.contacted.value) {
          this.timer.unsubscribe();
          this.progressbarValue = 100;
        } else if (this.dialog.openDialogs.length === 1 && this.dialog.openDialogs[0].id !== 'confirm') {
          this.timer.unsubscribe();
          this.progressbarValue = 100;
        } else if (value === this.research.hanguptime && this.alternativeDialed === false && /^(?:[+\d].*\d|\d)$/.test(this.interviews.interview.value.respondent.phone2) === true) {
          this.confirmAlternative();
        } else if (value === this.research.hanguptime && (this.alternativeDialed === true || /^(?:[+\d].*\d|\d)$/.test(this.interviews.interview.value.respondent.phone2) === false)) {
          this.notAnsweringStopInterview();
        }
      }
    );
  }

  async refreshTimer() {
    this.timer.unsubscribe();
    this.notAnsweringTimer();
  }

  async notAnsweringStopInterview() {
    try {
      this.timer.unsubscribe();
      await this.interviews.hangup();
      await this.interviews.note();

      const interview = this.interviews.interview.value;

      interview.tstop = new Date();
      interview.acttime = Math.abs(interview.tstart.getTime() - interview.tstop.getTime()) / 60000;
      interview.callres = Callres.NotAnswering;

      await this.interviews.stopInterview(this.interviews.interview.value);

      this.router.navigate(['respondents']);
    } catch (e) {
      this.logging.logError('survey.service', e.message);
      this.router.navigate(['respondents']);
    }
  }

  async confirmAlternative() {
    this.timer.unsubscribe();
    await this.interviews.hangup();

    const text = 'Přejete si vytočit alternativní číslo?';
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      id: 'confirm',
      data: text,
      autoFocus: false
    });

    ref.afterClosed().subscribe(async result => {
      if (result) {
        this.interviews.dialAlternative();
      } else {
        this.notAnsweringStopInterview();
      }
    });
  }

  public get isDetailWindow(): boolean {
    return (this.research && this.research.display != null && this.research.display !== '');
  }

}

import {Component} from '@angular/core';
import {SipService} from './services/sip.service';
import {SessionsService} from './services/sessions.service';
import {InactivityService} from './services/inactivity.service';
import {InterviewService} from './services/interview.service';
import {InterviewModel} from './models/interview.model';
import {UserModel} from './models/user.model';
import {AuthorizationService} from './services/authorization.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-root',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(-100%)', opacity: 0}),
          animate('250ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('250ms', style({transform: 'translateX(-100%)', opacity: 0}))
        ])
      ]
    )
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public testmode = false;
  public pause = false;
  public consultation = false;
  public interview: InterviewModel;
  public user: UserModel;

  constructor(
    private sessions: SessionsService,
    private inactivity: InactivityService,
    private interviews: InterviewService,
    private sip: SipService,
    private authorization: AuthorizationService,
  ) {
    this.sessions.testmode.subscribe(
      testmode => this.testmode = testmode
    );

    this.sessions.pause.subscribe(
      pause => this.pause = pause
    );

    this.sessions.consultation.subscribe(
      consultation => this.consultation = consultation
    );

    this.interviews.interview.subscribe(
      interview => this.interview = interview
    );

    this.authorization.user.subscribe(
      user => this.user = user
    );
  }

  public call(number: string) {
    this.sip.call(number);
  }
}

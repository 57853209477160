<app-page-wrapper>
  <div wrapper-title>Historie hovorů
  </div>
  <div wrapper-content>
    <div fxFlex fxLayout="column">
      <mat-form-field fxFlex fxLayout="row">
        <input matInput (keyup)="filter($event.target.value)" placeholder="Filtr">
      </mat-form-field>
      <table mat-table [dataSource]="data" *ngIf="data" fxFlex>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Datum</th>
          <td mat-cell *matCellDef="let item">{{item.date | datetime}}</td>
        </ng-container>

        <ng-container matColumnDef="oid">
          <th mat-header-cell *matHeaderCellDef>OID</th>
          <td mat-cell *matCellDef="let item">{{item.oid}}</td>
        </ng-container>

        <ng-container matColumnDef="rid">
          <th mat-header-cell *matHeaderCellDef>RID</th>
          <td mat-cell *matCellDef="let item">{{item.rid}}</td>
        </ng-container>

        <ng-container matColumnDef="respid">
          <th mat-header-cell *matHeaderCellDef>RESPID</th>
          <td mat-cell *matCellDef="let item">{{item.respid}}</td>
        </ng-container>

        <ng-container matColumnDef="intid">
          <th mat-header-cell *matHeaderCellDef>INTID</th>
          <td mat-cell *matCellDef="let item">{{item.intid}}</td>
        </ng-container>

        <ng-container matColumnDef="callres">
          <th mat-header-cell *matHeaderCellDef>Callres</th>
          <td mat-cell *matCellDef="let item">{{item.callres}}</td>
        </ng-container>

        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef>Metoda</th>
          <td mat-cell *matCellDef="let item">{{item.method}}</td>
        </ng-container>

        <ng-container matColumnDef="tocall">
          <th mat-header-cell *matHeaderCellDef>Tocall</th>
          <td mat-cell *matCellDef="let item">{{item.tocall | datetime}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</app-page-wrapper>

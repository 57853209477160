import {SyslistItemModel} from './syslistitem.model';

export class SyslistModel {

  table: string;
  items: SyslistItemModel[] = [];

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

<div fxFlex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly center">

  <div fxFlex="40" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="space-evenly">
    <mat-slide-toggle matInput [(ngModel)]="registered" (ngModelChange)="registerToggle()">
        Registrováno
    </mat-slide-toggle>
    <button mat-raised-button color="accent" fxFlex (click)="apiCall()" [disabled]="!phoneBarForm.valid"><mat-icon>call</mat-icon> API Call</button>
    <button mat-raised-button color="accent" fxFlex (click)="apiHangup()"><mat-icon>call_end</mat-icon> API Hangup</button>
  </div>

  <div fxFlex="60" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="space-evenly center">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <h1>{{status}}</h1>
    </div>
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <ng-form [formGroup]="phoneBarGroup" #phoneBarForm="ngForm" class="form">
        <mat-form-field>
          <input matInput placeholder="Telefonní číslo" [(ngModel)]="phone" formControlName="phone">
          <mat-error *ngIf="phoneBarGroup.get('phone').hasError('pattern')">Prosím zadejte platné telefonní číslo.</mat-error>
        </mat-form-field>
      </ng-form>
    </div>
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('1')">1</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('2')">2</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('3')">3</button>
    </div>

    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('4')">4</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('5')">5</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('6')">6</button>
    </div>

    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('7')">7</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('8')">8</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('9')">9</button>
    </div>

    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('*')">*</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('0')">0</button>
      <button mat-stroked-button color="primary" fxFlex="33" (click)="press('#')">#</button>
    </div>

    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-evenly none">
      <button mat-raised-button [color]="'success'" fxFlex="33" (click)="initiate()" [disabled]="!phoneBarForm.valid" [ngClass]="(status==='ringing' && !testmode) ? 'shake' : null"><mat-icon>call</mat-icon></button>
      <button mat-raised-button color="primary" fxFlex="33" (click)="mute()"><mat-icon>{{ ismuted() ? 'mic_off' : 'mic' }}</mat-icon></button>
      <button mat-raised-button color="warn" fxFlex="33" (click)="hangup()"><mat-icon>call_end</mat-icon></button>
    </div>
  </div>
</div>

export enum Callres {
  DialFailed = 100, // nepodařilo se vytočit hovor
  NotAnswering = 101, // nikdo nezvedá
  BadNumber = 102, // špatné telefonní číslo
  FaxVoicebox = 103, // fax, záznamník, memobox, hlasová schránka
  Busy = 104, // obsazeno
  Deferred = 105, // hovor odložen
  StoppedBeginning = 106, // rozhovor ukončen v úvodu
  Interrupted = 107, // rozhovor přerušen
  Complete = 108, // kompletní rozhovor
  StoppedDuring = 109, // rozhovor ukončen v průběhu (kvóta, screen)
  Unavailable = 111, // permanentně nedostupný
  RefusedBeginning = 116, // respondent v úvodu odmítl rozhovor
  RefusedDuring = 119, // respondent spontánně ukončil rozhovor
  NextAttemptInitiated = 120, // iniciován další pokus o dovolání
}

export enum State {
  Unknown = 0, // Neznamý
  Unused = 1, // Dosud Nevoláno
  Available = 2, // Dostupný
  Inprogress = 3, // Probíhá
  Complete = 4, // Kompletní
  Deferred = 5, // Odložen
  ClosedQuota = 6, // Uzavřeno Skriptem (Screen, Kvóta)
  BadNumber = 7, // Špatné Číslo
  IncorrectNumber = 8, // Nedohledatelné Číslo
  PermanentlyUnavailable = 11, // Permanentně Nedostupný
  ClosedRespondent = 16, // Uzavřeno Respondentem (Odmítá)
  Trying = 20, // Manuálně Uzavřeno (100+Původní Kód)
  ClosedManually = 100, // Manuálně Uzavřeno (100+Původní Kód)
}

export enum Daytimes {
  Morning = 9,
  Forenoon = 10,
  Afternoon = 12,
  Evening = 17
}

export enum Dayblocks {
  Morning = 1,
  Forenoon = 2,
  Afternoon = 3,
  Evening = 4
}

export enum Color {
  BASE = '#a00046',
  RED = '#cf3229',
  GREEN = '#22ab72',
  BLUE = '#2793c2',
  YELLOW = '#ccb929',
  ORANGE = '#c75716',
  FUCHSIA = '#7427c2',
  LIGHTGRAY = '#dddddd',
  GREY = '#bbbbbb',
  DARKGRAY = '#7d7e80'
}

export const ChartColors = [
  Color.RED,
  Color.GREEN,
  Color.BLUE,
  Color.YELLOW,
  Color.ORANGE,
  Color.FUCHSIA,
  Color.LIGHTGRAY,
  Color.GREY,
  Color.DARKGRAY,
  Color.BASE
];

export const passwordMinLength = 6;

export interface NumberedDictionary<T> {
  [id: number]: T;
}

export interface NamedDictionary<T> {
  [id: string]: T;
}

export let errorCodes: NumberedDictionary<string> = {
  2002: 'Neznámé uživatelské jméno.',
  2003: 'Špatné heslo, zkuste to prosím znovu.',
  2010: 'Žádné adresy k vylosování.',
  2013: 'Nemáte právo k přihlášení do aplikace.',
  2014: 'Uživatelská relace je neaktivní.',
  2015: 'Již jste přihlášen na jiném místě.',
  2016: 'Uživatelská relace vypršela.',
  3002: 'Chyba při pokusu o přihlášení! Zkuste to prosím později.',
  3012: 'Chyba při pokusu o změnu hesla! Zkuste to prosím později.',
  3014: 'Chyba při vyhodnocení kvót'
};

export let infoCodes: NumberedDictionary<string> = {
  1009: 'Heslo bylo změněno.'
};

export let panelFields: NamedDictionary<string> = {
  'respid': 'Id Respondenta.',
  'tocall': 'Datum a čas odložení.',
  'tocall_note': 'Poznámka k odložení.',
  'totalint': 'Celkový počet pokusů o rozhovor.',
  'lastcall': 'Datum a čas posledního volání.',
  'oname': 'Uživatelské jméno operátora v poslední komunikaci.',
  'state': 'Životní cyklus respondenta v rámci panelu.',
  'phone1': 'První telefonní číslo.',
  'phone2': 'Druhé telefonní číslo.',
  'operator': 'Identifikátor operátora.',
  'tocall_exacttime': 'Přibližný čas odložení.',
  'age': 'Věk respondenta.',
  'sex': 'Pohlaví respondenta.',
  'edu': 'Vzdělání respondenta.',
  'distr': 'Město respondenta.',
  'region': 'Okres respondenta.',
  'trials_count': 'Počet pokusů dovolání.',
  'email_sent': 'Email byl odeslán.',
  'istester': 'Testovací respondent.'
};


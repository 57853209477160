import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DrawerService {

  public onToggle: Subject<void> = new Subject<void>();

  constructor() { }

  toggle() {
    this.onToggle.next();
  }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SipService } from '../../../services/sip.service';
import { Subscription } from 'rxjs';
import { CatiService } from '../../../services/cati.service';
import {SessionsService} from '../../../services/sessions.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-phonebar',
  templateUrl: './phonebar.component.html',
  styleUrls: ['./phonebar.component.css']
})
export class PhonebarComponent implements OnInit, OnDestroy {

  public phoneBarGroup: FormGroup;

  status: string;
  phone = '';
  registered = false;
  testmode = false;
  regex = '';

  sipStateSubscription: Subscription;
  registeredSubscription: Subscription;
  testmodeSubscription: Subscription;

  constructor(
    private sip: SipService,
    private cati: CatiService,
    private sessions: SessionsService,
    private sheet: MatBottomSheetRef<PhonebarComponent>,
    private changes: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private config: ConfigService
  ) {
    this.getPattern();
    this.phoneBarGroup = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.pattern(this.regex)]]
    });
  }

  ngOnInit() {
    this.sipStateSubscription = this.sip.state.subscribe(
      state => {
        this.status = state;
        this.changes.detectChanges();
      }
    );

    this.registeredSubscription = this.sip.registered.subscribe(
      registered => {
        this.registered = registered;
        this.changes.detectChanges();
      }
    );

    this.testmodeSubscription = this.sessions.testmode.subscribe(
      testmode => {
        this.testmode = testmode;
        this.changes.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.sipStateSubscription.unsubscribe();
    this.registeredSubscription.unsubscribe();
    this.testmodeSubscription.unsubscribe();
  }

  public press(value: string) {
    this.phone += value;
    this.sip.dtmf(value);
  }

  public mute() {
    this.sip.mute();
  }

  public ismuted(): boolean {
    return this.sip.ismuted();
  }

  public initiate() {
    if (this.status === 'ringing') {
      this.sip.answer();
    } else {
      this.sip.call(this.phone);
    }
  }

  public hangup() {
    this.phone = '';
    this.sip.hangup();
  }

  public apiCall() {
    this.cati.dial(this.sip.line.number, this.phone, 'test');
  }

  public apiHangup() {
    this.cati.hangup(this.sip.line.number, this.phone);
  }

  public registerToggle() {
    if (this.registered) {
      this.sip.register();
    } else {
      this.sip.unregister();
    }
  }

  public getPattern() {
    const allowPhone = +this.config.get('app.allowphone');

    if (allowPhone === 1) {
      this.regex = '^(?:[+\\d].*\\d|\\d)$';
    } else {
      this.regex = '[0-9]{1,3}';
    }
  }

}

import {Callres} from '../shared/cati.consts';

export class CallHistoryModel {

  public intid: string;
  public callres: Callres;
  public tstart: Date;
  public note: string
  public username: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

}

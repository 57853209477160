<div fxFill [ngClass]="{
  'bordernone': !user,
  'bordermain': user,
  'borderprimary': !testmode && !pause && !consultation,
  'borderwarning': testmode && !consultation && !pause,
  'bordersuccess': pause && !consultation,
  'borderdanger': consultation
}">
  <div fxFlex>
    <app-body fxFlex></app-body>
  </div>

  <div class="bottom-label" *ngIf="!interview && user" [@enterAnimation] [ngClass]="{
    'backgroundprimary': !testmode && !pause && !consultation,
    'backgroundwarning': testmode && !consultation && !pause,
    'backgroundsuccess': pause && !consultation,
    'backgrounddanger': consultation
  }">
    <span *ngIf="!testmode && !pause && !consultation">PRÁCE</span>
    <span *ngIf="testmode && !consultation && !pause">ZKUŠEBNÍ REŽIM</span>
    <span *ngIf="pause && !consultation">PŘESTÁVKA</span>
    <span *ngIf="consultation">KONZULTACE U SUPERVIZE</span>
  </div>
</div>

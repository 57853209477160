import { CatiService } from './cati.service';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Subscription, timer} from 'rxjs';
import { LoggingService } from './logging.service';
import {MessageInterface, MessageModel} from '../models/message.model';
import { AuthorizationService } from './authorization.service';
import {ToastrService} from 'ngx-toastr';
import {UserModel} from '../models/user.model';

/**
 * Service taking care of system and supervision messages management. Each arival message is being shown in SnackBar.
 */
@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private timer: Subscription;
  public items: MessageModel[] = [];
  public messagesCount: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public user: UserModel;

  constructor(
    private cati: CatiService,
    private authorization: AuthorizationService,
    private logging: LoggingService,
    private toastr: ToastrService
  ) {
    this.timer = timer(0, 5000).subscribe(
      async value => {
        if (this.authorization.user.value) {
          this.check();
        }
      }
    );
  }

  /**
   * Get new messages from the API. If there is some new messages,
   * push it to the message queue => will be shown as soon as possible.
   */
  public async check() {
    try {
      const messages = await this.cati.getMessages();
      const archivedMessages = await this.cati.getArchivedMessages();
      let count = 0;
      let i;

      for (i = 0; i < archivedMessages.length; i++) {
        const now = new Date();
        if (archivedMessages[i].expiration > now || (<any>now - <any>archivedMessages[i].insertdate) < 10 * 60 * 1000 && archivedMessages[i].expiration === null) {
          count++;
        }
      }

      this.messagesCount.next(count);

      messages.forEach(
        message => {
          if (!this.items.some(item => item.messageid === message.messageid)) {
            this.items.push(message);
          }
        }
      );

      this.items.forEach(
        item => {
          this.show(item, 'message');
        }
      );

    } catch (e) {
      this.logging.logError('messages.service', e.message);
    }
  }

  /**
   * Show a single message with given text. Message is automaticly dismissed after all.
   * @param message MessageModel|string An object containing the message.
   * @param icon string Optional icon string from Angular Material Icons.
   */
  public show(message: MessageModel | string, type?: string): void {
    const data: MessageInterface = {
      content: null,
      type: type,
    };

    let duration = 4000;
    let disableTimeout = false;
    let toast: any;

    if (message instanceof MessageModel) {
      data.content = message.content;
      if (message.expiration !== null) {
        duration = new Date(message.expiration).getTime() - new Date().getTime();
      } else {
        disableTimeout = true;
      }
      this.dismiss(message);
    } else {
      data.content = message;
    }

    const config = {
      timeOut: duration,
      extendedTimeOut: 0,
      disableTimeOut: disableTimeout
    };

    if (!type || type === 'info') {
      toast = this.toastr.info(data.content, '', config);
    } else if (type === 'phone') {
      toast = this.toastr.success(data.content, '', config);
    } else if (type === 'message') {
      toast = this.toastr.warning(data.content, '', config);
    } else if (type === 'error') {
      toast = this.toastr.error(data.content, '', config);
    }

    if (!disableTimeout) {
      toast.onShown.subscribe(() => {
        setTimeout(() => {
          toast.toastRef.manualClose();
        }, duration);
      });
    }
  }

  /**
   * Mark message as read. It wont be delivered again.
   * @param message MessageModel An object containing the message.
   */
  public async dismiss(message: MessageModel) {
    try {
      await this.cati.archiveMessage(message.messageid);
      this.items = this.items.filter(
        item => !(message.messageid === item.messageid)
      );
    } catch (e) {
      this.logging.logError('messages.service', e.message);
    }
  }
}

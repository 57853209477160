<app-page-wrapper>
  <div wrapper-title>
    Změna hesla
  </div>

  <div wrapper-content>
    <ng-form [formGroup]="passwordChangeForm">
      <div fxLayout="row">
        <mat-form-field>
          <input matInput
                 placeholder="Nové heslo"
                 [type]="hideNewPassword ? 'password' : 'text'"
                 formControlName="newPassword"
                 [errorStateMatcher]="matcher">
          <button mat-icon-button matSuffix
                  (click)="hideNewPassword = !hideNewPassword"
                  [attr.aria-label]="'Skrýt heslo'"
                  [attr.aria-pressed]="hideNewPassword"
          >
            <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="passwordChangeForm.hasError('required')">Zadejte nové heslo</mat-error>
          <mat-error *ngIf="passwordChangeForm.controls['newPassword'].hasError('minlength')">Minimální počet znaků pro heslo je: {{passwordMinLength}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field>
          <input matInput
                 placeholder="Potvrzení nového hesla"
                 [type]="hideConfirmPassword ? 'password' : 'text'"
                 formControlName="confirmPassword"
                 [errorStateMatcher]="matcher"
          >
            <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="passwordChangeForm.hasError('notSame')">Hesla nejsou stejná</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field>
          <input matInput
                 placeholder="Původní heslo"
                 formControlName="password"
                 [type]="hidePassword ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
            <mat-error *ngIf="passwordChangeForm.hasError('required')">Zadejte původní heslo</mat-error>
        </mat-form-field>
      </div>

      <button mat-stroked-button type="button" [disabled]="!passwordChangeForm.valid" (click)="onSubmit()">Změnit heslo</button>

    </ng-form>
  </div>
</app-page-wrapper>
